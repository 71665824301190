import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import SaveNameProjectForm from '../form/SaveNameProjectForm'
import { useNavigate } from 'react-router-dom'

const SaveProjectNameAndCloseDialog = () => {
  const navigateTo = useNavigate()
  const [open, setOpen] = useState(false)

  const handleFormSubmit = () => {
    setOpen(false)
    setTriggerDialog('')
    navigateTo('/projects')
  }

  const { triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'SaveProjectNameAndCloseDialog') {
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent
        aria-describedby={t('Val att namnge projekt innan sparning.')}
      >
        <DialogHeader>
          <DialogTitle>{t('Projektnamn')}</DialogTitle>
        </DialogHeader>
        <SaveNameProjectForm
          submitButtonText={t('Spara')}
          onFormSubmit={handleFormSubmit}
        />
      </DialogContent>
    </Dialog>
  )
}

export default SaveProjectNameAndCloseDialog
