import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import { cn } from '../lib/utils'
import React from 'react'
import { Button } from './ui/button'

type Props = {
  className: string
  iconProps: FontAwesomeIconProps
}

const CloseConfigurator = React.memo(({ className, iconProps }: Props) => {
  const navigateTo = useNavigate()
  const { reference, isConfigurationComplete, isEdited, setTriggerDialog } =
    useBoundStore(
      (state: StoreState) => ({
        localCart: state.localCart,
        reference: state.reference,
        isConfigurationComplete: state.isConfigurationComplete,
        isEdited: state.isEdited,
        setTriggerDialog: state.setTriggerDialog
      }),
      shallow
    )

  const handleClose = () => {
    if (!isConfigurationComplete) {
      setTriggerDialog('ConfirmCloseDialog')
    }
    if ((reference === undefined || isEdited) && isConfigurationComplete) {
      setTriggerDialog('SaveQuestionDialog')
    }
    if (reference !== undefined && !isEdited && isConfigurationComplete) {
      navigateTo('/projects')
    }
  }
  return (
    <Button
      variant="round"
      onClick={() => handleClose()}
      className={cn(className)}
    >
      <FontAwesomeIcon {...iconProps} />
    </Button>
  )
})

export default CloseConfigurator
