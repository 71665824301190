export const getPanelWidthMounted = (system: string, width: number) => {
  if (system === 'east/west') {
    return Math.round(Math.sqrt(Math.pow(width, 2) - Math.pow(185.5, 2)))
  }
  return width
}

export const getPanelHeightMounted = (system: string, height: number) => {
  if (system === 'south') {
    return Math.round(Math.sqrt(Math.pow(height, 2) - Math.pow(185.5, 2)))
  }
  return height
}
