import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { Button } from '~/components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowDownToLine,
  faClone,
  faPenLine,
  faTrashCan
} from '@fortawesome/sharp-regular-svg-icons'
import { t } from 'i18next'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { RenameRoofForm } from '~/components/canvas/RenameRoofForm'
import { cn, deleteRoof, duplicateRoof } from '~/lib/utils'
import DeleteRoofDialog from './dialogs/DeleteRoofDialog'

type Props = {
  roofUid: string
  // className?: string
  onGeneratePdf: () => void
}

const RoofInformationDropDown = ({
  roofUid,
  // className,
  onGeneratePdf
}: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [changeNameDialog, setChangeNameDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)

  const { roofs, updateRoof, setIsEdited } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      updateRoof: state.updateRoof,
      setCurrentRoofUid: state.setCurrentRoofUid,
      setIsEdited: state.setIsEdited
    }),
    shallow
  )

  const roof = roofs.find((roof) => roof.uid === roofUid)

  const handleNameChange = (name: string) => {
    if (roof === undefined) {
      return null
    }
    updateRoof({
      ...roof,
      name
    })
    setChangeNameDialog(false)
    setDropDownOpen(false)
    setIsEdited(true)
  }

  const handleDuplicate = () => {
    setDropDownOpen(false)
    duplicateRoof(roofUid)
    setIsEdited(true)
  }

  const handleDelete = () => {
    setDeleteDialog(!open)
    setDropDownOpen(false)
    deleteRoof(roofUid) // TODO: Test delete from roof information...
  }

  return (
    <>
      <DropdownMenu
        open={dropDownOpen}
        onOpenChange={setDropDownOpen}
        modal={false}
      >
        <DropdownMenuTrigger asChild>
          <Button
            className={cn(
              'ml-2 rounded-full text-lg hover:bg-silver-25 group-hover:hover:bg-silver-25',
              dropDownOpen && 'bg-grayscale-25'
            )}
            variant="ghost"
            size="icon"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          alignOffset={16}
          side="top"
          sideOffset={0}
        >
          <DropdownMenuGroup>
            <DropdownMenuItem
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                onGeneratePdf()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faArrowDownToLine} />
              <span className="ml-2">{t('Generera rapport')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                setChangeNameDialog(true)
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faPenLine} />
              <span className="ml-2">{t('Ändra namn')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleDuplicate()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faClone} />
              <span className="ml-2">{t('Duplicera')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-100"
              onSelect={(event) => {
                event.preventDefault()
                setDeleteDialog(true)
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
              <span className="ml-2">{t('Ta bort')}</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={changeNameDialog}
        onOpenChange={() => {
          setChangeNameDialog(!changeNameDialog)
          setDropDownOpen(false)
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('Ändra namn')}</DialogTitle>
            <RenameRoofForm
              handleNameChange={handleNameChange}
              roofName={roof?.name ?? ''}
            />
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <DeleteRoofDialog
        roofName={roof?.name ?? ''}
        open={deleteDialog}
        handleAbort={() => {
          setDeleteDialog(!deleteDialog)
          setDropDownOpen(false)
        }}
        handleDelete={handleDelete}
        handleOpenChange={() => {
          setDropDownOpen(false)
        }}
      />
    </>
  )
}

export default RoofInformationDropDown
