import { t } from 'i18next'
import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import { Message } from '~/slices/messageSlice'
import { useEffect, useState } from 'react'
import { solarPanelClient } from '~/http/api'
import { useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { StoreState, useBoundStore } from '~/store'
import BackButton from '~/components/buttons/BackButton'

interface Params {
  slug: string
}

const MessagePage = () => {
  const navigateTo = useNavigate()
  const { slug } = useParams()
  const [message, setMessage] = useState<Message>()

  const { user, messages, setUser } = useBoundStore((state: StoreState) => ({
    user: state.user,
    messages: state.messages,
    setUser: state.setUser
  }))

  useEffect(() => {
    if (!message && slug) {
      const msg = messages.posts.find((msg: Message) => msg.slug === slug)
      if (msg) {
        setMessage(msg)
        return
      }
      const params: Params = { slug: slug }
      solarPanelClient
        .get('/message', { params })
        .then((res) => setMessage(res.data.message))
        .catch((error) => {
          Sentry.captureException(error)
          navigateTo('/messages')
        })
    }
  }, [])

  useEffect(() => {
    if (message && !message.read && slug) {
      const params: Params = { slug: slug }
      solarPanelClient
        .post('/messages/read', params)
        .then(() => {
          setMessage({ ...message, read: true })
          if (user) {
            setUser({ ...user, unreadMessages: user.unreadMessages - 1 })
          }
        })
        .catch((error) => Sentry.captureException(error))
    }
  }, [message])

  if (!message) {
    navigateTo('/messages')
    return
  }

  return (
    <PageWrapper
      hideBackButton
      className="w-full flex-col"
    >
      <SideBar />
      <section className="ml-auto h-full w-[calc(100%-16rem)] overflow-auto py-24">
        <div className="m-auto max-w-[636px] px-8">
          <BackButton
            className="mb-14"
            onClick={() => navigateTo('/messages')}
          >
            {t('Gå tillbaka')}
          </BackButton>
          <h1 className="heading-m mb-4">{message.title}</h1>
          <p className="mb-8 text-grayscale-50">
            {message.readablePublishedAt}
          </p>

          <div
            className="wysiwyg-content"
            dangerouslySetInnerHTML={{ __html: message.content }}
          ></div>
        </div>
      </section>
    </PageWrapper>
  )
}

export default MessagePage
