export const convertGramToKilo = (g: number): number => {
  return g / 1000
}

export const formatCurrency = (price: number | null): string => {
  return price
    ? new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK'
      }).format(price)
    : '-'
}

export const preventEnterKeySubmission = (
  e: React.KeyboardEvent<HTMLFormElement>
) => {
  const target = e.target as HTMLInputElement | HTMLTextAreaElement
  if (e.key === 'Enter' && target.id == 'address') {
    e.preventDefault()
  }
}
