import { cn } from '~/lib/utils'
import Dropdown from './DropDown'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from './ui/table'

type TableProps = {
  className?: string,
  headers: TableHeaderType[]
  data: Record<string, any>[]
  showRowElipsis?: boolean
  rowIdentifier: string
  onClick?: (id: string | number, event: React.MouseEvent<HTMLButtonElement>) => void
  handleView?: (id: string | number) => void
  handleEdit?: (id: string | number) => void
  handleDelete?: (id: string | number) => void
  handleDuplicate?: (id: string | number) => void
}

export type TableHeaderType = {
  key: string
  name: string
}

const DynamicTable = ({
  className,
  headers,
  data,
  showRowElipsis,
  rowIdentifier,
  onClick,
  handleView,
  handleEdit,
  handleDelete,
  handleDuplicate
}: TableProps) => {
  return (
    <Table className={cn('min-w-full border border-grayscale-25 text-left text-base', className)}>
      <TableHeader>
        <TableRow className={cn('border border-grayscale-25')}>
          {headers.map((header, index) => (
            <TableHead
              key={index}
              className={cn(
                'bg-gray-100 px-6 py-2 text-sm font-bold uppercase text-black'
              )}
            >
              {header.name}
            </TableHead>
          ))}
          {showRowElipsis && rowIdentifier && (
            <TableHead className={cn('bg-gray-100 px-6 py-2')} />
          )}
        </TableRow>
      </TableHeader>

      <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow className={cn('hover:bg-white border border-grayscale-25')} key={rowIndex}>
            {headers.map((header, cellIndex) => (
              <TableCell
                key={cellIndex}
                className={cn('px-6 py-2 font-[300]')}
              >
                {row[header.key] !== undefined ? row[header.key] : 'N/A'}
              </TableCell>
            ))}
            {showRowElipsis && rowIdentifier && (
              <TableCell className={cn('px-6 py-2 text-lg flex justify-end last:px-3')}>
                <Dropdown
                  id={row[rowIdentifier]}
                  onClick={onClick}
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DynamicTable
