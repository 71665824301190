import { Rect, Group, Circle } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'
import React from 'react'

type Props = {
  mount: Mount
  railAngle: number
  isApproved: boolean
}

const Mount = React.memo(({ mount, railAngle, isApproved }: Props) => {
  const { position, isSupportPlate } = mount
  const width = isSupportPlate ? 120 : 150
  const height = isSupportPlate ? 120 : 120
  const size =
    railAngle == 0 ? { width: height, height: width } : { width, height }
  const offset =
    railAngle == 0
      ? { x: height / 2, y: width / 2 }
      : { x: width / 2, y: height / 2 }

  const scaledSize = scaleMmToPixels<Size>(size)
  const scaledPosition = scaleMmToPixels<Position>(position)
  const scaledOffset = scaleMmToPixels<Position>(offset)

  return (
    <>
      {!isSupportPlate ? (
        <Circle
          radius={scaledSize.width / 2}
          strokeWidth={1}
          stroke={isApproved ? '404040' : '#DA0A16'}
          fill={'#fff'}
          x={scaledPosition.x}
          y={scaledPosition.y}
        />
      ) : (
        <Group
          x={scaledPosition.x}
          y={scaledPosition.y}
        >
          <Rect
            width={scaledSize.width}
            height={scaledSize.height}
            offsetX={scaledOffset.x}
            offsetY={scaledOffset.y}
            strokeWidth={1}
            stroke={isApproved ? '#000' : '#DA0A16'}
            fill={isApproved ? '#808080' : '#EC848A'}
          />
        </Group>
      )}
    </>
  )
})

export default Mount
