import { MouseEvent, useEffect, useState } from 'react'
import CloseButton from './buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faXmark
} from '@fortawesome/sharp-regular-svg-icons'
import { t } from 'i18next'
import { cn } from '~/lib/utils'

type Props = {
  panelArea?: PanelArea
  panelAreaSections: PanelAreaSection[]
  closeModal: () => void
}

const DebugModal = ({ panelArea, panelAreaSections, closeModal }: Props) => {
  const [openTables, setOpenTables] = useState<string[]>([])
  const [openSection, setOpenSection] = useState(1)

  useEffect(() => {
    setOpenTables([])
  }, [openSection])

  const handleClose = () => {
    closeModal()
  }

  const close = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).getAttribute('data-name') === 'parent') {
      handleClose()
    }
  }

  const panelAreaSectionTabButtons = () =>
    panelAreaSections.map((panelAreaSection, index) => {
      return (
        <button
          key={index}
          className={cn(
            'pb-2 text-base font-bold text-grayscale-50',
            openSection === panelAreaSection.key
              ? 'border-b-2 border-primary text-primary'
              : ''
          )}
          onClick={() => {
            setOpenSection(panelAreaSection.key ?? 1)
          }}
        >
          {`${t('Sektion')} ${panelAreaSection.panelAreaKey}.${
            panelAreaSection.key
          }`}
        </button>
      )
    })

  const debugSections = () => {
    const selectedPanelAreaSection = panelAreaSections.find(
      (panelAreaSection) => panelAreaSection.key === openSection
    )
    if (selectedPanelAreaSection === undefined) {
      return null
    }
    {
      if ('debugValues' in selectedPanelAreaSection.result.values) {
        return Object.entries(
          selectedPanelAreaSection.result.values.debugValues
        ).map(([key, values], debugIndex) => {
          return (
            <div
              key={debugIndex}
              className="mb-8"
            >
              <div
                className="flex cursor-pointer items-center text-[20px] leading-6"
                onClick={() => {
                  if (openTables.includes(key)) {
                    setOpenTables(openTables.filter((k) => k !== key))
                  } else {
                    setOpenTables([...openTables, key])
                  }
                }}
              >
                {`${t('Tabell')} ${key}`}{' '}
                <FontAwesomeIcon
                  icon={openTables.includes(key) ? faChevronUp : faChevronDown}
                  className="ml-2 text-base"
                />
              </div>

              <div
                className={cn(
                  'hidden pt-10',
                  openTables.includes(key) && 'block'
                )}
              >
                {values.description !== undefined ? (
                  <h3 className="mb-6 text-lg font-bold">
                    {values.description}
                  </h3>
                ) : null}
                <div className="table">
                  {values.columns.map((column, columnIndex) => (
                    <div
                      key={`${columnIndex}-${column}`}
                      className="table-column px-4"
                    ></div>
                  ))}
                  <div className="table-row">
                    {values.columns.map((column, columnIndex) => (
                      <div
                        key={columnIndex}
                        className="table-cell px-5 pb-2 font-bold first:pl-0"
                      >
                        {column}
                      </div>
                    ))}
                  </div>
                  {values.data.map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      className="table-row"
                    >
                      {row.map((cell, cellIndex) => (
                        <div
                          key={cellIndex}
                          className="table-cell px-5 pb-2 first:pl-0"
                        >
                          {cell}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        })
      }
    }
  }

  if (panelArea === undefined) {
    return null
  }

  return (
    <div
      data-name="parent"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-[15%] p-24"
      onClick={close}
    >
      <div className="flex h-full w-full flex-col">
        <div className="relative my-auto h-full w-full overflow-auto bg-white px-28 py-24">
          <CloseButton
            className="absolute right-8 top-8"
            onClick={handleClose}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="text-xl"
            />
          </CloseButton>
          <h1 className="mb-8 text-2xl">{t('Debug-data')}</h1>
          <div className="mb-14 flex gap-8 border-b border-grayscale-50">
            {panelAreaSectionTabButtons()}
          </div>
          {debugSections()}
        </div>
      </div>
    </div>
  )
}

export default DebugModal
