import { useCallback, useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import DistanceLabel from './DistanceLabel'
import {
  getCrossRailDistanceData,
  getMainRailDistanceData,
  getMountDistanceLabelsData,
  getRailLengthsData
} from '~/lib/distanceLabelsUtils'

type Props = {
  roof: Roof
}

const DistanceLabels = ({ roof }: Props) => {
  const {
    panelAreaSections,
    positionsOverviewImageView,
    sectionsOverviewImageView,
    panelSectionsImageView,
    showMountDistanceLabels,
    showMountSupportDistanceLabels,
    showRailDistanceLabels,
    showCrossRailDistanceLabels,
    showRailLengthDistanceLabels
  } = useBoundStore(
    (state: StoreState) => ({
      panelAreaSections: state.panelAreaSections,
      positionsOverviewImageView: state.positionsOverviewImageView,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      panelSectionsImageView: state.computed.panelSectionsImageView,
      showMountDistanceLabels: state.showMountDistanceLabels,
      showMountSupportDistanceLabels: state.showMountSupportDistanceLabels,
      showRailDistanceLabels: state.showRailDistanceLabels,
      showCrossRailDistanceLabels: state.showCrossRailDistanceLabels,
      showRailLengthDistanceLabels: state.showRailLengthDistanceLabels
    }),
    shallow
  )

  const [mountDistanceLabels, setMountDistanceLabels] = useState<
    DistanceLabel[]
  >([])
  const [mountSupportDistanceLabels, setMountSupportDistanceLabels] = useState<
    DistanceLabel[]
  >([])
  const [railDistanceLabels, setRailDistanceLabels] = useState<DistanceLabel[]>(
    []
  )
  const [crossRailDistanceLabels, setCrossRailDistanceLabels] = useState<
    DistanceLabel[]
  >([])
  const [railLengthsDistanceLabels, setRailLengthsDistanceLabels] = useState<
    DistanceLabel[]
  >([])

  const filteredPanelAreaSections = useCallback(() => {
    return panelAreaSections.filter(
      (panelAreaSection) => panelAreaSection.roofUid === roof.uid
    )
  }, [panelAreaSections])

  const handleDistanceLabels = () => {
    if (roof.attachment !== 'long_rail' && roof.attachment !== 'short_rail') {
      const mountDistanceLabelsData = getMountDistanceLabelsData(
        filteredPanelAreaSections(),
        false
      )
      setMountDistanceLabels(mountDistanceLabelsData)
    }
  }

  const handleDistanceSupportLabels = () => {
    if (roof.attachment !== 'long_rail' && roof.attachment !== 'short_rail') {
      const mountDistanceSupportLabelsData = getMountDistanceLabelsData(
        filteredPanelAreaSections(),
        true
      )
      setMountSupportDistanceLabels(mountDistanceSupportLabelsData)
    }
  }

  const handleMainRailDistanceLabels = () => {
    setRailDistanceLabels(getMainRailDistanceData(filteredPanelAreaSections()))
  }

  const handleCrossRailDistanceLabels = () => {
    setCrossRailDistanceLabels(
      getCrossRailDistanceData(filteredPanelAreaSections())
    )
  }

  const handleRailLengthsData = () => {
    setRailLengthsDistanceLabels(
      getRailLengthsData(filteredPanelAreaSections())
    )
  }

  useEffect(() => {
    handleDistanceLabels()
    handleDistanceSupportLabels()
    handleMainRailDistanceLabels()
    handleCrossRailDistanceLabels()
    handleRailLengthsData()
  }, [panelAreaSections])

  return sectionsOverviewImageView === false &&
    positionsOverviewImageView === false ? (
    <>
      {showMountDistanceLabels && !showMountSupportDistanceLabels
        ? mountDistanceLabels.map((distanceLabel, index) => {
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
              />
            )
          })
        : null}
      {showMountSupportDistanceLabels && showMountDistanceLabels
        ? mountSupportDistanceLabels.map((distanceLabel, index) => {
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
              />
            )
          })
        : null}
      {showRailDistanceLabels
        ? railDistanceLabels.map((distanceLabel, index) => {
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
              />
            )
          })
        : null}
      {showCrossRailDistanceLabels
        ? crossRailDistanceLabels.map((distanceLabel, index) => {
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
              />
            )
          })
        : null}
      {showRailLengthDistanceLabels && !panelSectionsImageView
        ? railLengthsDistanceLabels.map((distanceLabel, index) => {
            return (
              <DistanceLabel
                key={index}
                distanceLabel={distanceLabel}
              />
            )
          })
        : null}
    </>
  ) : null
}

export default DistanceLabels
