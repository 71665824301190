import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { Button } from '../ui/button'
import { t } from 'i18next'

type Props = {
  roofName: string
  open: boolean
  handleAbort: () => void
  handleDelete: () => void
  handleOpenChange: () => void
}

const DeleteRoofDialog = ({
  roofName,
  open,
  handleAbort,
  handleDelete,
  handleOpenChange
}: Props) => {
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        handleOpenChange()
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Är du säker?')}</DialogTitle>
          <DialogDescription>
            {t(
              'Är du säker på att du vill ta bort {{roofName}}? Denna åtgärd kan inte ångras.',
              { roofName }
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={(event) => {
              event.stopPropagation()
              handleAbort()
            }}
          >
            {t('Avbryt')}
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              handleDelete()
            }}
          >
            {t('Ta bort')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteRoofDialog
