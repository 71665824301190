import Konva from 'konva'
import React, { createRef, useEffect, useState } from 'react'
import { Label, Line, Text, Group } from 'react-konva'

type Props = {
  distanceLabel: DistanceLabel
}

const DistanceLabel = React.memo(({ distanceLabel }: Props) => {
  const { showLabel, startPosition, endPosition, value } = distanceLabel

  const getColor = (type: typeof distanceLabel.type) => {
    switch (type) {
      case 'mount':
      case 'mount-support':
        return '#367C3B'
      case 'rail':
        return '#BE8940'
      case 'cross':
        return '#DF6C00'
      case 'rail-length':
        return '#1E45F4'
      default:
        return '#000'
    }
  }

  const color = getColor(distanceLabel.type)

  const textWidth = 32
  const textHeight = 16
  const labelPosition = { x: 0, y: 0 }

  labelPosition.x =
    distanceLabel.orientation === 'vertical'
      ? startPosition.x
      : startPosition.x + value / 2
  labelPosition.y =
    distanceLabel.orientation === 'vertical'
      ? startPosition.y + value / 2
      : startPosition.y + 1

  const groupRef = createRef<Konva.Group>()
  const [currentGroup, setCurrentGroup] = useState<Konva.Group | null>(null)

  useEffect(() => {
    if (groupRef.current !== null && currentGroup === null) {
      setCurrentGroup(groupRef.current)
    }
  }, [groupRef])

  useEffect(() => {
    if (currentGroup !== null) {
      currentGroup.moveToTop()
    }
  }, [currentGroup])

  const getLineCapPoints = (
    position: Position,
    orientation: string
  ): number[] => {
    const capLength = 6
    if (orientation === 'horizontal') {
      return [
        position.x,
        position.y - capLength / 2,
        position.x,
        position.y + capLength / 2
      ]
    }
    return [
      position.x - capLength / 2,
      position.y,
      position.x + capLength / 2,
      position.y
    ]
  }

  const getLineStartPoints = (
    startPosition: Position,
    endPosition: Position,
    textWidth: number,
    textHeight: number,
    orientation: string
  ): number[] => {
    const { x: startX, y: startY } = startPosition
    const { x: endX, y: endY } = endPosition

    if (orientation === 'horizontal') {
      const midX = endX - (endX - startX) / 2 - textWidth / 2
      return [startX, startY, midX, endY]
    }

    const midY = endY - (endY - startY) / 2 - textHeight / 2
    return [startX, startY, endX, midY]
  }

  const getLineEndPoints = (
    startPosition: Position,
    endPosition: Position,
    textWidth: number,
    textHeight: number,
    orientation: string
  ): number[] => {
    const { x: startX, y: startY } = startPosition
    const { x: endX, y: endY } = endPosition

    if (orientation === 'horizontal') {
      const midX = endX - (endX - startX) / 2 + textWidth / 2
      return [midX, endY, endX, endY]
    }

    const midY = endY - (endY - startY) / 2 + textHeight / 2
    return [endX, midY, endX, endY]
  }

  const getPosition = (
    type: typeof distanceLabel.type,
    orientation: string
  ) => {
    switch (type) {
      case 'mount':
      case 'mount-support':
        return orientation === 'vertical' ? { x: 13, y: 0 } : { x: 0, y: 13 }
      case 'rail':
        return orientation === 'vertical' ? { x: 14, y: 0 } : { x: 0, y: -13 }
      case 'rail-length':
        return orientation === 'vertical' ? { x: -16, y: 0 } : { x: 0, y: -13 }
      default:
        return { x: 0, y: 0 }
    }
  }

  return (
    <Group
      position={getPosition(distanceLabel.type, distanceLabel.orientation)}
    >
      <Line
        points={getLineStartPoints(
          startPosition,
          endPosition,
          textWidth,
          textHeight,
          distanceLabel.orientation
        )}
        stroke={color}
        strokeWidth={1}
      />
      <Line
        points={getLineEndPoints(
          startPosition,
          endPosition,
          textWidth,
          textHeight,
          distanceLabel.orientation
        )}
        stroke={color}
        strokeWidth={1}
      />
      <Line
        points={getLineCapPoints(startPosition, distanceLabel.orientation)}
        stroke={color}
        strokeWidth={1}
      />
      <Line
        points={getLineCapPoints(endPosition, distanceLabel.orientation)}
        stroke={color}
        strokeWidth={1}
      />
      <Group ref={groupRef}>
        <Label
          visible={showLabel}
          x={labelPosition.x}
          y={labelPosition.y}
          offsetY={textHeight / 2}
          offsetX={textWidth / 2}
        >
          <Text
            fontSize={10}
            text={Math.abs(value).toString()}
            width={textWidth}
            height={textHeight}
            align="center"
            verticalAlign="middle"
            fill={color}
            fontFamily="Roboto"
            fontStyle="500"
          />
        </Label>
      </Group>
    </Group>
  )
})

export default DistanceLabel
