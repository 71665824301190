import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { NameProjectForm } from '../form/NameProjectForm'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'

const LocaleStateProjectNameDialog = () => {
  const [open, setOpen] = useState(false)

  const {
    isEdited,
    triggerDialog,
    conditions,
    updateConditions,
    setIsEdited,
    setTriggerDialog
  } = useBoundStore((state: StoreState) => ({
    currentProjectReference: state.currentProjectReference,
    isEdited: state.isEdited,
    triggerDialog: state.triggerDialog,
    conditions: state.conditions,
    updateConditions: state.updateConditions,
    setIsEdited: state.setIsEdited,
    setTriggerDialog: state.setTriggerDialog
  }))

  const handleFormSubmit = (name: string) => {
    updateConditions({ ...conditions, name })
    setOpen(false)
    if (!isEdited) {
      setIsEdited(true)
    }
    setTriggerDialog('')
  }

  useEffect(() => {
    if (triggerDialog === 'LocaleStateProjectNameDialog') {
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Projektnamn')}</DialogTitle>
        </DialogHeader>
        <NameProjectForm onFormSubmit={handleFormSubmit} />
      </DialogContent>
    </Dialog>
  )
}

export default LocaleStateProjectNameDialog
