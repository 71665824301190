import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import RoofTab from './RoofTab'
import AddRoofButton from './AddRoofButton'

const RoofTabs = () => {
  const { roofs } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs
    }),
    shallow
  )

  return (
    <>
      {roofs.map((roof) => (
        <RoofTab
          key={roof.uid}
          roof={roof}
        />
      ))}
      <AddRoofButton />
    </>
  )
}

export default RoofTabs
