import PageWrapper from './PageWrapper'
import Login from '../Login'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { StoreState, useBoundStore } from '../../store'

const LoginPage = () => {
  const navigateTo = useNavigate()
  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))
  useEffect(() => {
    if (user) {
      navigateTo('/projects')
    }
  }, [])

  return (
    <PageWrapper
      className="justify-center bg-auth bg-cover bg-no-repeat"
      hideBackButton
    >
      <Login />
    </PageWrapper>
  )
}

export default LoginPage
