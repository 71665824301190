import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'

const Loader = (props: Omit<FontAwesomeIconProps, 'icon'>) => (
  <FontAwesomeIcon
    icon={faSpinnerThird}
    color="#001489"
    spin={true}
    {...props}
  />
)

export default Loader
