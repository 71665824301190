import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { Button } from '../ui/button'
import { useNavigate } from 'react-router-dom'

const ConfirmCloseDialog = () => {
  const navigateTo = useNavigate()
  const [open, setOpen] = useState(false)

  const { triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'ConfirmCloseDialog') {
      setOpen(true)
      setTriggerDialog('')
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Är du säker?')}</DialogTitle>
          <DialogDescription>
            {t(
              'Alla ändringar i pågående konfiguration kommer att gå förlorade.'
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={(event) => {
              event.stopPropagation()
              setOpen(!open)
            }}
          >
            {t('Avbryt')}
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              setOpen(!open)
              navigateTo('/projects')
            }}
          >
            {t('Avsluta')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmCloseDialog
