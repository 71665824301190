import { Rect, Group, Text } from 'react-konva'

type Props = {
  text: string
  position: {
    x: number
    y: number
  }
  inverted: boolean
  scale: number
  onClick: () => void
}

const KonvaButton = ({ text, position, inverted, scale, onClick }: Props) => (
  <Group
    name="button"
    x={position.x}
    y={position.y}
  >
    <Rect
      width={69 / scale}
      height={32 / scale}
      fill={inverted ? '#ffffff' : '#404040'}
      stroke="#ffffff"
      strokeWidth={1}
    />
    <Text
      width={69 / scale}
      height={32 / scale}
      align="center"
      verticalAlign="middle"
      text={text}
      fontSize={14 / scale}
      fontFamily="Roboto"
      fill={inverted ? '#000000' : '#ffffff'}
      onClick={() => onClick()}
    />
  </Group>
)

export default KonvaButton
