import PageWrapper from './PageWrapper'
import { t } from 'i18next'
import { cn } from '~/lib/utils'

const TermsPage = () => {
  const listItems = [
    {
      title: t('Inmatad information och beräkningsresultat'),
      items: [
        {
          title: t('Ansvar för inmatad information'),
          content: t(
            'Weland Ståls beräkningar grundas på den information som användaren matar in i konfiguratorn. Användaren ansvarar för att säkerställa att dessa uppgifter är korrekta och fullständiga, eftersom felaktig eller ofullständig indata kan påverka resultaten. Weland Stål tar inget ansvar för eventuella konsekvenser som kan uppstå till följd av sådan information.'
          )
        },
        {
          title: t('Resultatets giltighet och ansvarsfriskrivning'),
          content: t(
            'Resultaten är endast tillförlitliga om konfiguratorn används enligt anvisningarna och korrekt data har matats in för projektets specifika förutsättningar. Om konfiguratorn anger att en konfiguration inte är godkänd gäller en fullständig ansvarsfriskrivning från Weland Stål enligt Friskrivning Takfästen. Användaren ansvarar då för att söka alternativa lösningar för att uppnå en säker installation.'
          )
        }
      ]
    },
    {
      title: t('Krav för säker installation'),
      items: [
        {
          title: t('Anpassning för Weland Ståls produkter'),
          content: t(
            'Konfiguratorns beräkningar är specifikt anpassade för Weland Ståls egna produkter. En säker installation kan därför inte garanteras om andra produkter eller komponenter används. Weland Stål kan därför inte hållas ansvarigt för resultatens exakthet eller säkerhet om produkter från andra tillverkare används. Resultaten är vägledande och bör inte ses som bindande tekniska rekommendationer.'
          )
        },
        {
          title: t('Kontroll av byggnad innan installation'),
          content: t(
            'Användaren ansvarar för att bedöma om resultaten är lämpliga för installationen och att ta hänsyn till byggnadens unika egenskaper. För att säkerställa byggnadens hållfasthet och minska risken för skador rekommenderas att en kvalificerad byggnadstekniker konsulteras innan installationen påbörjas. Alla beslut och åtgärder som grundas på konfiguratorns beräkningar sker på användarens egen risk, och Weland Stål ansvarar inte för skador, förluster eller andra konsekvenser som kan uppstå till följd av användningen.'
          )
        }
      ]
    },
    {
      title: t('Efterlevnad av regler och standarder'),
      items: [
        {
          title: t('Följ relevanta lagar'),
          content: t(
            'Användaren är ansvarig för att följa alla relevanta lagar, regler och standarder samt nationella och internationella föreskrifter vid installation av solcellssystem. Att inte följa dessa kan påverka installationens säkerhet och även medföra juridiskt ansvar för användaren.'
          )
        },
        {
          title: t('Kontinuerlig uppdatering'),
          content: t(
            'Konfiguratorn uppdateras regelbundet för att förbättras och följa gällande byggstandarder och praxis. Detta innebär att beräkningsmodeller kan förändras, vilket kan göra att tidigare resultat skiljer sig från nya när verktyget uppdateras. Vid öppning av äldre projekt rekommenderas därför att användaren granskar och uppdaterar beräkningarna för att säkerställa att de fortfarande är aktuella.'
          )
        }
      ]
    },
    {
      title: t('Immateriella rättigheter'),
      content: t(
        'Konfiguratorn och dess innehåll är skyddat av upphovsrätt och andra immateriella rättigheter. Användaren får använda konfiguratorn för yrkesmässigt bruk inom solprojektering, men får inte kopiera, sprida eller ändra innehållet utan tillstånd från Weland Stål.'
      )
    },
    {
      title: t('Ändringar och giltighet för villkoren'),
      content: t(
        'Dessa villkor gäller från och med 2024-11-19 och tills vidare. Weland Stål förbehåller sig rätten att ändra villkoren vid behov. Vid större ändringar kommer användaren att informeras, och de nya villkoren kan behöva accepteras för fortsatt användning.'
      )
    },
    {
      title: t('Tillämplig lag och tvistlösning'),
      content: t(
        'Dessa villkor regleras enligt svensk lag. Eventuella tvister som uppstår i samband med dessa villkor ska lösas i svensk domstol.'
      )
    }
  ]

  return (
    <PageWrapper
      hideBackButton
      className="w-full flex-col"
    >
      <section className={cn('w-[calc(100%-16rem)] py-24')}>
        <div className={cn('m-auto max-w-[636px] px-8')}>
          <h1 className={cn('heading-l mb-10')}>{t('Regler och villkor')}</h1>
          <p className="mb-8">
            {t(
              'För en säker installation och en korrekt användning av Welands solkonfigurator är det viktigt att läsa igenom och förstå villkoren nedan. '
            )}
          </p>
          <ol>
            {listItems.map((listItem, index) => (
              <li key={index}>
                <p className="mb-6 text-lg font-bold">{`${index + 1}. ${
                  listItem.title
                }`}</p>
                {listItem.items && listItem.items.length > 0 ? (
                  <ol>
                    {listItem.items.map((item, childIndex) => (
                      <li key={childIndex}>
                        <p className="mb-4 font-bold">{`${index + 1}.${
                          childIndex + 1
                        } ${item.title}`}</p>
                        <p className="mb-6">{item.content}</p>
                      </li>
                    ))}
                  </ol>
                ) : null}
                {listItem.content ? (
                  <p className="mb-6">{listItem.content}</p>
                ) : null}
              </li>
            ))}
          </ol>
        </div>
      </section>
    </PageWrapper>
  )
}

export default TermsPage
