import { useEffect } from 'react'
import { solarPanelClient } from '../http/api'
import { useTranslation } from 'react-i18next'
import { StoreState, useBoundStore } from '../store'
import Button from './buttons/Button'
import ProgressBar from './ProgressBar'
import * as Sentry from '@sentry/react'
import {
  handleLoadMoreMessagesResponse,
  handleMessagesResponse
} from '~/lib/messageUtils'
import { Message } from '~/slices/messageSlice'
import { cn } from '~/lib/utils'

interface Params {
  page?: number
}

const Messages = () => {
  const { t } = useTranslation()
  const { messages, disableLoadMoreMessages, setDisableLoadMoreMessages } =
    useBoundStore((state: StoreState) => ({
      messages: state.messages,
      disableLoadMoreMessages: state.disableLoadMoreMessages,
      setDisableLoadMoreMessages: state.setDisableLoadMoreMessages
    }))

  useEffect(() => {
    const params: Params = {}
    solarPanelClient
      .get('/messages', { params })
      .then(handleMessagesResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMoreMessages(false)
      })
  }, [])

  const loadMore = () => {
    setDisableLoadMoreMessages(true)
    const params: Params = {
      page: messages.page + 1
    }
    solarPanelClient
      .get('/messages', { params })
      .then(handleLoadMoreMessagesResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMoreMessages(false)
      })
  }

  return (
    <div className="h-full w-full">
      <div className="mb-20 flex flex-col gap-2">
        {messages.posts.map((message: Message, key: number) => (
          <div
            key={key}
            className={cn('rounded-lg p-4', !message.read && 'bg-silver-25')}
          >
            <a
              href={`#/messages/${message.slug}`}
              className="block no-underline"
            >
              <p
                className={cn(
                  'mb-2 text-sm font-medium',
                  !message.read && 'font-bold'
                )}
              >
                {message.readablePublishedAt}
              </p>
              <p
                className={cn(
                  'mb-2 flex items-center gap-2 text-lg font-normal',
                  !message.read && 'font-bold'
                )}
              >
                {!message.read ? (
                  <span className="block h-2 w-2 rounded bg-primary"></span>
                ) : null}
                {message.title}
              </p>
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-base text-grayscale-50">
                {message.description}
              </p>
            </a>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center gap-4 pb-20">
        <div className="text-sm font-medium">{`${t('Visar')} ${
          messages.loaded
        } ${t('av')} ${messages.total}`}</div>
        <ProgressBar
          progress={(messages.loaded / messages.total) * 100}
          className="max-w-[244px]"
        />
        {messages.loaded < messages.total ? (
          <Button
            variant="ghost"
            onClick={loadMore}
            disabled={disableLoadMoreMessages}
          >
            {t('Ladda fler meddelanden')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default Messages
