import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import ProfileTabs from '../ProfileTabs'
import { t } from 'i18next'
import { cn } from '~/lib/utils'

const ProfilePage = () => {
  return (
    <PageWrapper
      hideBackButton
      className="w-full flex-col"
    >
      <SideBar />
      <section className={cn('ml-auto w-[calc(100%-16rem)] py-24')}>
        <div className={cn('m-auto max-w-[920px] px-8')}>
          <h1 className={cn('heading-l mb-10')}>{t('Min profil')}</h1>
          <ProfileTabs />
        </div>
      </section>
    </PageWrapper>
  )
}

export default ProfilePage
