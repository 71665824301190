import { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import RoofImageStage from './RoofImageStage'
import Konva from 'konva'
import { scalePixelsToMm } from '~/utils/configurator'
import { getImage } from '~/utils/images'

const OverviewImageHandler = () => {
  const [currentRoofImageStage, setCurrentRoofImageStage] =
    useState<Konva.Stage>()
  const [roofImage, setRoofImage] = useState<string>('')

  const { roof, updateImage, isConfigurationComplete, updateRoof } =
    useBoundStore(
      (state: StoreState) => ({
        roof: state.computed.currentRoof,
        updateImage: state.computed.roofsUpdateImage,
        isConfigurationComplete: state.isConfigurationComplete,
        updateRoof: state.updateRoof
      }),
      shallow
    )

  useEffect(() => {
    if (
      roof &&
      currentRoofImageStage !== undefined &&
      !isConfigurationComplete
    ) {
      setTimeout(() => {
        const roofSize = scalePixelsToMm<Size>({
          width: currentRoofImageStage.getAttr('width'),
          height: currentRoofImageStage.getAttr('height')
        })
        const image = getImage(
          currentRoofImageStage,
          { x: 0, y: 0 },
          roofSize,
          1
        )
        setRoofImage(image)
      }, 1000)
    }
  }, [currentRoofImageStage, updateImage])

  useEffect(() => {
    if (roofImage && roof) {
      updateRoof({
        ...roof,
        roofImage
      })
    }
  }, [roofImage])

  const handleChildRefSet = (ref: Konva.Stage) => {
    if (ref) {
      setCurrentRoofImageStage(ref)
    }
  }

  if (!roof) {
    return
  }

  return (
    <RoofImageStage
      roof={roof}
      onRefSet={handleChildRefSet}
    />
  )
}

export default OverviewImageHandler
