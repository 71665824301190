import { Group, Label, Line, Tag, Text } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'
import { createRef, useEffect } from 'react'
import Konva from 'konva'

type Props = {
  bottomLeftCorner: {
    distance: Position
    position: Position
  }
  roofSize: {
    a: number
    b: number
    c: number
  }
  roofShape: string
  scale: number
}

const PanelAreaPositionLines = ({
  bottomLeftCorner,
  roofSize,
  roofShape,
  scale
}: Props) => {
  const textWidth = Math.round(28 / scale)
  const fontSize = Math.round(10 / scale)
  const textHeight = Math.round(16 / scale)
  const roofHeight = scaleMmToPixels<number>(roofSize.b)
  const bottomLeftCornerPosition = scaleMmToPixels<Position>(
    bottomLeftCorner.position
  )
  const bottomLeftCornerDistance = scaleMmToPixels<Position>(
    bottomLeftCorner.distance
  )

  const groupRef = createRef<Konva.Group>()

  const showVerticalLine = () => {
    return (roofShape === 'trapezoid' && roofSize.a > roofSize.c) ||
      roofShape === 'rhomb1' ||
      roofShape === 'angle2'
      ? true
      : false
  }

  useEffect(() => {
    if (groupRef.current !== null) {
      groupRef.current.moveToTop()
    }
  }, [groupRef])

  return (
    <Group ref={groupRef}>
      <Line
        stroke="#001489"
        points={[
          bottomLeftCornerPosition.x - bottomLeftCornerDistance.x,
          bottomLeftCornerPosition.y,
          bottomLeftCornerPosition.x,
          bottomLeftCornerPosition.y,
          bottomLeftCornerPosition.x,
          roofHeight
        ]}
      />
      <Label
        x={
          bottomLeftCornerDistance.x > 0
            ? bottomLeftCornerPosition.x -
              Math.round(textWidth / 2 + 15 / scale)
            : bottomLeftCornerPosition.x +
              Math.round(textWidth / 2 + 15 / scale)
        }
        y={bottomLeftCornerPosition.y}
        offsetY={textHeight / 2}
        offsetX={textWidth / 2}
      >
        <Tag fill="#001489" />
        <Text
          fontSize={fontSize}
          height={textHeight}
          width={textWidth}
          text={bottomLeftCornerDistance.x.toString()}
          align="center"
          verticalAlign="middle"
          fill="#FFF"
          fontFamily="Roboto"
          fontStyle="500"
        />
      </Label>
      <Label
        x={bottomLeftCornerPosition.x}
        y={
          roofHeight -
          bottomLeftCornerDistance.y +
          Math.round(textHeight / 2 + 15 / scale)
        }
        offsetX={textWidth / 2}
        offsetY={textHeight / 2}
      >
        <Tag fill="#001489" />
        <Text
          fontSize={fontSize}
          height={textHeight}
          width={textWidth}
          text={bottomLeftCornerDistance.y.toString()}
          align="center"
          verticalAlign="middle"
          fill="#FFF"
          fontFamily="Roboto"
          fontStyle="500"
        />
      </Label>
      {showVerticalLine() ? (
        <Line
          stroke="#565658"
          points={[
            bottomLeftCornerPosition.x - bottomLeftCornerDistance.x,
            0,
            bottomLeftCornerPosition.x - bottomLeftCornerDistance.x,
            roofHeight
          ]}
          dash={[6 / scale, 6 / scale]}
        />
      ) : null}
    </Group>
  )
}

export default PanelAreaPositionLines
