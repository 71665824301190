import { useTranslation } from 'react-i18next'

type Props = {
  pdfUrl: string
}

const CartPdfLinks = ({ pdfUrl }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="absolute -left-[1px] top-full z-10 flex w-60 flex-col border border-grayscale-25 bg-white text-black">
      <a
        href={pdfUrl}
        target="_blank"
        className="p-4 font-normal no-underline hover:bg-silver-25"
      >
        {t('Inkludera pris i rapport')}
      </a>
      <a
        href={`${pdfUrl}&hide-prices=1`}
        target="_blank"
        className="p-4 font-normal no-underline hover:bg-silver-25"
      >
        {t('Exkludera pris i rapport')}
      </a>
    </div>
  )
}

export default CartPdfLinks
