export const getColStart = (number: number) => {
  switch (number) {
    case 1:
      return 'col-start-1'
    case 2:
      return 'col-start-2'
    case 3:
      return 'col-start-3'
    case 4:
      return 'col-start-4'
    case 5:
      return 'col-start-5'
    case 6:
      return 'col-start-6'
    case 7:
      return 'col-start-7'
    case 8:
      return 'col-start-8'
    case 9:
      return 'col-start-9'
    case 10:
      return 'col-start-10'
    case 11:
      return 'col-start-11'
    case 12:
      return 'col-start-12'
    case 13:
      return 'col-start-13'
  }
}

export const getColSpan = (number: number) => {
  switch (number) {
    case 1:
      return 'col-span-1'
    case 2:
      return 'col-span-2'
    case 3:
      return 'col-span-3'
    case 4:
      return 'col-span-4'
    case 5:
      return 'col-span-5'
    case 6:
      return 'col-span-6'
    case 7:
      return 'col-span-7'
    case 8:
      return 'col-span-8'
    case 9:
      return 'col-span-9'
    case 10:
      return 'col-span-10'
    case 11:
      return 'col-span-11'
    case 12:
      return 'col-span-12'
    case 13:
      return 'col-span-13'
  }
}

export const getColEnd = (number: number) => {
  switch (number) {
    case 1:
      return 'col-end-1'
    case 2:
      return 'col-end-2'
    case 3:
      return 'col-end-3'
    case 4:
      return 'col-end-4'
    case 5:
      return 'col-end-5'
    case 6:
      return 'col-end-6'
    case 7:
      return 'col-end-7'
    case 8:
      return 'col-end-8'
    case 9:
      return 'col-end-9'
    case 10:
      return 'col-end-10'
    case 11:
      return 'col-end-11'
    case 12:
      return 'col-end-12'
    case 13:
      return 'col-end-13'
  }
}
