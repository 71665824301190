import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { Button } from '../ui/button'
import { useNavigate } from 'react-router-dom'
import { handleSaveResponseData, saveConfiguration } from '~/lib/utils'
import { AxiosResponse } from 'axios'

const SaveQuestionDialog = () => {
  const navigateTo = useNavigate()

  const { name, triggerDialog, setTriggerDialog, setIsEdited } = useBoundStore(
    (state: StoreState) => ({
      name: state.conditions.name,
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog,
      setIsEdited: state.setIsEdited
    })
  )

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (triggerDialog === 'SaveQuestionDialog') {
      setOpen(true)
      setTriggerDialog('')
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  const handleSaveResponse = (response: AxiosResponse) => {
    setIsLoading(false)
    setIsEdited(false)
    handleSaveResponseData(response)
    setOpen(false)
    navigateTo('/projects')
  }

  const handleFailedResponse = (response: AxiosResponse) => {
    setIsLoading(false)
    console.log('failed response', response)
    setTriggerDialog('SomethingWentWrongDialog')
    // onFormSubmit()
  }

  const handleSave = () => {
    if (!name) {
      setOpen(!open)
      setTriggerDialog('SaveProjectNameAndCloseDialog')
    } else {
      setIsLoading(true)
      setTimeout(() => {
        saveConfiguration(handleSaveResponse, handleFailedResponse)
      }, 1000)
    }
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Vill du spara?')}</DialogTitle>
          <DialogDescription>
            {t('Vill du spara konfigurationen innan du avslutar?')}
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={(event) => {
              event.stopPropagation()
              setOpen(!open)
              navigateTo('/projects')
            }}
          >
            {t('Spara inte')}
          </Button>
          <Button
            onClick={() => {
              handleSave()
            }}
            isloading={isLoading}
          >
            {t('Spara')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SaveQuestionDialog
