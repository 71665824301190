import React, { useEffect, useRef, useState } from 'react'
import Konva from 'konva'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { createPanelSectionImagesData, getImage } from '~/utils/images'
import { scaleMmToPixels, scalePixelsToMm } from '~/utils/configurator'
import RoofImageStage from './RoofImageStage'

const ImageHandler = React.memo(() => {
  const {
    roofs,
    isLoaded,
    panelAreas,
    isConfigurationComplete,
    runImageCreation,
    panelAreaSections,
    roofImageScale,
    panelSectionImagesData,
    panelSectionsImageView,
    positionsOverviewImageView,
    sectionsOverviewImageView,
    roofsCoordinates,
    updateRoof,
    setRunImageCreation,
    setPanelSectionsImageView,
    setPositionsOverviewImageView,
    setSectionsOverviewImageView,
    setPanelSectionImagesData
  } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      system: state.conditions.configurationSystem,
      isLoaded: state.isLoaded,
      panelAreas: state.panelAreas,
      isConfigurationComplete: state.isConfigurationComplete,
      runImageCreation: state.runImageCreation,
      panelAreaSections: state.panelAreaSections,
      roofImageScale: state.computed.roofImageScale,
      panelSectionImagesData: state.panelSectionImagesData,
      panelSectionsImageView: state.computed.panelSectionsImageView,
      positionsOverviewImageView: state.positionsOverviewImageView,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      roofsCoordinates: state.roofsCoordinates,
      updateRoof: state.updateRoof,
      setRunImageCreation: state.setRunImageCreation,
      updatePanelAreaSection: state.updatePanelAreaSection,
      setPanelSectionsImageView: state.setPanelSectionsImageView,
      setPositionsOverviewImageView: state.setPositionsOverviewImageView,
      setSectionsOverviewImageView: state.setSectionsOverviewImageView,
      setPanelSectionImagesData: state.setPanelSectionImagesData
    }),
    shallow
  )

  const [sectionsOverviewImages, setSectionsOverviewImages] = useState<{
    [key: string]: string
  }>({})
  const [positionsOverviewImages, setPositionsOverviewImages] = useState<{
    [key: string]: string
  }>({})

  const roofImageRefs = useRef<Konva.Stage[]>([])
  const setRoofImageRef = (element: Konva.Stage, index: number) => {
    roofImageRefs.current[index] = element
  }

  const handleSetRoofImageRef = (ref: Konva.Stage, index: number = 0) => {
    setRoofImageRef(ref, index)
  }

  useEffect(() => {
    if (panelAreaSections.length === 0 || !panelSectionsImageView) {
      return
    }
    const roofsSectionImageData = roofs.map((roof, index) => {
      const minRoofX = scaleMmToPixels<number>(
        Math.abs(
          roofsCoordinates[roof.uid] !== undefined
            ? Math.min(
                ...roofsCoordinates[roof.uid].map((coordinate) => coordinate[0])
              )
            : 0
        )
      )
      return createPanelSectionImagesData(
        roofImageRefs.current[index],
        panelAreaSections.filter(
          (panelAreaSection) => panelAreaSection.roofUid === roof.uid
        ),
        { x: minRoofX, y: 0 },
        roof.attachment,
        panelAreas.filter((panelArea) => panelArea.roofUid === roof.uid),
        1
      )
    })
    const sectionImageData = roofsSectionImageData.reduce(
      (acc, val) => acc.concat(val),
      []
    )
    setPanelSectionImagesData(sectionImageData)
    setPanelSectionsImageView(false)
  }, [panelSectionsImageView, panelAreaSections])

  useEffect(() => {
    if (isConfigurationComplete) {
      setPanelSectionsImageView(true)
    }
  }, [isConfigurationComplete])

  useEffect(() => {
    if (roofImageRefs.current.length !== roofs.length) {
      roofImageRefs.current = new Array(roofs.length)
    }
  }, [roofs])

  useEffect(() => {
    if (panelAreaSections.length > 0 && isConfigurationComplete) {
      setTimeout(() => {
        setRunImageCreation(true)
      }, 500)
    }
  }, [panelSectionImagesData, isConfigurationComplete])

  useEffect(() => {
    if (
      runImageCreation &&
      isConfigurationComplete &&
      !isLoaded &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      setPositionsOverviewImageView(true)
    }
  }, [runImageCreation, isConfigurationComplete])

  useEffect(() => {
    if (
      positionsOverviewImageView &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      if (roofImageRefs.current.length === 0) {
        return
      }
      const overviewImages: { [key: string]: string } = {}
      roofs.forEach((roof, index) => {
        const roofImageRef = roofImageRefs.current[index]
        if (roofImageRef === undefined) {
          return
        }
        const checkRoofImageRef = (
          roofImageRef: Konva.Stage,
          roofUid: string
        ) => {
          const width = roofImageRef.getAttr('width')
          const height = roofImageRef.getAttr('height')

          if (width > 0 && height > 0 && !isNaN(width) && !isNaN(height)) {
            const roofSize = scalePixelsToMm<Size>({ width, height })
            const image = getImage(roofImageRef, { x: 0, y: 0 }, roofSize, 1)
            overviewImages[roofUid] = image
          } else {
            setTimeout(() => checkRoofImageRef(roofImageRef, roofUid), 100)
          }
        }
        checkRoofImageRef(roofImageRef, roof.uid)
      })

      setPositionsOverviewImages(overviewImages)
      setPositionsOverviewImageView(false)
      setSectionsOverviewImageView(true)
    }
  }, [positionsOverviewImageView])

  useEffect(() => {
    if (
      sectionsOverviewImageView &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      const overviewImages: { [key: string]: string } = {}
      roofs.forEach((roof, index) => {
        const coordinates: Position[] = panelAreaSections
          .filter((panelAreaSection) => panelAreaSection.roofUid === roof.uid)
          .flatMap((panelAreaSection) => [
            panelAreaSection.position,
            {
              x: panelAreaSection.position.x + panelAreaSection.size.width,
              y: panelAreaSection.position.y
            },
            {
              x: panelAreaSection.position.x + panelAreaSection.size.width,
              y: panelAreaSection.position.y + panelAreaSection.size.height
            },
            {
              x: panelAreaSection.position.x,
              y: panelAreaSection.position.y + panelAreaSection.size.height
            }
          ])
        const minX = Math.min(...coordinates.map((coordinate) => coordinate.x))
        const maxX = Math.max(...coordinates.map((coordinate) => coordinate.x))
        const minY = Math.min(...coordinates.map((coordinate) => coordinate.y))
        const maxY = Math.max(...coordinates.map((coordinate) => coordinate.y))
        const minRoofX =
          roofsCoordinates[roof.uid] !== undefined
            ? Math.min(
                ...roofsCoordinates[roof.uid].map((coordinate) => coordinate[0])
              )
            : 0

        const roofImageRef = roofImageRefs.current[index]

        const checkRoofImageRef = (
          roofImageRef: Konva.Stage,
          roofUid: string
        ) => {
          if (roofImageRef === undefined) {
            return
          }
          const width = roofImageRef.getAttr('width')
          const height = roofImageRef.getAttr('height')
          if (width > 0 && height > 0 && !isNaN(width) && !isNaN(height)) {
            const image = getImage(
              roofImageRef,
              { x: minX - minRoofX, y: minY },
              {
                width:
                  maxX - minX + scalePixelsToMm<number>(2 / roofImageScale),
                height:
                  maxY - minY + scalePixelsToMm<number>(2 / roofImageScale)
              },
              1
            )
            overviewImages[roofUid] = image
          } else {
            setTimeout(() => checkRoofImageRef(roofImageRef, roofUid), 100)
          }
        }
        checkRoofImageRef(roofImageRef, roof.uid)
      })
      setSectionsOverviewImages(overviewImages)
      setSectionsOverviewImageView(false)
    }
  }, [sectionsOverviewImageView])

  useEffect(() => {
    if (
      Object.keys(positionsOverviewImages).length === roofs.length &&
      Object.keys(sectionsOverviewImages).length === roofs.length &&
      import.meta.env.VITE_DEBUG_IMAGES !== 'true'
    ) {
      roofs.forEach((roof) => {
        updateRoof({
          ...roof,
          positionsOverviewImage: positionsOverviewImages[roof.uid],
          sectionsOverviewImage: sectionsOverviewImages[roof.uid]
        })
      })
    }
  }, [positionsOverviewImages, sectionsOverviewImages])

  return (
    <>
      {roofs.map((roof, index) => (
        <RoofImageStage
          key={roof.uid}
          roof={roof}
          index={index}
          onRefSet={handleSetRoofImageRef}
        />
      ))}
    </>
  )
})

export default ImageHandler
