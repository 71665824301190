import { StateCreator } from "zustand"

export interface CartSlice {
    localCart: LocalCart
    setLocalCart: (localCart: LocalCart) => void
    getCartCount: () => number
}

export const createCartSlice: StateCreator<CartSlice> = (set, get) => ({
    localCart: JSON.parse(localStorage.getItem('cart') ?? '{}'),
    setLocalCart: (localCart: LocalCart) => set(() => {
        localStorage.setItem('cart', JSON.stringify(localCart))
        return ({ localCart })
    }),
    getCartCount: () => Object.values(get().localCart).reduce((acc, obj) => acc + obj.quantity, 0)
})
