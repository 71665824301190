import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import { useNavigate } from 'react-router-dom'
import { StoreState, useBoundStore } from '../../store'

const EmptyCart = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="max-w-md text-center">
        <h2 className="mb-6 text-2xl">{t('Projektkorgen är tom!')}</h2>
        <p className="mb-10">
          {user
            ? t(
                'Lägg till konfigurationer från historiken eller direkt från ritverktyget i projektkorgen för att sammanställa en komplett artikelspecifikation för hela ditt projekt.'
              )
            : t(
                'Lägg till konfigurationer direkt från ritverktyget i projektkorgen för att sammanställa en komplett artikelspecifikation för hela ditt projekt.'
              )}
        </p>
        <Button onClick={() => navigateTo('/system')}>
          {t('Ny konfiguration')}
        </Button>
      </div>
    </div>
  )
}

export default EmptyCart
