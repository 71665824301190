import PageWrapper from './PageWrapper'
import BackButton from '../buttons/BackButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ResetPasswordSent from '../ResetPasswordSent'

const ResetPasswordSentPage = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  return (
    <PageWrapper
      className="justify-center bg-white bg-cover bg-no-repeat"
      hideBackButton
    >
      <ResetPasswordSent />
      <BackButton
        className="absolute left-14 top-14"
        onClick={() => navigateTo('/login')}
      >
        {t('Gå till inlogg')}
      </BackButton>
    </PageWrapper>
  )
}

export default ResetPasswordSentPage
