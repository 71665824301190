import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'
import { cn } from '~/lib/utils.ts'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  toolTipText?: string
  active: boolean
}

const Button: React.FC<IButtonProps> = React.memo(
  ({ type, children, disabled, onClick, toolTipText, active }) => {
    const uid = uuidv4()

    return (
      <>
        <button
          // prettier-ignore
          className={cn(
        'h-10 w-10 rounded-[3px] text-xl text-black bg-white',
        'hover:bg-silver-25',
        'active:bg-silver-50 active:text-black',
        active && 'bg-silver-25 text-black'
      )}
          onClick={onClick}
          type={type}
          disabled={disabled}
          {...(toolTipText !== undefined &&
            !active && {
              'data-tooltip-id': uid,
              'data-tooltip-content': toolTipText,
              'data-tooltip-place': 'right',
              'data-tooltip-offset': 20,
              'data-tooltip-delay-show': 500
            })}
        >
          {children}
        </button>
        {toolTipText !== undefined && !active ? (
          <Tooltip
            id={uid}
            style={{ borderRadius: 0 }}
          />
        ) : null}
      </>
    )
  }
)

export default Button
