// import { Button } from '../ui/button'
import { t } from 'i18next'
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/sharp-regular-svg-icons'
import { round } from '~/lib/utils'

const ProjectInfoDialog = () => {
  const [open, setOpen] = useState(false)

  const { project, triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      project: state.computedProject.currentProject,
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  useEffect(() => {
    if (triggerDialog === 'ProjectInfoDialog') {
      setOpen(true)
      setTriggerDialog('')
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  if (project === undefined) {
    return null
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{project.name}</DialogTitle>
        </DialogHeader>
        {/* <DialogDescription> */}
        <div className="text-base">
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Resultat')}</div>
            </div>
            <div className="font-light">
              <div>
                {project.isApproved ? (
                  t('Godkänd')
                ) : (
                  <span className="flex items-center text-red-100">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="mr-2 text-lg"
                    />
                    {t('Ej godkänd')}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Adress')}</div>
            </div>
            <div className="font-light">
              <div>
                {project.address ??
                  `${project.conditions.latitude}, ${project.conditions.longitude}`}
              </div>
            </div>
          </div>
          {/* <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Projektnamn')}</div>
              <div className="font-light">
                <div>{project.name !== '' ? project.name : ''}</div>
              </div>
            </div> */}
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Datum skapad')}</div>
            </div>
            <div className="font-light">
              <div>{project.createdAt}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Senast ändrad')}</div>
            </div>
            <div className="font-light">
              <div>{project.updatedAt}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Antal takytor')}</div>
            </div>
            <div className="font-light">
              <div>{project.numberOfRoofs}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Taktäckning')}</div>
            </div>
            <div className="font-light">
              <div>{project.roofCovering}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Typ av system')}</div>
            </div>
            <div className="font-light">
              <div>{project.system}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Antal paneler')}</div>
            </div>
            <div className="font-light">
              {project.numberOfPanels === null ? (
                <div className="italic">
                  {t('Spara om projekt för att se värde')}
                </div>
              ) : (
                <div>{project.numberOfPanels}</div>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="w-56 font-bold">
              <div>{t('Total vikt för systemet')}</div>
            </div>
            <div className="font-light">
              {project.weight === null ? (
                <div className="italic">
                  {t('Spara om projekt för att se värde')}
                </div>
              ) : (
                <div>{`${round(project.weight / 1000, 1)} ${t('kg')}`}</div>
              )}
            </div>
          </div>
        </div>
        {/* </DialogDescription> */}
      </DialogContent>
    </Dialog>
  )
}

export default ProjectInfoDialog
