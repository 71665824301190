import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

const LoadingCart = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="max-w-md text-center">
        <FontAwesomeIcon
          icon={faSpinnerThird}
          color="#001489"
          size="6x"
          spin={true}
        />
      </div>
    </div>
  )
}

export default LoadingCart
