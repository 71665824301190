import { StoreState, useBoundStore } from '~/store'
import FormPanelSettings from './form/FormPanelSettings'
import LowSlopePanelSettings from './form/lowSlope/LowSlopePanelSettings'
import {
  SettingsDialog,
  SettingsDialogContent,
  SettingsDialogDescription,
  SettingsDialogHeader,
  SettingsDialogTitle
} from '~/components/ui/settings-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

const DrawerPanelSettings = () => {
  const {
    configurationSystem,
    showPanelSettings,
    isDrawing,
    isRedrawing,
    setShowPanelSettings
  } = useBoundStore((state: StoreState) => ({
    configurationSystem: state.conditions.configurationSystem,
    showPanelSettings: state.computed.showPanelSettings,
    isDrawing: state.isDrawing,
    isRedrawing: state.isRedrawing,
    setShowPanelSettings: state.setShowPanelSettings
  }))
  if (isDrawing || isRedrawing) {
    return
  }
  return (
    <SettingsDialog
      open={showPanelSettings}
      onOpenChange={() => setShowPanelSettings(!showPanelSettings)}
    >
      <VisuallyHidden.Root>
        <SettingsDialogTitle>''</SettingsDialogTitle>
        <SettingsDialogDescription>''</SettingsDialogDescription>
        <SettingsDialogHeader></SettingsDialogHeader>
      </VisuallyHidden.Root>
      <SettingsDialogContent>
        {configurationSystem === 'parallel' ? (
          <FormPanelSettings />
        ) : (
          <LowSlopePanelSettings />
        )}
      </SettingsDialogContent>
    </SettingsDialog>
  )
}

export default DrawerPanelSettings
