import { StateCreator } from 'zustand'

const initialState: Conditions = {
  configurationSystem: undefined,
  name: '',
  address: undefined,
  latitude: 62.3875,
  longitude: 16.325556,
  snow: 2.5,
  wind: 23,
  terrain: 0,
  safetyClass: 2,
  lifeSpan: 30,
  surfaceTreatment: 'S',
  discount: undefined
}

export interface ConditionsSlice {
  conditions: Conditions
  updateConditions: (conditions: Conditions) => void
  setName: (name: string) => void
  resetConditions: () => void
}

export const createConditionsSlice: StateCreator<ConditionsSlice> = (set) => ({
  conditions: initialState,
  updateConditions: (conditions: Conditions) =>
    set(() => ({ conditions: conditions })),
  setName: (name: string) =>
    set((state) => ({
      conditions: {
        ...state.conditions,
        name
      }
    })),
  resetConditions: () => {
    set({ conditions: initialState })
  }
})
