export const getLocalCart = () => {
    return JSON.parse(localStorage.getItem('cart') ?? '{}')
}

export const updateLocalCart = (localCart: LocalCart) => {
    localStorage.setItem('cart', JSON.stringify(localCart))
}

export const getLocalCartString = (localCart?: LocalCart) => {
    const cart = localCart ?? getLocalCart()
    let string = ''
    Object.keys(cart).forEach(uid => {
        const item = cart[uid]
        string += `${item.reference}_${item.quantity},`
    });
    return string.replace(/(,$)/g, '')
} 