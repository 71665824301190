import { Rect, Group, Label, Tag, Text } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'

type Props = {
  uid: string
  position: Position
  size: Size
  labelText: string
  scale: number
}

const PanelAreaSectionLabel = ({ position, size, labelText, scale }: Props) => {
  const textWidth = 36 / scale
  const fontSize = 10 / scale
  const textHeight = 20 / scale
  const scaledPosition = scaleMmToPixels<Position>(position)
  const scaledSize = scaleMmToPixels<Size>(size)

  return (
    <Group
      x={scaledPosition.x}
      y={scaledPosition.y}
    >
      <Rect
        width={scaledSize.width - 1}
        height={scaledSize.height - 1}
        strokeWidth={2 / scale}
        stroke={'#001489'}
        fill={'#ccc'}
        opacity={0.7}
      />
      <Label
        x={scaledSize.width / 2}
        y={scaledSize.height / 2}
        offsetY={textHeight / 2}
        offsetX={textWidth / 2}
      >
        <Tag fill="#001489" />
        <Text
          fontSize={fontSize}
          text={labelText}
          width={textWidth}
          height={textHeight}
          align="center"
          verticalAlign="middle"
          fill="#fff"
          fontFamily="Roboto"
          fontStyle="bold"
        />
      </Label>
    </Group>
  )
}

export default PanelAreaSectionLabel
