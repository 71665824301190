import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Label, Tag } from 'react-konva'

type Props = {
  position: {
    x: number
    y: number
  }
  scale: number
  isDrawing: boolean
  isRedrawing: boolean
  maxPanels: {
    x: number
    y: number
  }
  panelAreaWidth: number
}

const PanelAreaAmountLabel = React.memo(
  ({ position, scale, isDrawing, isRedrawing, maxPanels }: Props) => {
    const { t } = useTranslation()
    const height = 52 / scale
    const fontSize = 14 / scale

    const amountLabelText = () => {
      let x = 0
      let y = 0
      if (maxPanels !== null) {
        x = isDrawing || isRedrawing ? maxPanels.x - 1 : maxPanels.x
        y = isDrawing || isRedrawing ? maxPanels.y - 1 : maxPanels.y
      }
      return `${t('Paneler')}: Y: ${y} \n${t('Paneler')} X:  ${x} \n${t(
        'Totalt'
      )}: ${x * y}`
    }

    return (
      <Label
        x={position.x + 8 / scale}
        y={position.y}
        offsetY={height / 2}
      >
        <Tag
          fill="#E9EAEA"
          fontFamily="Roboto"
        />
        <Text
          fontSize={fontSize}
          text={amountLabelText()}
          verticalAlign="middle"
          align="left"
          padding={8 / scale}
          lineHeight={1.2}
        />
      </Label>
    )
  }
)

export default PanelAreaAmountLabel
