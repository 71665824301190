import { ReactNode } from 'react'
import { Button } from '../ui/button'
import { t } from 'i18next'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  title: ReactNode
  content: ReactNode
}

const InfoDialog = ({ open, setOpen, title, content }: Props) => {
  return (
    <Dialog
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{content}</DialogDescription>
        <Button
          className="ml-auto"
          onClick={() => setOpen(!open)}
        >
          {t('Jag förstår')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default InfoDialog
