import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import ResultsTabs from '~/components/ResultsTabs'
import { useTranslation } from 'react-i18next'
import { cn } from '~/lib/utils'
import { createRef, useEffect, useState } from 'react'
import LoadingCart from './cart/LoadingCart'
import PdfButton from './buttons/PdfButton'
import { Button } from './ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faXmark
} from '@fortawesome/sharp-regular-svg-icons'

const Results = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)

  const {
    showResults,
    isLoadingResults,
    panelAreaSections,
    isLoaded,
    isEdited,
    isConfigurationComplete,
    triggerDialog,
    setTriggerDialog
  } = useBoundStore(
    (state: StoreState) => ({
      showResults: state.showResults,
      isLoadingResults: state.isLoadingResults,
      panelAreaSections: state.panelAreaSections,
      isLoaded: state.isLoaded,
      isEdited: state.isEdited,
      isConfigurationComplete: state.isConfigurationComplete,
      triggerDialog: state.triggerDialog,
      updatePanelAreaSections: state.updatePanelAreaSection,
      setTriggerDialog: state.setTriggerDialog
    }),
    shallow
  )

  const [containerOpenHeight, setContainerOpenHeight] = useState(0)
  const [containerClosedWidth, setContainerClosedWidth] = useState(0)
  const containerOpenWidth = 700
  const projectInformationButtonRef = createRef<HTMLButtonElement>()

  useEffect(() => {
    const openHeight = window.innerHeight - 115 - 56

    if (containerOpenHeight !== openHeight) {
      setContainerOpenHeight(openHeight)
    }

    const handleResize = () => {
      setContainerOpenHeight(window.innerHeight - 115 - 56)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (projectInformationButtonRef.current) {
      setContainerClosedWidth(projectInformationButtonRef.current.offsetWidth)
    }
  }, [projectInformationButtonRef])

  useEffect(() => {
    if (!isConfigurationComplete) {
      setOpen(true)
    }
  }, [isConfigurationComplete])

  const [isSectionImagesGenerated, setIsSectionImagesGenerated] =
    useState(false)

  useEffect(() => {
    const isPanelAreaSectionsImagesGenerated = () =>
      panelAreaSections.some(
        (panelAreaSection) => panelAreaSection.image !== undefined
      )

    if (isPanelAreaSectionsImagesGenerated()) {
      setIsSectionImagesGenerated(true)
    } else {
      setIsSectionImagesGenerated(false)
    }
  }, [panelAreaSections])

  const handlePdfButtonClick = () => {
    const pdfDialog = isEdited ? 'PdfSaveProjectDialog' : 'GeneratePdfDialog'
    setTriggerDialog(triggerDialog === '' ? pdfDialog : '')
  }

  return (
    <div
      className={cn(
        'absolute bottom-14 left-14 z-20 flex w-full max-w-[700px] flex-col bg-white px-14 py-[72px] shadow-[0_1px_4px_0_rgba(0,0,0,0.25)] transition-all duration-300', //
        showResults && !open && 'top-auto h-12 w-auto p-0',
        !showResults && 'hidden'
      )}
      style={{
        height: open ? containerOpenHeight : 48,
        width: open ? containerOpenWidth : containerClosedWidth
      }}
    >
      <div className={cn(open && 'invisible absolute -bottom-96')}>
        <Button
          ref={projectInformationButtonRef}
          variant="ghost"
          onClick={() => setOpen(true)}
          className="px-4"
        >
          {t('Projektinformation')}
          <FontAwesomeIcon
            className="ml-4"
            icon={faArrowUpRightAndArrowDownLeftFromCenter}
          />
        </Button>
      </div>
      <div className={cn('flex flex-grow flex-col', !open && 'hidden')}>
        {(!isSectionImagesGenerated && !isLoaded) || isLoadingResults ? (
          <>
            <LoadingCart />
          </>
        ) : (
          <>
            <div className="mb-10 flex items-center justify-between">
              <h1 className="text-xl">{t('Projektinformation')}</h1>
              <PdfButton onClick={handlePdfButtonClick} />
            </div>
            <ResultsTabs />
          </>
        )}
        <Button variant="ghost">
          <FontAwesomeIcon
            size="lg"
            icon={faXmark}
            className="absolute right-4 top-4 min-w-min"
            onClick={() => setOpen(false)}
          />
        </Button>
      </div>
    </div>
  )
}

export default Results
