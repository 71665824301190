import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import Input from './fields/Input'
import Radio from './fields/Radio'
import { formOptions } from './formOptions'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import {
  isSheetMetalMaterialRequired,
  isTileHeightRequired,
  isTileWidthRequired,
  isTopDistanceRequired
} from '../../utils/conditions'
import Select from './fields/Select'
import { useDebounce } from 'use-debounce'
import React, { useEffect } from 'react'

z.setErrorMap(zodI18nMap)

type Props = {
  closeSection: () => void
  isOpen: boolean
  openNextSection: () => void
}

const FormRoofMaterial = React.memo(
  ({ closeSection, isOpen, openNextSection }: Props) => {
    const { t } = useTranslation()
    const {
      roof,
      conditions,
      isRoofMaterialDataValid,
      updateRoof,
      updateConditions,
      setIsRoofMaterialDataValid,
      setShowConditions
    } = useBoundStore(
      (state: StoreState) => ({
        roofs: state.roofs,
        roof: state.computed.currentRoof,
        conditions: state.conditions,
        isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
        updateRoof: state.updateRoof,
        updateConditions: state.updateConditions,
        setIsRoofMaterialDataValid: state.setIsRoofMaterialDataValid,
        setShowConditions: state.setShowConditions
      }),
      shallow
    )

    if (!roof) return null

    const form = useForm({
      defaultValues: {
        covering: roof.covering,
        tileWidth: roof.tileWidth,
        tileHeight: roof.tileHeight,
        topDistance: roof.topDistance,
        attachment: roof.attachment,
        lathDimension: roof.lathDimension,
        sheetMetalMaterial: roof.sheetMetalMaterial,
        surfaceTreatment: conditions.surfaceTreatment
      }
    })

    const getSheetMetalMaterial = (
      sheetMetalMaterial: string | undefined,
      sheetMetalThickness: string | undefined
    ) => {
      if (!sheetMetalMaterial?.includes('_')) {
        return `${sheetMetalMaterial}_${sheetMetalThickness}`
      }
      return sheetMetalMaterial
    }

    const resetForm = () => {
      reset({
        covering: roof.covering,
        tileWidth: roof.tileWidth,
        tileHeight: roof.tileHeight,
        topDistance: roof.topDistance,
        attachment: roof.attachment,
        lathDimension: roof.lathDimension,
        sheetMetalMaterial: getSheetMetalMaterial(
          roof.sheetMetalMaterial,
          roof.sheetMetalThickness
        ),
        surfaceTreatment: conditions.surfaceTreatment
      })
    }

    useEffect(() => {
      reset()
    }, [roof, conditions])

    const {
      reset,
      watch,
      handleSubmit,
      setValue,
      formState: { isDirty }
    } = form

    const roofMaterialFormData = z.object({
      covering: z.string(),
      tileWidth: z.number().optional().nullable(),
      tileHeight: z.number().optional().nullable(),
      topDistance: z.number().optional().nullable(),
      attachment: z.string(),
      lathDimension: z.string().optional().nullable(),
      sheetMetalMaterial: z.string().optional().nullable(),
      surfaceTreatment: z.string()
    })

    type FormData = z.infer<typeof roofMaterialFormData>

    const covering = watch('covering')
    const attachment = watch('attachment')
    const topDistance = watch('topDistance')
    const [debouncedTopDistance] = useDebounce(topDistance, 300)

    useEffect(() => {
      if (
        covering === 'profiled_sheet_metal' &&
        typeof debouncedTopDistance === 'number'
      ) {
        if (debouncedTopDistance > 150 && attachment === 'short_rail') {
          setValue('attachment', '')
        }
        if (debouncedTopDistance > 300 && attachment === 'long_rail') {
          setValue('attachment', '')
        }
      }
    }, [debouncedTopDistance])

    useEffect(() => {
      switch (covering) {
        case 'concrete_tile':
        case 'brick_tile':
          setValue('tileWidth', 300)
          setValue('tileHeight', 330)
          setValue('attachment', 'base_plate')
          break
        case 'corrugated_tin_metal':
          setValue('topDistance', 600)
          setValue('attachment', 'standing_seam_mount')
          break
        case 'profiled_sheet_metal':
          setValue('topDistance', 100)
          setValue('sheetMetalMaterial', 'steel_0.5')
          setValue('attachment', 'long_rail')
          break
        case 'tile_profiled_sheet_metal':
          setValue('topDistance', 200)
          setValue('sheetMetalMaterial', 'steel_0.5')
          setValue('attachment', 'long_rail')
          break
        case 'flat':
          setValue('attachment', 'sealing_plate_flat')
          break
        default:
          break
      }
    }, [covering])

    useEffect(() => {
      resetForm()
    }, [conditions, roof, isOpen])

    const handleOnSubmit = (data: FormData, openNext = false) => {
      let validData = null
      try {
        validData = roofMaterialFormData.parse(data)
        updateConditions({
          ...conditions,
          surfaceTreatment: validData.surfaceTreatment
        })
        let sheetMetalMaterial = undefined
        let sheetMetalThickness = undefined
        if (validData.sheetMetalMaterial !== undefined) {
          sheetMetalMaterial = validData.sheetMetalMaterial?.split('_')[0]
          sheetMetalThickness = validData.sheetMetalMaterial?.split('_')[1]
        }
        updateRoof({
          ...roof,
          covering: validData.covering,
          tileWidth:
            typeof validData.tileWidth === 'number'
              ? validData.tileWidth
              : undefined,
          tileHeight:
            typeof validData.tileHeight === 'number'
              ? validData.tileHeight
              : undefined,
          topDistance:
            typeof validData.topDistance === 'number'
              ? validData.topDistance
              : undefined,
          attachment: validData.attachment,
          lathDimension:
            typeof validData.lathDimension === 'string'
              ? validData.lathDimension
              : undefined,
          sheetMetalMaterial,
          sheetMetalThickness
        })
        setIsRoofMaterialDataValid(true)
        closeSection()
        if (openNext) {
          openNextSection()
        } else {
          setShowConditions(false)
        }
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.log(error.issues)
        }
      }
    }

    const onSubmit: SubmitHandler<FormData> = (data) => {
      handleOnSubmit(data)
    }

    const onSubmitNext: SubmitHandler<FormData> = (data) => {
      handleOnSubmit(data, true)
    }

    return (
      <FormProvider {...form}>
        <form className="col-span-full grid h-auto grid-cols-4 gap-4 overflow-visible pt-4">
          <h3 className="heading-s col-span-full">Taktäckning</h3>
          <Radio
            name="covering"
            options={formOptions.roofCovering}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          {covering !== 'flat' ? (
            <h3 className="heading-s col-span-full">{t('Dimension')}</h3>
          ) : null}
          {!isTileWidthRequired(covering, null) ? (
            <Input
              rules={{ required: { value: true, message: t('Obligatorisk') } }}
              name="tileWidth"
              type="number"
              label={t('Bredd takpannor')}
              unit={t('mm')}
              columnPosition={{ span: 2 }}
            />
          ) : null}
          {!isTileHeightRequired(covering, null) ? (
            <Input
              rules={{ required: { value: true, message: t('Obligatorisk') } }}
              name="tileHeight"
              type="number"
              label={t('Höjd takpannor')}
              unit={t('mm')}
              columnPosition={{ span: 2 }}
            />
          ) : null}
          {!isTopDistanceRequired(covering, null) ? (
            <Input
              rules={{ required: { value: true, message: t('Obligatorisk') } }}
              name="topDistance"
              type="number"
              label={
                covering === 'corrugated_tin_metal'
                  ? t('Avstånd mellan falsar') || ''
                  : t('Avstånd mellan toppar') || ''
              }
              unit={t('mm')}
              columnPosition={{ span: 2 }}
            />
          ) : null}
          {!isSheetMetalMaterialRequired(covering) ? (
            <Select
              name="sheetMetalMaterial"
              label={t('Material för plåttak')}
              placeholder={t('Välj material')}
              options={formOptions.sheetMetalMaterial}
              rules={{ required: { value: true, message: t('Obligatorisk') } }}
            />
          ) : null}
          <h3 className="heading-s col-span-full">{t('Infästning')}</h3>

          <Radio
            name="attachment"
            options={formOptions.attachment(covering, debouncedTopDistance)}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          {attachment === 'lath_mount' ? (
            <Select
              name="lathDimension"
              label={t('Dimension läktfäste')}
              placeholder={t('Välj dimension')}
              options={formOptions.lathDimension}
              rules={{ required: { value: true, message: t('Obligatorisk') } }}
            />
          ) : null}
          <Radio
            name="surfaceTreatment"
            options={formOptions.surfaceTreatment}
            label={t('Ytbehandling')}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
            lowProfile
          />
          {isRoofMaterialDataValid ? (
            <div className="col-span-full flex justify-end">
              <Button onClick={handleSubmit(onSubmit)}>
                {isDirty ? t('Uppdatera') : t('Klar')}
              </Button>
            </div>
          ) : (
            <div className="col-span-full flex justify-end">
              <Button onClick={handleSubmit(onSubmitNext)}>{t('Nästa')}</Button>
            </div>
          )}
        </form>
      </FormProvider>
    )
  }
)

export default FormRoofMaterial
