import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const EmptyCartDialog = ({ dialogActions, handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <h2 className="mb-6 text-xl">{t('Vill du tömma projektkorgen?')}</h2>
      <p className="mb-10">
        {t(
          'Dina nuvarande konfigurationer kommer att gå förlorade om du väljer att tömma projektkorgen.'
        )}
      </p>
      <div className="flex gap-4">
        <Button
          onClick={handleClose}
          variant="ghost"
        >
          {t('Avbryt')}
        </Button>
        <Button
          onClick={() => {
            dialogActions?.clearCart()
            handleClose()
          }}
        >
          {t('Töm projektkorgen')}
        </Button>
      </div>
    </DialogBox>
  )
}

export default EmptyCartDialog
