import ForgotPassword from '../ForgotPassword'
import PageWrapper from './PageWrapper'

function ForgotPasswordPage() {
  return (
    <PageWrapper
      hideBackButton
      className="justify-center bg-auth bg-cover bg-no-repeat"
    >
      <ForgotPassword />
    </PageWrapper>
  )
}

export default ForgotPasswordPage
