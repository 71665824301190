import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { cn } from '~/lib/utils'
import { RoofSectionDropDown } from './RoofSectionDropDown'
import { t } from 'i18next'

type Props = {
  roof: Roof
}

const RoofTab = ({ roof }: Props) => {
  const { currentRoofUid, setCurrentRoofUid } = useBoundStore(
    (state: StoreState) => ({
      currentRoofUid: state.currentRoofUid,
      setCurrentRoofUid: state.setCurrentRoofUid
    }),
    shallow
  )

  const active = roof.uid === currentRoofUid

  return (
    <div
      className={cn(
        'relative z-50 mt-3 flex h-12 max-w-[200px] cursor-pointer items-center overflow-hidden border-silver-100 pl-6 pr-16 text-base hover:ml-[-1px] hover:border-l-[1px]',
        active && 'ml-[-1px] border-l-[1px] border-silver-50 bg-silver-50',
        !active && 'group hover:bg-silver-100'
      )}
      onClick={(event) => {
        event.stopPropagation()
        setCurrentRoofUid(roof.uid)
      }}
      title={roof.name}
    >
      <div className="z-0 whitespace-nowrap">{roof.name ?? t('Namnlös')}</div>
      <div
        className={cn(
          'absolute bottom-0 right-0 top-0 z-10 w-16 bg-roofTabGradient group-hover:bg-roofTabHoverGradient',
          active && 'bg-roofTabActiveGradient'
        )}
      ></div>
      <RoofSectionDropDown roofUid={roof.uid} />
      <span className="absolute right-0 z-20 h-6 w-[1px] bg-silver-50 group-hover:hidden"></span>
    </div>
  )
}

export default RoofTab
