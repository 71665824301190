import { useTranslation } from 'react-i18next'
import SecondaryButton from '../buttons/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '../../store'
import { shallow } from 'zustand/shallow'
import { cn } from '~/lib/utils.ts'

const AddPanelArea = () => {
  const { t } = useTranslation()
  const {
    configurationSystem,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    isConfigurationComplete,
    showPanelSettings,
    setIsDrawing,
    setActiveArea
  } = useBoundStore(
    (state: StoreState) => ({
      configurationSystem: state.conditions.configurationSystem,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      isConfigurationComplete: state.isConfigurationComplete,
      conditions: state.conditions,
      showPanelSettings: state.computed.showPanelSettings,
      setIsDrawing: state.setIsDrawing,
      setActiveArea: state.setActiveArea
    }),
    shallow
  )

  if (
    !isPositionDataValid ||
    !isRoofMaterialDataValid ||
    !isRoofPropertiesDataValid
  ) {
    return
  }

  const handleClick = () => {
    setActiveArea(null)
    setIsDrawing(true)
    localStorage.setItem('highlightAddPanelAreaButton', 'false')
  }

  const highlightAddPanelAreaButton = localStorage.getItem(
    'highlightAddPanelAreaButton'
  )

  if (
    (showPanelSettings && highlightAddPanelAreaButton !== 'false') ||
    isConfigurationComplete ||
    (!isPositionDataValid &&
      !isRoofMaterialDataValid &&
      !isRoofPropertiesDataValid)
  ) {
    return
  }

  return (
    <SecondaryButton
      className={cn(
        'absolute right-[58px] top-[104px]',
        highlightAddPanelAreaButton === null && 'animate-glow',
        configurationSystem === 'low' && 'right-[142px]'
      )}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        className="mr-4"
        icon={faPlus}
      />
      {t('Ny panelyta')}
    </SecondaryButton>
  )
}

export default AddPanelArea
