import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const LoginSuccessDialog = ({ handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="mr-4 text-[32px] text-green-100"
        />
        <h2 className="text-xl">{t('Inloggning lyckades')}</h2>
      </div>
      <p className="mb-10">
        {t('Du kan nu fortsätta att använda Konfiguratorn.')}
      </p>
      <div className="flex gap-4">
        <Button onClick={handleClose}>{t('Okej')}</Button>
      </div>
    </DialogBox>
  )
}

export default LoginSuccessDialog
