import { Group, Label, Tag, Text, Line } from 'react-konva'
import { StoreState, useBoundStore } from '~/store'
import { scaleMmToPixels } from '~/utils/configurator'
import { useTranslation } from 'react-i18next'

interface Props extends EdgeZone {
  imageView?: boolean
  scale: number
}

const EdgeZone = ({ imageView, coordinates, scale }: Props) => {
  const { t } = useTranslation()
  const points = coordinates
    .map((coordinate) => [coordinate.x / 10, coordinate.y / 10])
    .flat()

  const { positionsOverviewImageView } = useBoundStore((state: StoreState) => ({
    positionsOverviewImageView: state.positionsOverviewImageView
  }))

  const stroke = '#404040'
  const strokeWidth = positionsOverviewImageView ? 3 / scale : 1 / scale
  const textWidth = 50 / scale
  const textHeight = 12 / scale
  const fontSize = 10 / scale
  const minX = Math.min(...coordinates.map((coordinate) => coordinate.x))
  const maxX = Math.max(...coordinates.map((coordinate) => coordinate.x))
  const maxY = Math.max(...coordinates.map((coordinate) => coordinate.y))
  const horizontalLabelPosition = scaleMmToPixels<number>(
    minX + (maxX - minX) / 2
  )
  const verticalLabelPosition = scaleMmToPixels<number>(maxY - textHeight / 2)

  return (
    <Group>
      <Line
        points={points}
        stroke={stroke}
        strokeWidth={strokeWidth}
        dash={[15 / scale, 5 / scale]}
        dashEnabled={true}
        name="edge-zone"
        closed
      />
      {imageView !== true && positionsOverviewImageView !== true && (
        <Label
          x={horizontalLabelPosition}
          y={verticalLabelPosition}
          offset={{
            x: textWidth / 2,
            y: textHeight / 2
          }}
        >
          <Tag fill={'#FFF'} />
          <Text
            fontSize={fontSize}
            text={t('Randzon')}
            width={textWidth}
            height={textHeight}
            align="center"
            verticalAlign="middle"
            fill={stroke}
            fontFamily="Roboto"
          />
        </Label>
      )}
    </Group>
  )
}

export default EdgeZone
