import { StateCreator } from 'zustand'
import { findPanelByRowColumn } from '~/lib/panelAreaUtils'

export interface PanelAreaSlice {
  panelAreas: PanelArea[]
  addPanelArea: (panelArea: PanelArea) => void
  setPanelAreas: (panelAreas: PanelArea[]) => void
  updatePanelArea: (panelArea: PanelArea) => void
  updatePanel: (updatedPanel: Panel, panelAreaUid: string) => void
  updatePanelAndNearByPanel: (updatedPanel: Panel, panelAreaUid: string) => void
  deletePanelArea: (panelAreaKey: string) => void
  resetPanelArea: () => void
}

export const createPanelAreaSlice: StateCreator<PanelAreaSlice> = (set) => ({
  panelAreas: [],
  activePanelArea: null,
  addPanelArea: (panelArea) =>
    set((state) => {
      return { panelAreas: [...state.panelAreas, panelArea] }
    }),
  setPanelAreas: (panelAreas) => set(() => ({ panelAreas })),
  updatePanelArea: (updatedPanelArea) =>
    set((state) => ({
      panelAreas: state.panelAreas.map((panelArea) =>
        updatedPanelArea.uid === panelArea.uid ? updatedPanelArea : panelArea
      )
    })),
  deletePanelArea: (uid) =>
    set((state) => ({
      panelAreas: state.panelAreas.filter((panelArea) => panelArea.uid !== uid)
    })),
  updatePanel: (updatedPanel, panelAreaUid) =>
    set((state) => {
      const newPanelAreas = state.panelAreas.map((panelArea) => {
        if (panelArea.uid !== panelAreaUid) {
          return { ...panelArea }
        } else {
          const panels = panelArea.panels.map((panel) =>
            panel.uid === updatedPanel.uid ? updatedPanel : panel
          )
          const removedPanels = panels.filter((panel) => {
            if (panel.removed) {
              return { column: panel.column, row: panel.row }
            }
          })
          return { ...panelArea, panels, removedPanels }
        }
      })
      return {
        panelAreas: newPanelAreas
      }
    }),
  updatePanelAndNearByPanel: (updatedPanel, panelAreaUid) =>
    set((state) => {
      const newPanelAreas = state.panelAreas.map((panelArea) => {
        if (panelArea.uid !== panelAreaUid) {
          return { ...panelArea }
        } else {
          const panelColumn =
            updatedPanel.column % 2 === 0
              ? updatedPanel.column + 1
              : updatedPanel.column - 1
          const nearbyPanel = findPanelByRowColumn(
            panelArea.panels,
            updatedPanel.row,
            panelColumn
          )
          const panels = panelArea.panels.map((panel) => {
            if (panel.uid === updatedPanel.uid) {
              return updatedPanel
            } else if (
              nearbyPanel !== undefined &&
              panel.uid === nearbyPanel.uid
            ) {
              return { ...nearbyPanel, removed: !nearbyPanel.removed }
            } else {
              return panel
            }
          })
          const removedPanels = panels.filter((panel) => {
            if (panel.removed) {
              return { column: panel.column, row: panel.row }
            }
          })
          return { ...panelArea, panels, removedPanels }
        }
      })
      return {
        panelAreas: newPanelAreas
      }
    }),
  resetPanelArea: () => {
    set({ panelAreas: [] })
  }
})
