import { StoreState, useBoundStore } from '../../store'
import EmptyCartDialog from './EmptyCartDialog'
import ApprovedDialog from './ApprovedDialog'
import NotApprovedDialog from './NotApprovedDialog'
import NotApprovedThreeRailsDialog from './NotApprovedThreeRailsDialog'
import AddedToCartDialog from './AddedToCartDialog'
import ConfirmCloseDialog from './ConfirmCloseDialogOld'
import SaveConfigurationDialog from './SaveConfigurationDialog'
import SaveSuccessDialog from './SaveSuccessDialog'
import ConfirmSavedCloseDialog from './ConfirmSavedCloseDialogOld'
import ErrorDialog from './ErrorDialog'
import InfoChangedDialog from './InfoChangedDialog'
import PasswordChanged from './PasswordChanged'
import HistoryAddedToCartDialog from './HistoryAddedToCartDialog'
import SaveLoadedDialog from './SaveLoadedDialog'
import LoadingConfigurationDialog from './LoadingConfigurationDialogOld'
import ErrorLoadingConfigurationDialog from './ErrorLoadingConfigurationDialog'
import SaveChangesDialog from './SaveChangesDialog'
import SaveSuccessCartDialog from './SaveSuccessCartDialog'
import SaveChangesSuccessDialog from './SaveChangesSuccessDialog'
import SaveChangesAsNewSuccessDialog from './SaveChangesAsNewSuccessDialog'
import SaveChangesSuccessCloseDialog from './SaveChangesSuccessCloseDialog'
import SaveChangesAsNewSuccessCloseDialog from './SaveChangesAsNewSuccessCloseDialog'
import EmptyCartGuestDialog from './EmptyCartGuestDialog'
import SaveChangesAsGuestSuccessDialog from './SaveChangesAsGuestSuccessDialog'
import ConfigurationNotPossibleDialog from './ConfigurationNotPossibleDialog'
import UnauthorizedConfigurationDialog from './UnauthorizedConfigurationDialog'
import LoginDialog from './LoginDialog'
import NotFoundConfigurationDialog from './NotFoundConfigurationDialog'
import UnauthorizedDialog from './UnauthorizedDialog'
import LoginSuccessDialog from './LoginSuccessDialog'

const DialogHandler = () => {
  const { showDialog, dialogActions, disabledDialogActions, setShowDialog } =
    useBoundStore((state: StoreState) => ({
      showDialog: state.showDialog,
      dialogActions: state.dialogActions,
      disabledDialogActions: state.disabledDialogActions,
      setShowDialog: state.setShowDialog
    }))

  type Dialogs = {
    [key: string]: React.ComponentType<{
      dialogActions: any
      disabledDialogActions: any
      handleClose: () => void
    }>
  }

  const dialogs: Dialogs = {
    EmptyCartDialog,
    ApprovedDialog,
    NotApprovedDialog,
    NotApprovedThreeRailsDialog,
    AddedToCartDialog,
    ConfirmCloseDialog,
    SaveConfigurationDialog,
    SaveSuccessDialog,
    ConfirmSavedCloseDialog,
    ErrorDialog,
    InfoChangedDialog,
    PasswordChanged,
    HistoryAddedToCartDialog,
    SaveLoadedDialog,
    LoadingConfigurationDialog,
    ErrorLoadingConfigurationDialog,
    SaveChangesDialog,
    SaveSuccessCartDialog,
    SaveChangesSuccessDialog,
    SaveChangesAsNewSuccessDialog,
    SaveChangesSuccessCloseDialog,
    SaveChangesAsNewSuccessCloseDialog,
    EmptyCartGuestDialog,
    SaveChangesAsGuestSuccessDialog,
    ConfigurationNotPossibleDialog,
    UnauthorizedConfigurationDialog,
    LoginDialog,
    NotFoundConfigurationDialog,
    UnauthorizedDialog,
    LoginSuccessDialog
  }

  const DialogComponent =
    typeof showDialog === 'string' ? dialogs[showDialog] : null

  return DialogComponent ? (
    <DialogComponent
      dialogActions={dialogActions}
      disabledDialogActions={disabledDialogActions}
      handleClose={() => setShowDialog(false)}
    />
  ) : null
}

export default DialogHandler
