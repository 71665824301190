import React from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import PanelArea from './PanelArea'
import PanelAreaLabel from './PanelAreaLabel'
import PanelAreaPositionLines from './PanelAreaPositionLines'

type Props = {
  scale: number
  imageView?: boolean
  isScrolling: boolean
  roofPosition: Position
  mappedRoof: Roof
}

const PanelAreas = React.memo(
  ({ scale, imageView, isScrolling, mappedRoof }: Props) => {
    const {
      panelAreas,
      activeArea,
      isDrawing,
      isRedrawing,
      positionsOverviewImageView,
      roofImageScale
    } = useBoundStore(
      (state: StoreState) => ({
        panelAreas: state.panelAreas,
        activeArea: state.activeArea,
        isDrawing: state.isDrawing,
        isRedrawing: state.isRedrawing,
        isComplete: state.computed.isComplete,
        positionsOverviewImageView: state.positionsOverviewImageView,
        roofImageScale: state.computed.roofImageScale
      }),
      shallow
    )

    const { measurementA, measurementB, measurementC, shape } = mappedRoof

    return (
      <>
        {panelAreas
          .filter((panelArea) => panelArea.roofUid === mappedRoof.uid)
          .map((panelArea, index) => (
            <React.Fragment key={panelArea.uid}>
              <PanelArea
                key={panelArea.uid}
                panelArea={panelArea}
                {...panelArea}
                isDrawing={isDrawing && panelArea.uid === activeArea}
                isRedrawing={isRedrawing && panelArea.uid === activeArea}
                isSelected={panelArea.uid === activeArea}
                scale={scale}
                isScrolling={isScrolling}
                imageView={imageView}
              />
              {imageView === true && positionsOverviewImageView === true ? (
                <>
                  <PanelAreaLabel
                    scale={roofImageScale}
                    type="panelArea"
                    position={panelArea.position}
                    size={panelArea.size}
                    labelText={(index + 1).toString()}
                  />
                  {panelArea?.bottomLeftCorner !== undefined && (
                    <PanelAreaPositionLines
                      scale={roofImageScale}
                      roofShape={shape}
                      roofSize={{
                        a: measurementA,
                        b: measurementB,
                        c: measurementC
                      }}
                      bottomLeftCorner={panelArea.bottomLeftCorner}
                    />
                  )}
                </>
              ) : null}
            </React.Fragment>
          ))}
      </>
    )
  }
)

export default PanelAreas
