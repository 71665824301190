import { StoreState, useBoundStore } from '../store'
import { useTranslation } from 'react-i18next'
import { cn } from '~/lib/utils'
import { formatCurrency } from '../utils/utils'
import { round } from '../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenLine } from '@fortawesome/sharp-regular-svg-icons'
import { Button } from './ui/button'
import { faCircleExclamation } from '@fortawesome/sharp-solid-svg-icons'
import { shallow } from 'zustand/shallow'

const Summary = () => {
  const { t } = useTranslation()
  const {
    name,
    roofs,
    summary,
    reference,
    isApproved,
    conditions,
    isConfigurationComplete,
    setTriggerDialog
  } = useBoundStore(
    (state: StoreState) => ({
      name: state.conditions.name,
      roofs: state.roofs,
      summary: state.summary,
      reference: state.reference,
      isApproved: state.isApproved,
      conditions: state.conditions,
      isConfigurationComplete: state.isConfigurationComplete,
      setTriggerDialog: state.setTriggerDialog
    }),
    shallow
  )

  if (!isConfigurationComplete) {
    return
  }

  return (
    <>
      <div>
        <p className="mb-10">
          {t(
            'Nedan visas en sammanfattning av projektet med översiktlig information som gäller alla konfigurerade takytor.'
          )}
        </p>

        <h3 className="mb-4 text-lg font-bold">{t('Projektinformation')}</h3>

        <div className="mb-8 text-base">
          <div className="mb-2 flex">
            <div className="w-56 shrink-0 font-bold">
              <div>{t('Resultat')}</div>
            </div>
            <div className="font-light">
              <div>
                {isApproved ? (
                  t('Godkänd')
                ) : (
                  <>
                    <span className="mb-2 flex items-center text-red-100">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="mr-2 text-lg"
                      />
                      {t('Ej godkänd')}
                    </span>
                    <span className="italic">
                      {t(
                        'Denna konfiguration godkänns inte av Welands Stål. Om du önskar gå vidare ändå friskriver sig Weland Stål ansvar enligt Friskriving takfästen.'
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>
                {t('Projektnamn')}{' '}
                <Button
                  variant="ghost"
                  className="ml-2 h-5 min-w-min p-0"
                  onClick={() => {
                    setTriggerDialog('LocaleStateProjectNameDialog')
                  }}
                >
                  <FontAwesomeIcon icon={faPenLine} />
                </Button>
              </div>
            </div>
            <div className={cn('font-light', !name && 'italic')}>
              <div>{name && name.length > 0 ? name : t('Namnlös')}</div>
            </div>
          </div>
          {reference ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">
                <div>{t('Referensnummer')}</div>
              </div>
              <div className="font-light">
                <div>{reference}</div>
              </div>
            </div>
          ) : null}
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Datum')}</div>
            </div>
            <div className="font-light">
              <div>{summary.projectDetails.date}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Adress')}</div>
            </div>
            <div className="font-light">
              <div>
                {conditions.address && conditions.address.length > 0
                  ? conditions.address
                  : `${conditions.latitude}, ${conditions.longitude}`}
              </div>
            </div>
          </div>
          {summary.price ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">
                <div>{t('Totalsumma (exkl. moms)')}</div>
              </div>
              <div>
                <div>
                  <span
                    className={cn(
                      'font-bold',
                      summary.originalPrice != summary.price && 'text-red-100'
                    )}
                  >
                    {formatCurrency(summary.price)}
                  </span>
                  {summary.originalPrice != summary.price ? (
                    <span className="ml-6 font-light line-through">{`${summary.originalPrice.toLocaleString(
                      'sv-SE'
                    )} ${t('SEK')}`}</span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <h3 className="mb-4 text-lg font-bold">{t('System')}</h3>

        <div className="mb-8 text-base">
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Antal takytor')}</div>
            </div>
            <div className="font-light">
              <div>{roofs.length}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Typ av system')}</div>
            </div>
            <div className="font-light">
              <div>{summary.projectDetails.system}</div>
            </div>
          </div>
          {/* {conditions.configurationSystem === 'low' ? (
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Systemets lutning')}</div>
            </div>
            <div className="font-light">
              <div>{`${summary.projectDetails.systemSlope}°`}</div>
            </div>
          </div>
        ) : null} */}
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Totalt antal paneler')}</div>
            </div>
            <div className="font-light">
              <div>{`${summary.numberOfPanels} ${t('st')}`}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Total vikt infästning')}</div>
            </div>
            <div className="font-light">
              <div>{`${round(summary.totalMountingWeight / 1000, 1)} ${t(
                'kg'
              )}`}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Total vikt paneler')}</div>
            </div>
            <div className="font-light">
              <div>{`${round(summary.totalPanelWeight / 1000, 1)} ${t(
                'kg'
              )}`}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Total vikt för systemet')}</div>
            </div>
            <div className="font-light">
              <div>{`${round((summary.totalWeight || 0) / 1000, 1)} ${t(
                'kg'
              )}`}</div>
            </div>
          </div>
        </div>

        <h3 className="heading-s mb-6">{t('Lastförutsättningar')}</h3>

        <div className="mb-8 text-base">
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Konsekvensklass')}</div>
            </div>
            <div className="font-light">
              <div>{summary.projectDetails.consequenceClass}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Dimensionering')}</div>
            </div>
            <div className="font-light">
              <div>{summary.projectDetails.dimensioning}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Säkerhetsklass')}</div>
            </div>
            <div className="font-light">
              <div>{conditions.safetyClass}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Dimensionerande livslängd')}</div>
            </div>
            <div className="font-light">
              <div>
                {conditions.lifeSpan} {t('år')}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Referensvindhastighet')}</div>
            </div>
            <div className="font-light">
              <div>
                {conditions.wind} {t('m/s')}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Terrängtyp')}</div>
            </div>
            <div className="font-light">
              <div>{conditions.terrain}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Lastkombination faktor Ψ 0v')}</div>
            </div>
            <div className="font-light">
              <div>{summary.loadConditions.windCoefficient}</div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Snözon')}</div>
            </div>
            <div className="font-light">
              <div>
                {conditions.snow} {t('kN/m2')}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">
              <div>{t('Lastkombination faktor Ψ 0s')}</div>
            </div>
            <div className="font-light">
              <div>{summary.loadConditions.snowCoefficient}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <ProjectNameDialog
        open={openProjectNameChange}
        setOpen={setOpenProjectNameChange}
      /> */}
    </>
  )
}

export default Summary
