import { Group, Rect, Image } from 'react-konva'
import { getImageElement } from '~/utils/configurator'
import trashBlack from '~/assets/trash-black.svg'
import gear from '~/assets/gear.svg'
import React from 'react'

type Props = {
  position: Position
  scale: number
  handleShowRemovePopup: () => void
  handleShowPanelSettings: () => void
}

const PanelAreaActions = React.memo(
  ({
    position,
    scale,
    handleShowRemovePopup,
    handleShowPanelSettings
  }: Props) => {
    const actionSize = 32 / scale
    const iconSize = actionSize / 2
    return (
      <Group
        position={position}
        offsetX={actionSize}
        offsetY={actionSize}
      >
        <Rect
          name="panel-action"
          fill="#E9EAEA"
          height={actionSize}
          width={actionSize}
          onClick={handleShowPanelSettings}
        />

        <Image
          image={getImageElement(gear)}
          offset={{ x: iconSize / 2, y: iconSize / 2 }}
          width={iconSize}
          height={iconSize}
          x={iconSize}
          y={iconSize}
          listening={false}
        />
        <Rect
          name="panel-action"
          fill="#E9EAEA"
          x={actionSize}
          height={actionSize}
          width={actionSize}
          onClick={handleShowRemovePopup}
        />

        <Image
          image={getImageElement(trashBlack)}
          offset={{ x: 7 / scale, y: iconSize / 2 }}
          width={iconSize - 2 / scale}
          height={iconSize}
          x={48 / scale}
          y={iconSize}
          listening={false}
        />
      </Group>
    )
  }
)

export default PanelAreaActions
