import Register from '../form/Register'
import PageWrapper from './PageWrapper'

const RegisterPage = () => {
  return (
    <PageWrapper>
      <Register />
    </PageWrapper>
  )
}

export default RegisterPage
