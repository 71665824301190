import { useTranslation } from 'react-i18next'
import DialogBox from './DialogBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/sharp-regular-svg-icons'
import LoginModalForm from '../LoginModalForm'
// import { StoreState, useBoundStore } from '../../store'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const LoginDialog = ({ handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className="mr-4 text-[32px] text-yellow-500"
        />
        <h2 className="text-xl">{t('Du behöver logga in')}</h2>
      </div>
      <p className="mb-4">{t('Logga in för att fortsätta')}</p>
      <div className="flex gap-4">
        <LoginModalForm />
      </div>
    </DialogBox>
  )
}

export default LoginDialog
