import { StoreState, useBoundStore } from '~/store'
import CloseConfigurator from '~/components/CloseConfigurator'
import DrawerPosition from '../DrawerPosition'
import { shallow } from 'zustand/shallow'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { faArrowRightFromBracket } from '@fortawesome/sharp-regular-svg-icons'

const PositionPage = () => {
  const navigateTo = useNavigate()

  const { configurationSystem } = useBoundStore(
    (state: StoreState) => ({
      configurationSystem: state.conditions.configurationSystem
    }),
    shallow
  )

  useEffect(() => {
    if (configurationSystem === undefined) {
      navigateTo('/system')
    }
  }, [configurationSystem])

  return (
    <>
      <DrawerPosition />
      <CloseConfigurator
        className="absolute right-14 top-14 z-50 h-12 bg-white"
        iconProps={{
          icon: faArrowRightFromBracket,
          size: 'lg',
          color: 'black'
        }}
      />
      <div id="weland-google-maps-container"></div>
    </>
  )
}

export default PositionPage
