import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/sharp-solid-svg-icons'

const ErrorIcon = () => {
  return (
    <div className="bg-white text-lg text-red-100 absolute right-4 top-2/4 translate-y-[-50%] transform leading-none">
      <FontAwesomeIcon icon={faExclamationCircle} />
    </div>
  )
}

export default ErrorIcon
