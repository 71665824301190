import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import SystemRadio from './fields/SystemRadio'

import i18n from '../../lib/i18n'
const { t } = i18n

import parallel from './../../assets/parallel.png'
import lowSlope from './../../assets/low-slope.png'
import { Trans } from 'react-i18next'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import { useNavigate } from 'react-router-dom'
import { getNewRoof } from '~/lib/utils'

const formOptionsSystem = [
  {
    value: 'parallel',
    label: t('Parallella system'),
    image: parallel
  },
  {
    value: 'low',
    label: t('Låglutande system'),
    image: lowSlope
  }
]

const validationSchema = z.object({
  system: z.string({ invalid_type_error: t('System är obligatorisk') })
})

type ValidationSchema = z.infer<typeof validationSchema>

const FormSystemPage = React.memo(() => {
  const navigateTo = useNavigate()
  const {
    conditions,
    panelInfo,
    panelInfoLow,
    setRoofs,
    updateConditions,
    setPanelInfo,
    setPanelInfoLow,
    setCurrentRoofUid,
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.computed.currentRoof,
      conditions: state.conditions,
      panelInfo: state.panelInfo,
      panelInfoLow: state.panelInfoLow,
      setRoofs: state.setRoofs,
      updateConditions: state.updateConditions,
      setPanelInfo: state.setPanelInfo,
      setPanelInfoLow: state.setPanelInfoLow,
      setCurrentRoofUid: state.setCurrentRoofUid,
    }),
    shallow
  )

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      system: conditions.configurationSystem
    }
  })

  const { handleSubmit, watch } = form

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    handleOnSubmit(data)
  }

  const handleSystemState = (system: string) => {
    const roof = getNewRoof(system)
    roof.name = `${t('Takyta')} 1`
    setCurrentRoofUid(roof.uid)

    const isLowSystem = system === 'low'

    updateConditions({
      ...conditions,
      configurationSystem: system as typeof conditions.configurationSystem,
      surfaceTreatment: isLowSystem ? '' : conditions.surfaceTreatment
    })

    if (isLowSystem) {
      const lowSlopePanelMounting =
        localStorage.getItem('lowSlopePanelMounting') || '2'
      setPanelInfoLow({
        ...panelInfoLow,
        mounting: lowSlopePanelMounting
      })
    } else {
      const panelMounting =
        localStorage.getItem('panelMounting') || '90-portrait'
      setPanelInfo({
        ...panelInfo,
        mounting: panelMounting
      })
    }
    setRoofs([roof])
  }

  const handleOnSubmit = (data: ValidationSchema) => {
    handleSystemState(data.system)
    navigateTo('/position')
  }

  const system = watch('system')

  return (
    <FormProvider {...form}>
      <SystemRadio
        name="system"
        options={formOptionsSystem}
        className="mb-4"
      />
      {system === 'low' ? (
        <Trans i18nKey="system_page_info">
          <p className="mb-8 max-w-[328px]">
            <span className="font-bold">Information:</span> Låglutande system
            kan endast beräknas på tak med taklutning mellan 0-10 grader.
          </p>
        </Trans>
      ) : null}
      {system !== undefined ? (
        <div className="col-span-full flex justify-center pt-2">
          <Button onClick={handleSubmit(onSubmit)}>{t('Fortsätt')}</Button>
        </div>
      ) : null}
    </FormProvider>
  )
})

export default FormSystemPage
