import { StateCreator } from 'zustand'

export interface Project {
  isApproved: boolean
  name: string
  result: boolean
  address: String
  createdAt: string
  updatedAt: string
  numberOfRoofs: number
  roofCovering: string
  system: string
  numberOfPanels: number
  weight: number
  reference: string
  pdfUrl: string
  conditions: {
    latitude: number
    longitude: number
  }
}

interface Projects {
  userProjects: Project[]
  page: number
  total: number
  loaded: number
}

export interface ProjectSlice {
  projects: Projects
  currentProjectReference?: string
  disableLoadMoreProjects: boolean
  sortingProjects: {
    field: string
    ascending: boolean
  }
  computedProject: {
    currentProject: Project | undefined
  }
  setProjects: (projects: Projects) => void
  addUserProjects: (
    userProjects: Project[],
    total: number,
    loaded: number,
    page: number
  ) => void
  setCurrentProjectReference: (reference: string) => void
  updateUserProject: (project: Project) => void
  setDisableLoadMoreProjects: (disableLoadMoreProjects: boolean) => void
  setSortingProjects: (sortingProjects: {
    field: string
    ascending: boolean
  }) => void
}

export const createProjectSlice: StateCreator<ProjectSlice> = (set, get) => ({
  projects: {
    userProjects: [],
    page: 1,
    total: 0,
    loaded: 0
  },
  disableLoadMoreProjects: false,
  sortingProjects: {
    field: 'updated_at',
    ascending: false
  },
  computedProject: {
    get currentProject() {
      return get().projects.userProjects.find(
        (project) => project.reference === get().currentProjectReference
      )
    }
  },
  setCurrentProjectReference: (reference: string) => {
    set(() => ({ currentProjectReference: reference }))
  },
  setProjects: (projects: Projects) => set(() => ({ projects })),
  addUserProjects: (
    userProjects: Project[],
    total: number,
    loaded: number,
    page: number
  ) =>
    set((state) => ({
      projects: {
        userProjects: [...state.projects.userProjects, ...userProjects],
        total,
        loaded,
        page,
        disableLoadMore: false
      }
    })),
  updateUserProject: (project: Project) => {
    set((state) => {
      const userProjects = state.projects.userProjects.map((p) =>
        p.reference === project.reference ? project : p
      )
      return { projects: { ...state.projects, userProjects } }
    })
  },
  setDisableLoadMoreProjects: (disableLoadMoreProjects: boolean) =>
    set(() => ({ disableLoadMoreProjects })),
  setSortingProjects: (sortingProjects: {
    field: string
    ascending: boolean
  }) => {
    set(() => ({ sortingProjects }))
  }
})
