import {
  Controller,
  useFormContext,
  RegisterOptions,
  FieldValues
} from 'react-hook-form'
import { SelectHTMLAttributes } from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import { cn } from '~/lib/utils'

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: keyof FieldValues
  label?: string
  options: formOptionsItemType[]
  rules?: RegisterOptions<FieldValues>
  placeholder?: string
  className?: string
}

const Input: React.FC<ISelectProps> = ({
  name,
  label,
  options,
  placeholder,
  rules,
  disabled,
  className
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={cn('relative col-span-2', className)}>
      <label
        htmlFor={name}
        className="mb-2 flex w-full text-sm font-bold uppercase"
      >
        {label}
      </label>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <select
            {...field}
            placeholder={placeholder}
            disabled={disabled}
            className="block h-12 w-full appearance-none rounded-none border border-grayscale-25 bg-white bg-select bg-[right_1rem_top_1rem] bg-no-repeat p-4 px-4 py-3 pr-9 text-base leading-4 focus:rounded-none focus:border-grayscale-50  focus:ring-grayscale-75 "
          >
            {placeholder !== undefined ? (
              <option value="">{placeholder}</option>
            ) : null}
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                disabled={option.rules?.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
        )}
      />
      <FieldErrorMessage
        name={name}
        errors={errors}
      />
    </div>
  )
}

export default Input
