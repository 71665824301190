import { CartSlice, createCartSlice } from './slices/cartSlice'
import {
  ConfiguratorSlice,
  createConfiguratorSlice
} from './slices/ConfiguratorSlice'
import {
  ConditionsSlice,
  createConditionsSlice
} from './slices/conditionsSlice'
import { RoofSlice, createRoofSlice } from './slices/roofSlice'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { PanelAreaSlice, createPanelAreaSlice } from './slices/panelAreaSlice'
import {
  PanelAreaSectionSlice,
  createPanelAreaSectionSlice
} from './slices/panelAreaSectionSlice'
import { ProductSlice, createProductSlice } from './slices/productSlice'
import { ImageSlice, createImageSlice } from './slices/imageSlice'
import { UserSlice, createUserSlice } from './slices/userSlice'
import { SummarySlice, createSummarySlice } from './slices/summarySlice'
import { ProjectSlice, createProjectSlice } from './slices/projectSlice'
import {
  DistanceLabelSlice,
  createDistanceLabelSlice
} from './slices/distanceLabelSlice'
import { createWithEqualityFn } from 'zustand/traditional'
import { createMessageSlice, MessageSlice } from '~/slices/messageSlice'

export type StoreState = ConditionsSlice &
  RoofSlice &
  ConfiguratorSlice &
  PanelAreaSlice &
  PanelAreaSectionSlice &
  ProductSlice &
  ImageSlice &
  UserSlice &
  SummarySlice &
  CartSlice &
  ProjectSlice &
  MessageSlice &
  DistanceLabelSlice

export const useBoundStore = createWithEqualityFn<StoreState>()((...a) => ({
  ...createConfiguratorSlice(...a),
  ...createConditionsSlice(...a),
  ...createRoofSlice(...a),
  ...createPanelAreaSlice(...a),
  ...createPanelAreaSectionSlice(...a),
  ...createProductSlice(...a),
  ...createImageSlice(...a),
  ...createUserSlice(...a),
  ...createSummarySlice(...a),
  ...createCartSlice(...a),
  ...createProjectSlice(...a),
  ...createMessageSlice(...a),
  ...createDistanceLabelSlice(...a)
}))

mountStoreDevtool('Store', useBoundStore)
