import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import FormPosition from './form/FormPosition'
import Section from './form/Section'
import FormRoofMaterial from './form/FormRoofMaterial'
import FormRoofProperties from './form/FormRoofProperties'
import { useEffect, useState } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LowSlopeRoofMaterialForm } from './form/lowSlope/LowSlopeMaterialForm'
import LowSlopePropertiesForm from './form/lowSlope/LowSlopePropertiesForm'
import {
  SettingsDialog,
  SettingsDialogContent,
  SettingsDialogDescription,
  SettingsDialogHeader,
  SettingsDialogTitle
} from '~/components/ui/settings-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

const DrawerConditions = React.memo(() => {
  const { t } = useTranslation()
  const {
    conditions,
    currentRoofUid,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    setShowConditions,
    showConditions
  } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      currentRoofUid: state.currentRoofUid,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      setShowConditions: state.setShowConditions,
      showConditions: state.showConditions
    }),
    shallow
  )
  const { configurationId } = useParams()

  const [openSection, setOpenSection] = useState<{
    [key: string]: 'position' | 'roofMaterial' | 'roofProperties' | undefined
  }>({})

  useEffect(() => {
    if (!showConditions) {
      setOpenSection({})
    }
  }, [showConditions])

  useEffect(() => {
    if (configurationId === undefined) {
      if (!isPositionDataValid) {
        setOpenSection({
          ...openSection,
          [currentRoofUid]: 'position'
        })
      } else if (!isRoofMaterialDataValid) {
        setOpenSection({
          ...openSection,
          [currentRoofUid]: 'roofMaterial'
        })
      } else if (!isRoofPropertiesDataValid) {
        setOpenSection({
          ...openSection,
          [currentRoofUid]: 'roofProperties'
        })
      }
    }
  }, [isPositionDataValid, isRoofMaterialDataValid, isRoofPropertiesDataValid])

  return (
    <SettingsDialog
      open={showConditions === true || showConditions === undefined}
      onOpenChange={() => setShowConditions(!showConditions)}
    >
      <VisuallyHidden.Root>
        <SettingsDialogTitle>''</SettingsDialogTitle>
        <SettingsDialogDescription>''</SettingsDialogDescription>
        <SettingsDialogHeader></SettingsDialogHeader>
      </VisuallyHidden.Root>
      <SettingsDialogContent>
        <Section
          isValid={isPositionDataValid}
          isOpen={openSection[currentRoofUid] === 'position'}
          toggleOpen={() =>
            setOpenSection({
              ...openSection,
              [currentRoofUid]:
                openSection[currentRoofUid] === 'position'
                  ? undefined
                  : 'position'
            })
          }
          title={t('Position')}
          step="1"
        >
          <FormPosition
            isOpen={
              showConditions && openSection[currentRoofUid] === 'position'
            }
            closeSection={() =>
              setOpenSection({
                ...openSection,
                [currentRoofUid]: undefined
              })
            }
            openNextSection={() =>
              setOpenSection({
                ...openSection,
                [currentRoofUid]: 'roofMaterial'
              })
            }
          />
        </Section>
        <hr className="my-6 w-full bg-grayscale-25" />
        <Section
          isValid={isRoofMaterialDataValid}
          isOpen={openSection[currentRoofUid] === 'roofMaterial'}
          toggleOpen={() =>
            setOpenSection({
              ...openSection,
              [currentRoofUid]:
                openSection[currentRoofUid] === 'roofMaterial'
                  ? undefined
                  : 'roofMaterial'
            })
          }
          title={t('Material')}
          step="2"
          disabled={!isPositionDataValid}
        >
          {conditions.configurationSystem === 'parallel' ? (
            <FormRoofMaterial
              isOpen={
                showConditions && openSection[currentRoofUid] === 'roofMaterial'
              }
              closeSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: undefined
                })
              }
              openNextSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: 'roofProperties'
                })
              }
            />
          ) : (
            <LowSlopeRoofMaterialForm
              isOpen={
                showConditions && openSection[currentRoofUid] === 'roofMaterial'
              }
              closeSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: undefined
                })
              }
              openNextSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: 'roofProperties'
                })
              }
            />
          )}
        </Section>
        <hr className="my-6 w-full bg-grayscale-25" />
        <Section
          isValid={isRoofPropertiesDataValid}
          isOpen={openSection[currentRoofUid] === 'roofProperties'}
          toggleOpen={() =>
            setOpenSection({
              ...openSection,
              [currentRoofUid]:
                openSection[currentRoofUid] === 'roofProperties'
                  ? undefined
                  : 'roofProperties'
            })
          }
          className="mb-6"
          title={t('Takegenskaper')}
          step="3"
          disabled={!(isPositionDataValid && isRoofMaterialDataValid)}
        >
          {conditions.configurationSystem === 'parallel' ? (
            <FormRoofProperties
              isOpen={
                showConditions &&
                openSection[currentRoofUid] === 'roofProperties'
              }
              closeSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: undefined
                })
              }
            />
          ) : (
            <LowSlopePropertiesForm
              isOpen={
                showConditions &&
                openSection[currentRoofUid] === 'roofProperties'
              }
              closeSection={() =>
                setOpenSection({
                  ...openSection,
                  [currentRoofUid]: undefined
                })
              }
            />
          )}
        </Section>
      </SettingsDialogContent>
    </SettingsDialog>
  )
})

export default DrawerConditions
