import { StateCreator } from 'zustand'

const initialState: { products: Product[] } = {
  products: []
}

export interface ProductSlice {
  products: Product[],
  updateProducts: (products: Product[]) => void
}

export const createProductSlice: StateCreator<ProductSlice> = (set) => ({
  ...initialState,
  updateProducts: (products) => set(() => ({ products }))
})

