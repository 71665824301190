import * as React from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import { FieldValues, useFormContext, RegisterOptions } from 'react-hook-form'
import { InputHTMLAttributes } from 'react'
import { cn } from '~/lib/utils'
import { getColEnd, getColSpan, getColStart } from '~/utils/tailwind'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/sharp-regular-svg-icons'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: keyof FieldValues
  label?: string | React.ReactNode
  info?: string
  icon?: React.ReactElement
  rules?: RegisterOptions<FieldValues>
  placeholder?: string
  unit?: string
  columnPosition?: {
    start?: number
    span?: number
    end?: number
  }
  hideError?: boolean
  width?: string
  className?: string
}

const Checkbox: React.FC<IInputProps> = ({
  name,
  label,
  step,
  rules,
  placeholder,
  columnPosition,
  hideError,
  width,
  className,
  disabled
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  if (typeof rules === 'object' && rules !== null) {
    rules['setValueAs'] = (v) => (v === '' ? undefined : parseFloat(v))
  }

  const { start, end, span } =
    columnPosition === undefined
      ? { start: false, span: false, end: false }
      : columnPosition

  const hasError = !!Object.getOwnPropertyDescriptor(errors, name)

  return (
    <div
      className={cn(
        'relative flex flex-wrap items-start text-base',
        columnPosition === undefined && 'col-span-full',
        typeof start === 'number' && getColStart(start),
        typeof span === 'number' && getColSpan(span),
        typeof end === 'number' && getColEnd(end),
        width !== undefined && width,
        className
      )}
    >
      <div className={cn('flex items-center', disabled && 'text-grayscale-50')}>
        <input
          {...register(name, rules)}
          id={name}
          type="checkbox"
          step={step}
          placeholder={placeholder}
          className="absolute h-8 w-8 cursor-pointer opacity-0"
          disabled={disabled}
        />
        <div
          className={cn(
            'pointer-events-none relative mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-none border border-black bg-white focus-within:border-pink-700',
            hasError && 'border-red-100'
          )}
        >
          <FontAwesomeIcon
            className="absolute left-[1px] !hidden text-[20px]"
            icon={faCheck}
          />
        </div>
        <label
          htmlFor={name}
          className="cursor-pointer select-none text-base font-light"
        >
          {label}
        </label>
      </div>
      {!(hideError === true) ? (
        <FieldErrorMessage
          name={name}
          errors={errors}
        />
      ) : null}
    </div>
  )
}

export default Checkbox
