import React from 'react'
import RoundButton from '../buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { getNewRoof } from '~/lib/utils'

const AddRoofButton = React.memo(() => {
  const { configurationSystem, setShowConditions, addRoof, setCurrentRoofUid } =
    useBoundStore(
      (state: StoreState) => ({
        configurationSystem: state.conditions.configurationSystem,
        addRoof: state.addRoof,
        setShowConditions: state.setShowConditions,
        setCurrentRoofUid: state.setCurrentRoofUid
      }),
      shallow
    )

  const handleClick = () => {
    if (!configurationSystem) {
      console.log('Invalid configuration system')
      return
    }

    const roof = getNewRoof(configurationSystem)

    addRoof(roof)
    setShowConditions(true)
    setCurrentRoofUid(roof.uid)
  }

  return (
    <RoundButton
      onClick={() => handleClick()}
      className=" z-50 mx-2 mt-1 h-8 w-8 text-[16px] hover:bg-opacity-0"
    >
      <FontAwesomeIcon icon={faPlus} />
    </RoundButton>
  )
})

export default AddRoofButton
