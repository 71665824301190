import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  disabledDialogActions: {
    [key: string]: boolean
  } | null
  handleClose: () => void
}

const SaveLoadedDialog = ({
  dialogActions,
  disabledDialogActions,
  handleClose
}: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <h2 className="text-xl">{t('Spara ändringar')}</h2>
      </div>
      <p className="mb-10">
        {t(
          'Vill du spara ändringarna som en ny konfiguration, eller vill du att ändringarna appliceras på befintlig konfiguration?'
        )}
      </p>
      <div className="flex gap-4">
        <Button
          onClick={() => dialogActions?.SaveNew()}
          variant="ghost"
          disabled={disabledDialogActions?.saveNew}
        >
          {t('Spara som ny')}
        </Button>
        <Button
          onClick={() => dialogActions?.Save()}
          disabled={disabledDialogActions?.save}
        >
          {t('Spara befintlig')}
        </Button>
      </div>
    </DialogBox>
  )
}

export default SaveLoadedDialog
