import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '~/components/ui/select'
import { cn } from '~/lib/utils'
import FieldErrorMessage from '../FieldErrorMessage'

type Props = {
  name: keyof FieldValues
  label?: string
  options: formOptionsItemType[]
  rules?: RegisterOptions<FieldValues>
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (event: any) => void
}

const NewSelect = ({
  name,
  label,
  options,
  rules,
  className,
  placeholder,
  disabled,
  onChange
}: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={cn('relative col-span-2', className)}>
      <label
        htmlFor={name}
        className="mb-2 flex w-full text-sm font-bold uppercase"
      >
        {label}
      </label>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field }) => (
          <>
            <Select
              value={field.value}
              defaultValue={field.value}
              onValueChange={(e) => {
                field.onChange(e)

                if (onChange) {
                  onChange(e)
                }
              }}
              disabled={disabled}
            >
              <SelectTrigger ref={field.ref}>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {options.map((option, index) => (
                    <SelectItem
                      key={index}
                      value={option.value}
                      disabled={option.rules?.disabled}
                    >
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </>
        )}
      />
      <FieldErrorMessage
        name={name}
        errors={errors}
      />
    </div>
  )
}

export default NewSelect
