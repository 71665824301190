import { Label, Tag, Text } from 'react-konva'

type Props = {
  x: number
  y: number
  text: string
  textSize?: number
  scale?: number
}

const MeasurementLabel = ({ x, y, text, textSize = 10, scale = 1 }: Props) => {
  const width = 51 / scale
  const height = 23 / scale
  return (
    <Label
      x={x}
      y={y}
      offset={{ x: width / 2, y: height / 2 }}
    >
      <Tag fill="#404040" />
      <Text
        fontSize={textSize / scale}
        fontFamily="Roboto"
        fontStyle="bold"
        text={text}
        height={height}
        width={width}
        align="center"
        verticalAlign="middle"
        fill="hsl(0, 0%, 100%)"
      />
    </Label>
  )
}

export default MeasurementLabel
