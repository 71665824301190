import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './form/fields/Input'
import Button from './buttons/Button'
import { useState } from 'react'
import { authClient } from '../http/api'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'

const validationSchema = z.object({
  email: z.string().email(),
  loginUrl: z.string()
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const ForgotPassword = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      loginUrl: window.location.origin
    }
  })
  const { handleSubmit, watch } = form
  const email = watch('email')

  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleApiResponse = () => {
    setDisableSubmit(false)
    navigateTo(`/reset-password-sent/${email}`)
  }

  const handleFailedApiResponse = (error: AxiosError) => {
    Sentry.captureException(error)
    setDisableSubmit(false)
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    setDisableSubmit(true)
    authClient
      .post('/forgot-password', data)
      .then(handleApiResponse)
      .catch(handleFailedApiResponse)
  }

  return (
    <div className="w-[640px] bg-white px-20 py-24 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
      <h1 className="heading-l mb-6">{t('Glömt lösenord')}</h1>
      <p className="mb-6">
        {t('Ange din e-postadress nedan för att återställa ditt lösenord.')}
      </p>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mb-6"
        >
          <div className="mb-10 flex flex-col gap-6">
            <Input
              label={t('E-Postadress') || ''}
              name="email"
              type="email"
            />
          </div>
          <Input
            name="loginUrl"
            type="hidden"
          />
          <Button
            className="mb-2 w-full"
            disabled={disableSubmit}
          >
            {t('Återställ lösenord')}
          </Button>
        </form>
        <a
          href="#login"
          className="block w-full text-center"
        >
          {t('Logga in')}
        </a>
      </FormProvider>
    </div>
  )
}

export default ForgotPassword
