import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Button from './buttons/Button'

const Verify = () => {
  const { t } = useTranslation()
  const { email, link } = useParams()
  return (
    <div className="max-w-[464px] bg-white text-center">
      <h1 className="heading-l mb-6">{t('Verifiera din e-postadress')}</h1>
      <p className="mb-5">
        {t('En verifieringslänk har skickats till ')}
        <strong>{email}</strong>.
        {t(
          ' Kolla din inkorg och klicka på länken för att slutföra din registrering och skapa ett lösenord.'
        )}
      </p>
      <p className="mb-10">
        {t('Om du inte hittar mailet, kolla din skräppost.')}
      </p>
      <Button
        onClick={() => {
          window.location.href = decodeURIComponent(link || '')
        }}
      >
        {t('Skicka igen')}
      </Button>
    </div>
  )
}

export default Verify
