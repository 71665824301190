import { cn, savePanel } from '~/lib/utils'
import { t } from 'i18next'
import DynamicTable from './DynamicTable'
import { StoreState, useBoundStore } from '~/store'
import { TableHeaderType } from './DynamicTable'
import { Button } from '~/components/ui/button'
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid';

const SavedPanels = () => {
  const { user, currentPanel, setCurrentPanel, setTriggerDialog, setUser } =
    useBoundStore((state: StoreState) => state)

  /** If no user, it doesn't make sense to show anything. */
  if (!user) return null

  if (user.panels.length === 0)
    return (
      <section id="saved-panels">
        <p className={cn('mb-10')}>
          {t(
            'Du har inga sparade paneler för tillfället. Skapa en ny panel genom att klicka på knappen nedan.'
          )}
        </p>
        <Button
          className={cn(
            'col-span-2 block border border-grayscale-75 bg-transparent text-grayscale-75 hover:bg-grayscale-75 hover:text-white disabled:text-white'
          )}
          onClick={() => {
            setCurrentPanel(null)
            setTriggerDialog('CreateEditPanelDialog')
          }}
          variant="ghost"
        >
          {t('Ny panel')}
        </Button>
      </section>
    )

  /** Only the data corresponding to the specified header will be shown */
  const tableHeaders: TableHeaderType[] = [
    { key: 'name', name: 'Panelens namn' },
    { key: 'width', name: 'Bredd' },
    { key: 'height', name: 'Höjd' },
    { key: 'weight', name: 'Vikt' }
  ]

  const tableData = user.panels.map((panel) => ({
    uid: panel.uid,
    name: panel.name,
    width: `${panel.width} mm`,
    height: `${panel.height} mm`,
    weight: `${panel.weight} kg`
  })).toSorted((a, b) => a.name.localeCompare(b.name))

  const handleDelete = () => {
    if (!currentPanel) return

    setTriggerDialog('DeletePanelDialog')
  }

  const handleDuplicate = () => {
    if (!currentPanel) return

    setTimeout(() => {
      const panelInfo: Partial<PanelInfo> = {...currentPanel, uid: uuidv4(), name: `${currentPanel.name} - ${t('Kopia')}`}
    
      savePanel(
        panelInfo,
        (res) => setUser({ ...user, panels: res.data.panels } as User),
        (error: AxiosResponse) => {
          Sentry.captureException(error)
          setTriggerDialog('SomethingWentWrongDialog')
        }
      )
    }, 200)
  }

  const handleCreateEdit = () => {
    setTriggerDialog('CreateEditPanelDialog')
  }

  return (
    <section id="saved-panels">
      <h1 className={cn('font-bold mb-8')}>{t('Mina paneler')}</h1>

      <DynamicTable
        className={cn('mb-10')}
        headers={tableHeaders}
        data={tableData}
        showRowElipsis={true}
        rowIdentifier="uid"
        onClick={(uid) => setCurrentPanel(uid as string, user.panels)}
        handleDelete={handleDelete}
        handleEdit={handleCreateEdit}
        handleDuplicate={handleDuplicate}
      />

      <Button
        className={cn(
          'col-span-2 block border border-grayscale-75 bg-transparent text-grayscale-75 hover:bg-grayscale-75 hover:text-white disabled:text-white'
        )}
        onClick={() => {
          setCurrentPanel(null)
          handleCreateEdit()
        }}
        variant="ghost"
      >
        {t('Ny panel')}
      </Button>
    </section>
  )
}

export default SavedPanels
