import { createRef, useEffect, useState } from 'react'
import { Layer, Stage } from 'react-konva'
import Konva from 'konva'
import PointerGuides from './PointerGuides'
import Roof from './Roof'
import { StoreState, useBoundStore } from '~/store'
import { KonvaEventObject } from 'konva/lib/Node'
import { scaleMmToPixels, scalePixelsToMm } from '~/utils/configurator'
import { shallow } from 'zustand/shallow'
import { useNavigate, useParams } from 'react-router-dom'
import CanvasZoom from '../CanvasZoom'
import CanvasToolbar from '../CanvasToolbar'
import { solarPanelClient } from '~/http/api'
import { useTranslation } from 'react-i18next'
import BackButton from '~/components/buttons/BackButton'
import { createPanelArea } from '~/lib/panelAreaUtils'
import {
  getScrollValue,
  handleLoadConfiguration,
  handleOnClick,
  handleOnDragEnd,
  handleOnDragStart,
  resetAllConfiguratorStates,
  updateRoofsPositions
} from '~/lib/canvasUtils'
import { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'
import { cn } from '~/lib/utils'
import DistanceLabelActions from '../DistanceLabelActions'

const Canvas = () => {
  const { t } = useTranslation()
  const { configurationId } = useParams()
  const navigateTo = useNavigate()

  const stageRef = createRef<Konva.Stage>()
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 })
  const [roofPointerPosition, setRoofPointerPosition] = useState({ x: 0, y: 0 })
  const [mainPosition, setMainPosition] = useState<{
    [key: string]: Position
  }>({})
  const [scale, setScale] = useState<{ [key: string]: number }>({})
  const [currentStage, setCurrentStage] = useState<Konva.Stage>()

  useEffect(() => {
    if (stageRef.current !== null && currentStage === undefined) {
      setCurrentStage(stageRef.current)
    }
  }, [stageRef])

  useEffect(() => {
    setMainPosition({})
    if (!isPositionDataValid && configurationId === undefined) {
      navigateTo('/projects')
    }
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsDrawing(false)
        setActiveArea(null)
      }
    }
    window.addEventListener('keydown', onKeydown)
    return () => {
      window.removeEventListener('keydown', onKeydown)
    }
  }, [])

  const handleLoadConfigurationError = (error: AxiosError) => {
    if (error.response?.status === 401 && user !== null) {
      setTriggerDialog('UnauthorizedDialog')
    } else if (error.response?.status === 401 && user === null) {
      setShowDialog('LoginDialog')
      setTriggerDialog('')
    } else if (error.response?.status === 403 && user) {
      setTriggerDialog('CannotBeResumedDialog')
    } else if (error.response?.status === 403 && user === null) {
      setTriggerDialog('UnauthorizedDialog')
    } else if (error.response?.status === 404) {
      setTriggerDialog('NotFoundDialog')
    } else {
      Sentry.captureException(error)
      setTriggerDialog('SomethingWentWrongDialog')
    }
  }

  useEffect(() => {
    loadConfiguration()
  }, [configurationId])

  const {
    roof,
    roofs,
    user,
    isDrawing,
    isRedrawing,
    activeArea,
    panelInfo,
    panelInfoLow,
    panelAreas,
    isConfigurationComplete,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    isRoofMaterialDataValidObject,
    isRoofPropertiesDataValidObject,
    showResults,
    configurationSystem,
    isLoaded,
    currentRoofUid,
    setIsLoaded,
    setIsDrawing,
    setIsRedrawing,
    addPanelArea,
    setActiveArea,
    updatePanelArea,
    setPanelAreaSections,
    setIsConfigurationComplete,
    setShowConditions,
    setShowResults,
    setIsComplete,
    setShowDialog,
    setIsEdited,
    setTriggerDialog,
    setRunImageCreation
  } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      roof: state.computed.currentRoof,
      user: state.user,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      activeArea: state.activeArea,
      panelInfo: state.panelInfo,
      panelInfoLow: state.panelInfoLow,
      panelAreas: state.panelAreas,
      isConfigurationComplete: state.isConfigurationComplete,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      isRoofMaterialDataValidObject: state.isRoofMaterialDataValidRoofSettings,
      isRoofPropertiesDataValidObject:
        state.isRoofPropertiesDataValidRoofSettings,
      showResults: state.showResults,
      configurationSystem: state.conditions.configurationSystem,
      isLoaded: state.isLoaded,
      currentRoofUid: state.currentRoofUid,
      setIsLoaded: state.setIsLoaded,
      setIsDrawing: state.setIsDrawing,
      setIsRedrawing: state.setIsRedrawing,
      addPanelArea: state.addPanelArea,
      setActiveArea: state.setActiveArea,
      updatePanelArea: state.updatePanelArea,
      setPanelAreaSections: state.setPanelAreaSections,
      setIsConfigurationComplete: state.setIsConfigurationComplete,
      setShowConditions: state.setShowConditions,
      setShowResults: state.setShowResults,
      setIsApproved: state.setIsApproved,
      setIsComplete: state.setIsComplete,
      setShowDialog: state.setShowDialog,
      setIsEdited: state.setIsEdited,
      setTriggerDialog: state.setTriggerDialog,
      setRunImageCreation: state.setRunImageCreation
    }),
    shallow
  )

  useEffect(() => {
    setActiveArea(null)
  }, [currentRoofUid])

  const loadConfiguration = () => {
    if (configurationId !== undefined) {
      resetAllConfiguratorStates()
      setShowConditions(false)
      setTriggerDialog('LoadingConfigurationDialog')
      solarPanelClient
        .get(`/load/${configurationId}`)
        .then(handleLoadConfiguration)
        .catch(handleLoadConfigurationError)
    }
  }

  useEffect(() => {
    if (user !== null && configurationId !== undefined && isLoaded === false) {
      loadConfiguration()
    }
  }, [user])

  const [stageSize, setStageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleWindowResize = () => {
    setStageSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    handleWindowResize()
    // Resize event listener
    window.addEventListener('resize', handleWindowResize)
    return () => {
      document.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  /**
   * Sets the roof position by updating the main layer position
   *
   * @param event
   */
  const handleMainDragMove = (event: KonvaEventObject<DragEvent>) => {
    if (activeArea !== null && event.target.attrs.name !== 'panel-area-group') {
      setActiveArea(null)
    }
    if (event.target.attrs.name !== 'Main') {
      return
    }
    handleSetMainPosition(event.target.position())
  }

  /**
   * Used for creating panel area and handling isDrawing state.
   *
   * @param event KonvaEventObject<MouseEvent>
   */
  const handleStageMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    if (roof === undefined) return
    setMouseDown(true)
    // Set the mouse down position used for calculation the dragging of the main layer (Roof positions on the canvas)
    if (
      mainPosition[isConfigurationComplete ? 'main' : roof.uid] !== undefined
    ) {
      setMouseDownPosition({
        x:
          pointerPosition.x -
          mainPosition[isConfigurationComplete ? 'main' : roof.uid].x,
        y:
          pointerPosition.y -
          mainPosition[isConfigurationComplete ? 'main' : roof.uid].y
      })
    }

    // Check if panel area should be created
    if (
      isDrawing &&
      !event.target.attrs.isPanelArea &&
      event.evt.button === 0 &&
      (activeArea === undefined || activeArea === null)
    ) {
      const panelAreaData = createPanelArea(
        {
          x: roofPointerPosition.x - roof.position.x,
          y: roofPointerPosition.y - roof.position.y
        },
        configurationSystem === 'low' ? panelInfoLow : panelInfo,
        roof.uid,
        configurationSystem === 'low' ? panelInfoLow.system : 'parallel'
      )
      setIsDrawing(true)
      setActiveArea(panelAreaData.uid)
      addPanelArea(panelAreaData)
      // Abort panel area drawing if other than left mouse button is clicked.
    } else if (event.evt.button !== 0 && isDrawing) {
      setIsDrawing(false)
    }
  }

  const [mouseDown, setMouseDown] = useState(false)
  const [mouseDownPosition, setMouseDownPosition] = useState({
    x: 0,
    y: 0
  })

  /**
   * Sets pointer, roof pointer position, while creating panel area
   * determines and updates the initial size of the panel area.
   *
   * @param event KonvaEventObject<MouseEvent>
   * @returns
   */
  const handleStageMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    if (roof === undefined) return
    // Position of the entire stage origin top left
    const position = event.target.getStage()?.getPointerPosition()

    const roofPointerPosition = event.target
      .getStage()
      ?.findOne('.roof')
      ?.getRelativePointerPosition()

    if (
      position === null ||
      position === undefined ||
      roofPointerPosition === null ||
      roofPointerPosition === undefined
    ) {
      return
    }

    if (
      mouseDown &&
      !isDrawing &&
      pointerPosition.x > 0 &&
      pointerPosition.x < stageSize.width &&
      pointerPosition.y > 0 &&
      pointerPosition.y < stageSize.height
    ) {
      handleSetMainPosition({
        x: pointerPosition.x - mouseDownPosition.x,
        y: pointerPosition.y - mouseDownPosition.y
      })
    }

    setPointerPosition(position)
    setRoofPointerPosition(roofPointerPosition)

    const panelArea = panelAreas.find(
      (panelArea) => panelArea.uid === activeArea
    )

    if (
      panelArea === undefined ||
      activeArea === null ||
      !isDrawing ||
      isRedrawing
    ) {
      return
    }

    const rescaledPosition = scaleMmToPixels<Position>(panelArea.position)
    const width = roofPointerPosition.x - rescaledPosition.x - roof.position.x
    const height = roofPointerPosition.y - rescaledPosition.y - roof.position.y

    event.target.getStage()?.findOne(`#${activeArea}`)?.width(width)
    event.target.getStage()?.findOne(`#${activeArea}`)?.height(height)

    updatePanelArea({
      ...panelArea,
      size: scalePixelsToMm({ width, height })
    })
  }

  /**
   * Handles end of drawing panel area
   *
   * @param event
   */
  const handleStageMouseUp = (event: KonvaEventObject<MouseEvent>) => {
    setMouseDown(false)
    if (isDrawing || isRedrawing) {
      finishDrawingPanelArea(event)
    }
  }

  /**
   * Handles states related to drawing panel area
   *
   * @param event
   */
  const finishDrawingPanelArea = (event: KonvaEventObject<MouseEvent>) => {
    if (
      !event.target.attrs.isPanelArea &&
      isDrawing &&
      activeArea !== undefined &&
      activeArea !== null
    ) {
      setIsDrawing(false)
    }
    setIsDrawing(false)
    setIsRedrawing(false)
  }

  const handleSetScale = (newScale: number, reset = false) => {
    if (roof === undefined) return
    if (reset) {
      setScale({ [roof.uid]: newScale })
    } else {
      setScale({ ...scale, [roof.uid]: newScale })
    }
  }

  const handleSetMainPosition = (position?: Position, reset = false) => {
    if (roof === undefined) return
    const key = isConfigurationComplete ? 'main' : roof.uid
    if (position === undefined) {
      position = getRoofMainCenterPosition(roofs[0])
    }
    if (reset) {
      setMainPosition({ [key]: position })
    } else {
      setMainPosition({ ...mainPosition, [key]: position })
    }
  }

  useEffect(() => {
    if (roof === undefined) return
    if (scale[roof.uid] === undefined) {
      setScale({ ...scale, [roof.uid]: getScaleToFitViewport(roof) })
    }
    if (mainPosition[roof.uid] === undefined) {
      setMainPosition({
        ...mainPosition,
        [roof.uid]: getRoofMainCenterPosition(roof)
      })
    }
  }, [roof?.uid])

  useEffect(() => {
    handleSetMainPosition(undefined, true)
    if (isConfigurationComplete) {
      handleSetScale(getScaleToFitViewport(roofs[0]))
    } else if (roof !== undefined) {
      handleSetMainPosition(getRoofMainCenterPosition(roof))
      handleSetScale(getScaleToFitViewport(roof))
    }
  }, [isConfigurationComplete])

  /**
   * Zoom the entire main layer by scrolling relative to the pointer position
   *
   * @param event KonvaEventObject<WheelEvent>
   */
  const handleScrollZoom = (event: KonvaEventObject<WheelEvent>) => {
    if (roof === undefined) return
    if (isDrawing || isRedrawing) {
      return
    }
    const newScale = getScrollValue(scale?.[roof.uid] ?? 1, event)

    if (scale?.[roof.uid] !== newScale) {
      handleSetScale(newScale)
      setIsScrolling(true)
    } else if (isScrolling) {
      setIsScrolling(false)
    }

    // Reposition the main layer so that the zooming
    // is relative to the pointer position
    const mainPosition = event.target
      .getStage()
      ?.findOne('.Main')
      ?.position() || { x: 0, y: 0 }
    const mousePointToX =
      pointerPosition.x -
      ((pointerPosition.x - mainPosition.x) / (scale?.[roof.uid] ?? 1)) *
        newScale
    const mousePointToY =
      pointerPosition.y -
      ((pointerPosition.y - mainPosition.y) / (scale?.[roof.uid] ?? 1)) *
        newScale
    handleSetMainPosition({
      x: mousePointToX,
      y: mousePointToY
    })
  }

  const handleAdjustConfiguration = () => {
    setIsLoaded(false)
    setIsComplete(false)
    setIsConfigurationComplete(false)
    setIsEdited(true)
    setPanelAreaSections([])
    setRunImageCreation(false)
    if (showResults) {
      setShowResults(false)
    }
  }

  const [isScrolling, setIsScrolling] = useState(false)

  let timer: any = null

  useEffect(() => {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => setIsScrolling(false), 150)
    return () => {
      clearTimeout(timer)
    }
  }, [scale])

  const [isDragging, setIsDragging] = useState(false)
  const [isDraggingPanelArea, setIsDraggingPanelArea] = useState(false)

  const getRoofMainCenterPosition = (roof: Roof) => {
    const mainX =
      window.innerWidth / 2 -
      roof.position.x * getScaleToFitViewport(roof) -
      (scaleMmToPixels<number>(roof.measurementA) / 2) *
        getScaleToFitViewport(roof)
    const mainY =
      window.innerHeight / 2 -
      roof.position.y * getScaleToFitViewport(roof) -
      (scaleMmToPixels<number>(roof.measurementB) / 2) *
        getScaleToFitViewport(roof)

    return { x: mainX, y: mainY }
  }

  const getScaleToFitViewport = (roof: Roof) => {
    const zoomedRoofMaxWidth = window.innerWidth - 320
    const zoomedRoofMaxHeight = window.innerHeight - 320
    const roofWidth = scaleMmToPixels<number>(roof.measurementA)
    const roofHeight = scaleMmToPixels<number>(roof.measurementB)
    const zoomX =
      roofWidth > zoomedRoofMaxWidth ? zoomedRoofMaxWidth / roofWidth : 1
    const zoomY =
      roofHeight > zoomedRoofMaxHeight ? zoomedRoofMaxHeight / roofHeight : 1
    return Math.min(zoomX, zoomY)
  }

  useEffect(() => {
    if (
      isPositionDataValid &&
      isRoofMaterialDataValid &&
      isRoofPropertiesDataValid &&
      roof !== undefined
    ) {
      handleSetScale(getScaleToFitViewport(roof))
      handleSetMainPosition(getRoofMainCenterPosition(roof))
    }
  }, [isPositionDataValid, isRoofMaterialDataValid, isRoofPropertiesDataValid])

  useEffect(() => {
    if (roof === undefined) return
    if (
      mainPosition[roof.uid] === undefined &&
      roof.measurementA > 0 &&
      roof.measurementB > 0
    ) {
      handleSetMainPosition(getRoofMainCenterPosition(roof))
    }
    updateRoofsPositions()
  }, [roofs.filter((roof) => !roof.deleted).length, roof?.measurementA])

  if (roof === undefined) return

  return (
    <div
      id="canvas-wrapper"
      className="flex"
    >
      {!isConfigurationComplete ? (
        <CanvasToolbar />
      ) : (
        <BackButton
          className="absolute left-20 top-14 z-10"
          onClick={handleAdjustConfiguration}
        >
          {t('Redigera konfiguration')}
        </BackButton>
      )}
      {isDrawing ? (
        <div className="pointer-events-none absolute right-1/2 top-[116px] z-30 translate-x-1/2 text-base font-light">
          {t('Klicka på Esc för att avsluta ritfunktionen')}
        </div>
      ) : null}
      <Stage
        className="bg-canvas"
        id="stage"
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        draggable={false}
        onMouseMove={handleStageMouseMove}
        onMouseDown={handleStageMouseDown}
        onMouseLeave={() => {
          setMouseDown(false)
        }}
        onMouseEnter={() => {
          setMouseDown(false)
        }}
        onMouseUp={handleStageMouseUp}
        onClick={handleOnClick}
        onWheel={handleScrollZoom}
      >
        {isPositionDataValid &&
        isRoofMaterialDataValid &&
        isRoofPropertiesDataValid ? (
          <Layer
            name="Main"
            draggable={!isDrawing}
            onDragMove={handleMainDragMove}
            onDragStart={(event) => {
              handleOnDragStart(event, setIsDragging, setIsDraggingPanelArea)
            }}
            onDragEnd={() => {
              handleOnDragEnd(setIsDragging, setIsDraggingPanelArea)
            }}
            scale={{ x: scale?.[roof.uid] ?? 1, y: scale?.[roof.uid] ?? 1 }}
            position={
              mainPosition[isConfigurationComplete ? 'main' : roof.uid] ?? {
                x: 0,
                y: 0
              }
            }
          >
            {roofs.map((mappedRoof) =>
              isPositionDataValid &&
              isRoofMaterialDataValidObject[mappedRoof.uid] &&
              isRoofPropertiesDataValidObject[mappedRoof.uid] ? (
                <Roof
                  key={mappedRoof.uid}
                  scale={!isScrolling ? scale?.[roof.uid] ?? 1 : 1}
                  visible={
                    mappedRoof.uid === roof.uid || isConfigurationComplete
                  }
                  roof={mappedRoof}
                  isScrolling={isScrolling}
                  isDragging={isDragging}
                />
              ) : null
            )}
          </Layer>
        ) : null}
        {(isDrawing || activeArea !== null) && !isDraggingPanelArea ? (
          <Layer name="pointerGuides">
            <PointerGuides
              visible={true}
              pointerPosition={pointerPosition}
              mainPosition={mainPosition[roof.uid] ?? { x: 0, y: 0 }}
              roofPosition={roof.position}
              scale={scale?.[roof.uid] ?? 1}
            />
          </Layer>
        ) : null}
      </Stage>
      <div
        className={cn(
          'absolute bottom-14 right-1/2 flex translate-x-1/2 items-center gap-1 bg-white p-1 shadow-lg',
          isConfigurationComplete && 'translate-none right-14 translate-x-0'
        )}
      >
        <DistanceLabelActions />
        <CanvasZoom
          scale={scale?.[roof.uid] ?? 1}
          setScale={handleSetScale}
          stageSize={stageSize}
          mainPosition={
            mainPosition[isConfigurationComplete ? 'main' : roof.uid] ?? {
              x: 0,
              y: 0
            }
          }
          setMainPosition={handleSetMainPosition}
        />
      </div>
    </div>
  )
}

export default Canvas
