import { AxiosResponse } from 'axios'
import { solarPanelClient } from '~/http/api'
import { Project } from '~/slices/projectSlice'
import { useBoundStore } from '~/store'
import * as Sentry from '@sentry/react'
import { addProjectReferenceToLocalStorage } from './utils'

interface ProjectsResponse {
  projects: {
    current_page: number
    data: Project[]
    total: number
    to: number
  }
}

interface ProjectsParams {
  references?: string[]
  search?: string
  orderBy?: string
  order?: 'ASC' | 'DESC'
  page?: number
}

export const handleProjectResponse = (res: AxiosResponse<ProjectsResponse>) => {
  const { setProjects, setDisableLoadMoreProjects } = useBoundStore.getState()

  setProjects({
    userProjects: res.data.projects.data,
    total: res.data.projects.total,
    loaded: res.data.projects.to ?? 0,
    page: 1
  })
  setDisableLoadMoreProjects(false)
}

export const handleLoadMoreProjectsResponse = (
  res: AxiosResponse<ProjectsResponse>
) => {
  const { addUserProjects, setDisableLoadMoreProjects } =
    useBoundStore.getState()

  addUserProjects(
    res.data.projects.data,
    res.data.projects.total,
    res.data.projects.to ?? 0,
    res.data.projects.current_page
  )
  setDisableLoadMoreProjects(false)
}

export const refreshProjects = (
  search: string,
  sorting: { field: string; ascending: boolean }
) => {
  const { user, setDisableLoadMoreProjects } = useBoundStore.getState()

  setDisableLoadMoreProjects(true)
  const params: ProjectsParams = {
    orderBy: sorting.field,
    order: sorting.ascending ? 'ASC' : 'DESC'
  }
  if (search.length) {
    params.search = search
  }
  if (!user) {
    params.references = JSON.parse(
      localStorage.getItem('guestProjectReferences') || '[]'
    )
  }
  ;[
    solarPanelClient
      .get('/projects', { params })
      .then(handleProjectResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setDisableLoadMoreProjects(false)
      })
  ]
}

export const duplicateProject = (reference: string) => {
  const { user, setTriggerDialog } = useBoundStore.getState()

  setTriggerDialog('LoadingProjectDuplicationDialog')

  solarPanelClient
    .post('/duplicate', {
      reference
    })
    .then((response: AxiosResponse<{ reference: string; status: boolean }>) => {
      if (!user) {
        addProjectReferenceToLocalStorage(response.data.reference)
      }
      refreshProjects('', { field: 'updated_at', ascending: false })
      setTriggerDialog('')
    })
    .catch(() => setTriggerDialog('SomethingWentWrongDialog'))
}
