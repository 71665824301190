import React from 'react'
import { Text, Label, Tag } from 'react-konva'

type Props = {
  position: Position
  text: string
  scale: number
}

const MeasurementIndicator = React.memo(({ position, text, scale }: Props) => {
  const offset = {
    x: Math.round(70 / scale) / 2,
    y: Math.round(30 / scale) / 2
  }

  return (
    <Label
      x={position.x}
      y={position.y}
      offset={offset}
    >
      <Tag fill="#404040" />
      <Text
        fontSize={Math.round(10 / scale)}
        fontFamily="Roboto"
        text={text}
        height={Math.round(24 / scale)}
        width={Math.round(56 / scale)}
        align="center"
        verticalAlign="middle"
        fill="#ffffff"
        fontStyle="bold"
      />
    </Label>
  )
})

export default MeasurementIndicator
