import rectangle from './../assets/roof_shape_rectangle.svg'
import angle1 from './../assets/roof_shape_angle_1.svg'
import angle2 from './../assets/roof_shape_angle_2.svg'
import rhomb1 from './../assets/roof_shape_rhomb_1.svg'
import rhomb2 from './../assets/roof_shape_rhomb_2.svg'
import trapezoid from './../assets/roof_shape_trapezoid_1.svg'

export const getRoofShapeImage = (roofShape: string) => {
  switch (roofShape) {
    case 'rectangle':
      return rectangle
    case 'angle1':
      return angle1
    case 'angle2':
      return angle2
    case 'rhomb1':
      return rhomb1
    case 'rhomb2':
      return rhomb2
    case 'trapezoid':
      return trapezoid
  }
}

export const isRoofMeasurementCRequired = (
  roofShape: string,
  roofMeasurementC?: number | null
): boolean => {
  // using the Array.includes method to check if roofCovering is one of the allowed strings
  if (
    [
      'angle1',
      'angle2',
      'rhomb1',
      'rhomb2',
      'trapezoid',
    ].includes(roofShape) &&
    (roofMeasurementC === null || roofMeasurementC === undefined)
  ) {
    return false
  }
  return true
}

export const isTileWidthRequired = (
  roofCovering: string,
  tileWidth?: number | null
): boolean => {
  // using the Array.includes method to check if roofCovering is one of the allowed strings
  if (
    ['concrete_tile', 'brick_tile'].includes(roofCovering) &&
    (tileWidth === null || tileWidth === undefined)
  ) {
    return false
  }
  return true
}

export const isTileHeightRequired = (
  roofCovering: string,
  tileHeight?: number | null
): boolean => {
  // using the Array.includes method to check if roofCovering is one of the allowed strings
  if (
    ['concrete_tile', 'brick_tile'].includes(roofCovering) &&
    (tileHeight === null || tileHeight === undefined)
  ) {
    return false
  }
  return true
}

export const isTopDistanceRequired = (
  roofCovering: string,
  topDistance?: number | null
): boolean => {
  // using the Array.includes method to check if roofCovering is one of the allowed strings
  if (
    [
      'corrugated_tin_metal',
      'profiled_sheet_metal',
      'tile_profiled_sheet_metal'
    ].includes(roofCovering) &&
    (topDistance === null || topDistance === undefined)
  ) {
    return false
  }
  return true
}

export const isSheetMetalMaterialRequired = (
  roofCovering: string
  // sheetMetalMaterial: string | null | undefined
): boolean => {
  // using the Array.includes method to check if roofCovering is one of the allowed strings
  // if (['profiled_sheet_metal', 'tile_profiled_sheet_metal'].includes(roofCovering) && (sheetMetalMaterial === null || sheetMetalMaterial === undefined)) {
  if (
    ['profiled_sheet_metal', 'tile_profiled_sheet_metal'].includes(roofCovering)
  ) {
    return false
  }
  return true
}

export const isLathDimensionRequired = (
  attachment: string,
  lathDimension: string | null | undefined
): boolean => {
  if (
    attachment === 'lath_mount' &&
    (lathDimension === null || lathDimension === undefined)
  ) {
    return false
  }
  return true
}
