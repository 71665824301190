import { Libraries, useJsApiLoader } from '@react-google-maps/api'
import AutoCompletePosition from './AutoCompletePosition'
import Map from './Map'
import { useState } from 'react'
import { cn } from '~/lib/utils'
import { useTranslation } from 'react-i18next'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import ErrorMessage from '~/components/ErrorMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/sharp-solid-svg-icons'

const googleMapsLibraries: Libraries = ['places']

type Props = {
  isOpen: boolean
  handleAddressChange: (address: string, lat: number, lng: number) => void
  handleMapInteraction: (address: string, lat: number, lng: number) => void
  coordinates: {
    latitude: number
    longitude: number
  }
  address: string
  usePortal: boolean
}

const Position = ({
  handleAddressChange,
  handleMapInteraction,
  coordinates,
  address,
  isOpen,
  usePortal
}: Props) => {
  const { t } = useTranslation()
  const [zoom, setZoom] = useState(6)
  const { climateLoadsError } = useBoundStore(
    (state: StoreState) => ({
      climateLoadsError: state.climateLoadsError
    }),
    shallow
  )

  const handleSetZoom = (zoom: number) => {
    setZoom(zoom)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
    language: 'sv',
    preventGoogleFontsLoading: true
  })

  return (
    <>
      <p className={cn('col-span-full mb-2')}>
        {t(
          'Ange position i fältet nedan eller på kartan för att hämta klimatlaster.'
        )}
      </p>
      {!usePortal ? (
        <div className="bg-gold-10 col-span-full flex items-center px-7 py-4">
          <FontAwesomeIcon
            size="xl"
            icon={faCircleExclamation}
            className="text-gold-100"
          />
          <p className="ml-4 text-base font-light">
            {t(
              'Ändringar i position, terrängtyp och krav kommer att appliceras på samtliga takytor i konfigurationen.'
            )}
          </p>
        </div>
      ) : null}
      {isLoaded ? (
        <>
          <Map
            isOpen={isOpen}
            zoom={zoom}
            coordinates={coordinates}
            address={address}
            handleMapInteraction={handleMapInteraction}
            usePortal={usePortal}
          />
          {climateLoadsError ? (
            <ErrorMessage className="col-span-full mb-2">
              <p>{climateLoadsError}</p>
            </ErrorMessage>
          ) : null}
          <AutoCompletePosition
            setZoom={handleSetZoom}
            handleAddressChange={handleAddressChange}
          />
        </>
      ) : null}
    </>
  )
}

export default Position
