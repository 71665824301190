import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import Drawer from './Drawer'
import Section from './form/Section'
// import { useState } from 'react'
import React from 'react'
import BackButton from './buttons/BackButton'
import { useTranslation } from 'react-i18next'
import FormPositionPage from './form/FormPositionPage'

const DrawerPosition = React.memo(() => {
  const { t } = useTranslation()
  const { setShowConditions } = useBoundStore(
    (state: StoreState) => ({
      setShowConditions: state.setShowConditions
    }),
    shallow
  )

  return (
    <Drawer
      isOpen={true}
      handleClose={() => setShowConditions(false)}
      className="bottom-0 left-0 top-0 max-h-none pt-[144px]"
      hideCloseButton={true}
    >
      <BackButton
        className="absolute left-14 top-14"
        onClick={() => {
          history.back()
        }}
      >
        {t('Gå tillbaka')}
      </BackButton>
      <Section
        isValid={true}
        isOpen={true}
        title="Position"
      >
        <FormPositionPage isOpen={true} />
      </Section>
    </Drawer>
  )
})

export default DrawerPosition
