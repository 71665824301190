import { ReactNode, useEffect, useState } from 'react'
import InfoDialog from './InfoDialog'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { StoreState, useBoundStore } from '~/store'

const InfoDialogs = () => {
  const [open, setOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState<{
    title: string
    content: ReactNode
  } | null>()

  const { triggerDialog, dialogMessage } = useBoundStore(
    (state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      dialogMessage: state.dialogMessage
    })
  )

  useEffect(() => {
    const infoDialogs = [
      'ConfigurationNotPossibleDialog',
      'UnauthorizedDialog',
      'NotFoundDialog',
      'CannotBeResumedDialog',
      'SomethingWentWrongDialog',
      'ProjectLockedDialog',
      'InformationUpdatedDialog',
      'PasswordChangedDialog'
    ]
    if (infoDialogs.includes(triggerDialog)) {
      setDialogProps(getDialogProps(triggerDialog))
      setOpen(true)
    } else if (open) {
      setDialogProps(null)
      setOpen(false)
    }
  }, [triggerDialog])

  const getDialogProps = (dialogName: string) => {
    switch (dialogName) {
      case 'ConfigurationNotPossibleDialog':
        return {
          title: t('Konfiguration ej möjlig'),
          content: (
            <Trans>
              <span className="block max-w-[460px]">
                {dialogMessage ??
                  'Konfigurationen är inte möjlig eftersom panelfästens kapacitet överskrids. Försök med andra inställningar eller kontakta oss på 0321-261 60'}
              </span>
            </Trans>
          )
        }
      case 'UnauthorizedDialog':
        return {
          title: t('Behörighet saknas'),
          content: (
            <p className="max-w-[460px]">
              {t('Någonting blev fel på grund av att behörighet saknas.')}
            </p>
          )
        }
      case 'CannotBeResumedDialog':
        return {
          title: t('Kan inte återupptas'),
          content: (
            <p className="max-w-[460px]">
              {t(
                'Projektet är skapat av en gästanvändare och saknar koppling till ditt konto. För att undvika framtida problem, logga in när du skapar nya projekt.'
              )}
            </p>
          )
        }
      case 'SomethingWentWrongDialog':
        return {
          title: t('Något gick fel'),
          content: (
            <Trans>
              <span className="block max-w-[460px]">
                Var vänlig försök igen senare. Om felet kvarstår, kontakta gärna
                oss på &nbsp;
                <span className="font-bold">0321-261 60</span>.
              </span>
            </Trans>
          )
        }
      case 'NotFoundDialog':
        return {
          title: t('404 - Hittades inte'),
          content: (
            <p className="max-w-[460px]">
              {t('Konfigurationen hittades inte.')}
            </p>
          )
        }
      case 'InformationUpdatedDialog':
        return {
          title: t('Uppgifter uppdaterade'),
          content: (
            <p className="max-w-[460px]">
              {t('Dina kontouppgifter har nu uppdaterats.')}
            </p>
          )
        }
      case 'PasswordChangedDialog':
        return {
          title: t('Lösenord ändrat'),
          content: (
            <p className="max-w-[460px]">
              {t(
                'Ditt lösenord har ändrats. Du kan nu använda ditt nya lösenord för att logga in på ditt konto.'
              )}
            </p>
          )
        }
      case 'ProjectLockedDialog':
        return {
          title: t('Projektet är låst'),
          content: (
            <p className="max-w-[460px]">
              {t(
                'Detta projekt är för närvarande låst eftersom det är öppet i en annan session. Avsluta den andra sessionen innan du fortsätter.'
              )}
            </p>
          )
        }
      default:
        return null
    }
  }

  return (
    <InfoDialog
      open={open}
      setOpen={setOpen}
      title={dialogProps?.title}
      content={dialogProps?.content}
    />
  )
}

export default InfoDialogs
