import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import CartTabs from '../CartTabs'

const CartPage = () => {
  return (
    <PageWrapper hideBackButton>
      <SideBar />
      <div className="h-full w-full max-w-[1432px] px-72 py-24">
        <CartTabs selectedTab={0} />
      </div>
    </PageWrapper>
  )
}

export default CartPage
