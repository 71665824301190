import { t } from 'i18next'
import PageWrapper from './PageWrapper'
import SideBar from '../SideBar'
import ProjectTabs from '../ProjectsTabs'

const ProjectsPage = () => {
  return (
    <PageWrapper
      hideBackButton
      className="w-full flex-col"
    >
      <SideBar />
      <section className="ml-auto h-full w-[calc(100%-16rem)] py-24">
        <div className="m-auto h-full max-w-[920px] px-8">
          <h1 className="heading-l mb-10">{t('Mina projekt')}</h1>
          <ProjectTabs />
        </div>
      </section>
    </PageWrapper>
  )
}

export default ProjectsPage
