import { useState } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import Input from '../fields/Input'
import { useTranslation } from 'react-i18next'

type Props = {
  setZoom: (zoom: number) => void
  handleAddressChange: (address: string, lat: number, lng: number) => void
}

const AutoCompletePosition = ({ setZoom, handleAddressChange }: Props) => {
  const { t } = useTranslation()
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>()

  const { conditions, updateConditions } = useBoundStore(
    (state: StoreState) => ({
      conditions: state.conditions,
      updateConditions: state.updateConditions
    }),
    shallow
  )

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const { latitude, longitude } = conditions

  const onPlaceChanged = () => {
    const place = autoComplete?.getPlace()
    if (place !== undefined && place.formatted_address && place.geometry) {
      const formattedAddress = place.formatted_address
      const location = place.geometry.location
      setZoom(14)
      handleAddressChange(
        formattedAddress,
        location?.lat() || latitude,
        location?.lng() || longitude
      )
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConditions({
      ...conditions,
      address: event.target.value
    })
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={{ componentRestrictions: { country: 'se' }, types: ['address'] }}
      className="col-span-full"
    >
      <Input
        label={t('Adress')}
        type="text"
        name="address"
        placeholder={t('Sök på din adress ...')}
        onChange={handleInputChange}
      />
    </Autocomplete>
  )
}

export default AutoCompletePosition
