import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import Radio from '~/components/form/fields/Radio'
import { lowSlopeFormOptions } from '~/components/form/lowSlope/lowSlopeFormOptions'
import Button from '~/components/buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { useDebounce } from 'use-debounce'
import React, { useEffect } from 'react'
import Info from '~/components/Info'

z.setErrorMap(zodI18nMap)

type Props = {
  closeSection: () => void
  isOpen: boolean
  openNextSection: () => void
}

export const LowSlopeRoofMaterialForm = React.memo(
  ({ closeSection, isOpen, openNextSection }: Props) => {
    const { t } = useTranslation()
    const {
      roof,
      conditions,
      isRoofMaterialDataValid,
      updateRoof,
      updateConditions,
      setIsRoofMaterialDataValid,
      setShowConditions
    } = useBoundStore(
      (state: StoreState) => ({
        roof: state.computed.currentRoof,
        conditions: state.conditions,
        isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
        updateRoof: state.updateRoof,
        updateConditions: state.updateConditions,
        setIsRoofMaterialDataValid: state.setIsRoofMaterialDataValid,
        setShowConditions: state.setShowConditions
      }),
      shallow
    )

    if (!roof) {
      return null
    }

    const form = useForm({
      defaultValues: {
        covering: roof.covering,
        tileWidth: roof.tileWidth,
        tileHeight: roof.tileHeight,
        topDistance: roof.topDistance,
        attachment: roof.attachment,
        lathDimension: roof.lathDimension,
        sheetMetalMaterial: roof.sheetMetalMaterial,
        surfaceTreatment: conditions.surfaceTreatment
      }
    })

    const getSheetMetalMaterial = (
      sheetMetalMaterial: string | undefined,
      sheetMetalThickness: string | undefined
    ) => {
      if (!sheetMetalMaterial?.includes('_')) {
        return `${sheetMetalMaterial}_${sheetMetalThickness}`
      }
      return sheetMetalMaterial
    }

    const resetForm = () => {
      reset({
        covering: roof.covering,
        tileWidth: roof.tileWidth,
        tileHeight: roof.tileHeight,
        topDistance: roof.topDistance,
        attachment: roof.attachment,
        lathDimension: roof.lathDimension,
        sheetMetalMaterial: getSheetMetalMaterial(
          roof.sheetMetalMaterial,
          roof.sheetMetalThickness
        ),
        surfaceTreatment: conditions.surfaceTreatment
      })
    }

    useEffect(() => {
      reset()
    }, [roof, conditions])

    const {
      reset,
      watch,
      handleSubmit,
      setValue,
      formState: { isDirty }
    } = form

    const roofMaterialFormData = z.object({
      covering: z.string(),
      tileWidth: z.number().optional().nullable(),
      tileHeight: z.number().optional().nullable(),
      topDistance: z.number().optional().nullable(),
      attachment: z.string(),
      lathDimension: z.string().optional().nullable(),
      sheetMetalMaterial: z.string().optional().nullable(),
      surfaceTreatment: z.string()
    })

    type FormData = z.infer<typeof roofMaterialFormData>

    const covering = watch('covering')
    const attachment = watch('attachment')
    const topDistance = watch('topDistance')
    const [debouncedTopDistance] = useDebounce(topDistance, 300)

    useEffect(() => {
      if (
        covering === 'profiled_sheet_metal' &&
        typeof debouncedTopDistance === 'number'
      ) {
        if (debouncedTopDistance > 150 && attachment === 'short_rail') {
          setValue('attachment', '')
        }
        if (debouncedTopDistance > 300 && attachment === 'long_rail') {
          setValue('attachment', '')
        }
      }
    }, [debouncedTopDistance])

    useEffect(() => {
      resetForm()
    }, [conditions, roof, isOpen])

    const handleOnSubmit = (data: FormData, openNext = false) => {
      let validData = null
      try {
        validData = roofMaterialFormData.parse(data)
        updateConditions({
          ...conditions,
          surfaceTreatment: validData.surfaceTreatment
        })
        let sheetMetalMaterial = undefined
        let sheetMetalThickness = undefined
        if (validData.sheetMetalMaterial !== undefined) {
          sheetMetalMaterial = validData.sheetMetalMaterial?.split('_')[0]
          sheetMetalThickness = validData.sheetMetalMaterial?.split('_')[1]
        }
        updateRoof({
          ...roof,
          covering: validData.covering,
          tileWidth:
            typeof validData.tileWidth === 'number'
              ? validData.tileWidth
              : undefined,
          tileHeight:
            typeof validData.tileHeight === 'number'
              ? validData.tileHeight
              : undefined,
          topDistance:
            typeof validData.topDistance === 'number'
              ? validData.topDistance
              : undefined,
          attachment: validData.attachment,
          lathDimension:
            typeof validData.lathDimension === 'string'
              ? validData.lathDimension
              : undefined,
          sheetMetalMaterial,
          sheetMetalThickness
        })
        setIsRoofMaterialDataValid(true)
        closeSection()
        if (openNext) {
          openNextSection()
        } else {
          setShowConditions(false)
        }
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.log(error.issues)
        }
      }
    }

    const onSubmit: SubmitHandler<FormData> = (data) => {
      handleOnSubmit(data)
    }

    const onSubmitNext: SubmitHandler<FormData> = (data) => {
      handleOnSubmit(data, true)
    }

    return (
      <FormProvider {...form}>
        <form className="col-span-full grid h-auto grid-cols-4 gap-4 overflow-visible pt-6">
          <h3 className="heading-s col-span-full flex">
            {t('Infästning')}{' '}
            <Info
              id="attachment-info"
              text={t('Stödplatta är inkluderad i infästningen')}
            />
          </h3>
          <Radio
            name="attachment"
            options={lowSlopeFormOptions.attachment}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          <Radio
            name="surfaceTreatment"
            options={lowSlopeFormOptions.surfaceTreatment}
            label={t('Ytbehandling')}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
            lowProfile
          />
          {isRoofMaterialDataValid ? (
            <div className="col-span-full flex justify-end">
              <Button onClick={handleSubmit(onSubmit)}>
                {isDirty ? t('Uppdatera') : t('Klar')}
              </Button>
            </div>
          ) : (
            <div className="col-span-full flex justify-end">
              <Button onClick={handleSubmit(onSubmitNext)}>{t('Nästa')}</Button>
            </div>
          )}
        </form>
      </FormProvider>
    )
  }
)
