import { scaleMmToPixels } from '~/utils/configurator'
import { round } from './utils'
import { useBoundStore } from '~/store'

export interface ExtendedRail extends Rail {
  sectionUid: string
  protrudingRail: number
}

/**
 * Creates data for the distance labels between mounts on a rail.
 *
 * @param mounts - An array of mounts.
 * @param railAngle - The angle of the rail.
 * @returns An array of distance labels.
 */
export const getMountDistanceLabels = (
  mounts: Mount[],
  railAngle: number,
  isApproved: boolean
) => {
  const distanceLabels: DistanceLabel[] = []
  let prevPosition = { x: 0, y: 0 }

  mounts.forEach((mount) => {
    const position = { ...mount.position }
    const showLabel =
      railAngle === 0
        ? prevPosition.x === position.x
        : prevPosition.y === position.y
    const value =
      railAngle === 0
        ? position.y - prevPosition.y
        : position.x - prevPosition.x
    if (prevPosition.x === position.x || prevPosition.y === position.y) {
      distanceLabels.push({
        showLabel,
        startPosition: scaleMmToPixels(prevPosition),
        endPosition: scaleMmToPixels(position),
        value: round(value / 10, 1),
        type: 'mount',
        isApproved,
        orientation: railAngle === 0 ? 'vertical' : 'horizontal'
      })
    }
    prevPosition = mount.position
  })

  return distanceLabels
}

/**
 * Creates data for the distance labels between mounts on a rail.
 *
 * @param mounts - The array of mounts.
 * @param railAngle - The angle of the rail.
 * @returns An array of distance labels.
 */
export const getRailDistanceLabels = (
  mounts: Mount[],
  railAngle: number,
  isApproved: boolean
) => {
  const sortByProperty = railAngle === 0 ? 'y' : 'x'
  const mountsSorted = [...mounts].sort(
    (a, b) => a.position[sortByProperty] - b.position[sortByProperty]
  )

  const minXorY = mounts.reduce(
    (min, mount) => {
      const mountValue = mount.position[sortByProperty as keyof Position]
      return mountValue < min ? mountValue : min
    },
    mounts[0].position[sortByProperty as keyof Position]
  )

  const distanceLabels: DistanceLabel[] = []
  let prevPosition = { x: 0, y: 0 }
  let prevRowOrColumn = -1

  mountsSorted.forEach((mount) => {
    const position = { ...mount.position }
    const value =
      railAngle === 0
        ? position.x - prevPosition.x
        : position.y - prevPosition.y
    const showLabel = mount.position[sortByProperty] === minXorY
    if (
      (prevPosition.x === position.x || prevPosition.y === position.y) &&
      prevRowOrColumn === mount.rowOrColumn
    ) {
      distanceLabels.push({
        showLabel,
        startPosition: scaleMmToPixels(prevPosition),
        endPosition: scaleMmToPixels(position),
        value: scaleMmToPixels(value),
        type: 'rail',
        isApproved,
        orientation: railAngle === 0 ? 'vertical' : 'horizontal'
      })
    }
    prevPosition = mount.position
    prevRowOrColumn = mount.rowOrColumn
  })

  return distanceLabels
}

/**
 * Retrieves a panel area from the given array of panel areas based on the provided UID.
 * @param panelAreas - The array of panel areas to search in.
 * @param panelAreaUid - The UID of the panel area to retrieve.
 * @returns The panel area object matching the provided UID, or undefined if not found.
 */
export const getPanelAreaByUid = (
  // panelAreas: PanelArea[],
  panelAreaUid: string
) => {
  const { panelAreas } = useBoundStore.getState()
  return panelAreas.find((panelArea) => panelArea.uid === panelAreaUid)
}

/**
 * Retrieves rails from panel area sections and panel areas.
 * @param panelAreaSections - The array of panel area sections.
 * @param panelAreas - The array of panel areas.
 * @returns An object containing panel area rails.
 */
export const getPanelAreaRails = (
  panelAreaSections: PanelAreaSection[]
  // panelAreas: PanelArea[]
) => {
  return panelAreaSections.reduce(
    (acc, panelAreaSection) => {
      if (panelAreaSection.result && panelAreaSection.result.rails) {
        if (!acc[panelAreaSection.panelAreaUid]) {
          const panelArea = getPanelAreaByUid(
            // panelAreas,
            panelAreaSection.panelAreaUid
          )
          acc[panelAreaSection.panelAreaUid] = {
            rails: [],
            panelInfo: panelArea ? panelArea.panelInfo : null,
            edgeDistance: panelAreaSection
              ? panelAreaSection.result.edgeDistance
              : null,
            protrudingRail: panelAreaSection.result.protrudingRail,
            sectionUid: panelAreaSection ? panelAreaSection.uid : null
          }
        }
        const rails: ExtendedRail[] = panelAreaSection.result.rails.map(
          (rail) => ({
            ...rail,
            sectionUid: panelAreaSection.uid,
            protrudingRail: panelAreaSection.result.protrudingRail
          })
        )
        acc[panelAreaSection.panelAreaUid].rails.push(...rails)
      }
      return acc
    },
    {} as {
      [key: string]: {
        rails: any[]
        panelInfo: PanelInfo | null
        edgeDistance: number | null
        protrudingRail: number
        sectionUid: string | null
      }
    }
  )
}

/**
 * Checks if a rail is within the bounds of a previous rail.
 * @param {ExtendedRail} rail - The rail to check.
 * @param {ExtendedRail} prevRail - The previous rail to compare against.
 * @returns {boolean} - Returns true if the rail is within the bounds of the previous rail, otherwise false.
 */
const isRailWithinBoundsHorizontal = (
  rail: ExtendedRail,
  prevRail: ExtendedRail
) => {
  return (
    (rail.startPosition.x >= prevRail.startPosition.x &&
      rail.startPosition.x <= prevRail.endPosition.x) ||
    (prevRail.startPosition.x >= rail.startPosition.x &&
      prevRail.startPosition.x <= rail.endPosition.x)
  )
}

/**
 * Checks if a rail is within the bounds of a previous rail.
 * @param {ExtendedRail} rail - The rail to check.
 * @param {ExtendedRail} prevRail - The previous rail to compare against.
 * @returns {boolean} - Returns true if the rail is within the bounds of the previous rail, otherwise false.
 */
const isRailWithinBoundsVertical = (
  rail: ExtendedRail,
  prevRail: ExtendedRail
) => {
  return (
    (rail.startPosition.y >= prevRail.startPosition.y &&
      rail.startPosition.y <= prevRail.endPosition.y) ||
    (prevRail.startPosition.y >= rail.startPosition.y &&
      prevRail.startPosition.y <= rail.endPosition.y)
  )
}

/**
 * Creates a distance label for a rail.
 *
 * @param {ExtendedRail} rail - The current rail distance label.
 * @param {ExtendedRail} prevRail - The previous rail distance label.
 * @param {number} edgeDistancePosition - The edge distance position.
 * @returns The created distance label.
 */
const createDistanceLabel = (
  rail: ExtendedRail,
  prevRail: ExtendedRail,
  edgeDistancePosition: number,
  isApproved: boolean
): DistanceLabel => {
  const railAngle = rail.angle
  const showLabel = true
  const type = 'rail'
  const betweenSections = rail.sectionUid !== prevRail.sectionUid
  const xPosition =
    rail.startPosition.x > prevRail.startPosition.x
      ? rail.startPosition.x
      : prevRail.startPosition.x + edgeDistancePosition
  const yPosition =
    rail.startPosition.y > prevRail.startPosition.y
      ? rail.startPosition.y
      : prevRail.startPosition.y + edgeDistancePosition

  let startPosition: Position = { x: 0, y: 0 }
  let endPosition: Position = { x: 0, y: 0 }
  let value: number = 0

  if (railAngle === 90) {
    startPosition = scaleMmToPixels<Position>({
      x: xPosition,
      y: prevRail.startPosition.y
    })
    endPosition = scaleMmToPixels<Position>({
      x: xPosition,
      y: rail.startPosition.y
    })
    value = scaleMmToPixels<number>(
      rail.startPosition.y - prevRail.startPosition.y
    )
  } else if (railAngle === 0) {
    startPosition = scaleMmToPixels<Position>({
      x: prevRail.startPosition.x,
      y: yPosition
    })
    endPosition = scaleMmToPixels<Position>({
      x: rail.startPosition.x,
      y: yPosition
    })
    value = scaleMmToPixels<number>(
      rail.startPosition.x - prevRail.startPosition.x
    )
  }
  return {
    ...rail,
    showLabel,
    startPosition,
    endPosition,
    value,
    type,
    betweenSections,
    isApproved,
    orientation: rail.angle === 90 ? 'vertical' : 'horizontal'
  }
}

/**
 * Creates the distance labels between panels rails.
 *
 * @param rails - The array of rails.
 * @param panelInfo - The panel information.
 * @param edgeDistance - The mount edge distance.
 * @returns An array of distance labels data.
 */
export const getDistanceLabelsBetweenPanels = (
  rails: ExtendedRail[],
  panelInfo: PanelInfo,
  edgeDistance: number,
  protrudingRail: number,
  panelAreaSections: PanelAreaSection[]
): DistanceLabel[] => {
  const distanceLabels: DistanceLabel[] = []
  const railAngle =
    rails.length > 0
      ? rails[0].angle
      : panelInfo.mounting.split('-')[0] === '90'
      ? 90
      : 0
  if (panelInfo.mounting === '2') {
    if (railAngle === 90) {
      let yValues = Array.from(
        new Set(rails.map((rail) => rail.startPosition.y))
      ).sort((a, b) => a - b)

      const findRailsByYPosition = (yPosition: number) => {
        return rails.filter((rail) => rail.startPosition.y === yPosition)
      }
      rails.forEach((rail, index) => {
        if (index % 2 !== 0 || index === 0) {
          return
        }
        const yValuesIndex = yValues.indexOf(rail.startPosition.y)
        const yValue = yValues[yValuesIndex]
        const prevYValue = yValues[yValuesIndex - 1]
        if (prevYValue < yValue - panelInfo.heightMounted) {
          return
        }
        const prevRails =
          yValuesIndex > 0
            ? findRailsByYPosition(yValues[yValuesIndex - 1])
            : null
        if (prevRails?.length === 0 || prevRails === null) {
          return
        }
        const edgeDistancePosition = edgeDistance + protrudingRail
        prevRails.forEach((prevRail) => {
          if (isRailWithinBoundsHorizontal(rail, prevRail)) {
            distanceLabels.push(
              createDistanceLabel(
                rail,
                prevRail,
                edgeDistancePosition,
                checkIfSectionsIsApproved(
                  [rail.sectionUid, prevRail.sectionUid],
                  panelAreaSections
                )
              )
            )
          }
        })
      })
    } else if (railAngle === 0) {
      let xValues = Array.from(
        new Set(rails.map((rail) => rail.startPosition.x))
      ).sort((a, b) => a - b)

      const findRailsByXPosition = (xPosition: number) => {
        return rails.filter((rail) => rail.startPosition.x === xPosition)
      }
      rails.forEach((rail, index) => {
        if (index % 2 !== 0 || index === 0) {
          return
        }
        const xValuesIndex = xValues.indexOf(rail.startPosition.x)
        const xValue = xValues[xValuesIndex]
        const prevXValue = xValues[xValuesIndex - 1]
        if (prevXValue < xValue - panelInfo.widthMounted) {
          return
        }
        const prevRails =
          xValuesIndex > 0
            ? findRailsByXPosition(xValues[xValuesIndex - 1])
            : null
        if (prevRails?.length === 0 || prevRails === null) {
          return
        }
        const edgeDistancePosition = edgeDistance + protrudingRail
        prevRails.forEach((prevRail) => {
          if (isRailWithinBoundsVertical(rail, prevRail)) {
            distanceLabels.push(
              createDistanceLabel(
                rail,
                prevRail,
                edgeDistancePosition,
                checkIfSectionsIsApproved(
                  [rail.sectionUid, prevRail.sectionUid],
                  panelAreaSections
                )
              )
            )
          }
        })
      })
    }
  }
  return distanceLabels
}

/**
 * Checks if all sections with the given IDs are approved.
 *
 * @param sectionIds - An array of section IDs to check.
 * @param panelAreaSections - An array of `PanelAreaSection` objects.
 * @returns A boolean indicating whether all sections are approved.
 */
const checkIfSectionsIsApproved = (
  sectionIds: string[],
  panelAreaSections: PanelAreaSection[]
) =>
  sectionIds.every((sectionId) => {
    const section = panelAreaSections.find(
      (panelAreaSection) => panelAreaSection.uid === sectionId
    )
    return section ? section.result.isApproved : false
  })

/**
 * Generates an array of distance label data for mounts in panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with mount data.
 * @param includeSupportPlates - A boolean indicating whether to include support plates in the calculation.
 * @returns An array of distance label data for mounts.
 */
export const getMountDistanceLabelsData = (
  panelAreaSections: PanelAreaSection[],
  includeSupportPlates: boolean
) => {
  let mountDistanceLabelsData: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const mounts = panelAreaSection.result.mounts
    const railAngle = panelAreaSection.result.railAngle
    const valueAxis = railAngle === 0 ? 'y' : 'x'
    let prevPosition: Position = { x: 0, y: 0 }
    mounts
      .filter((mount) =>
        includeSupportPlates ? true : mount.isSupportPlate !== true
      )
      .forEach((mount: Mount) => {
        const value = mount.position[valueAxis] - prevPosition[valueAxis]
        if (
          prevPosition.x === mount.position.x ||
          prevPosition.y === mount.position.y
        ) {
          mountDistanceLabelsData.push({
            showLabel: true,
            startPosition: scaleMmToPixels<Position>(prevPosition),
            endPosition: scaleMmToPixels<Position>(mount.position),
            value: scaleMmToPixels<number>(value),
            type: 'mount',
            isApproved: panelAreaSection.result.isApproved,
            orientation: railAngle === 0 ? 'vertical' : 'horizontal'
          })
        }
        prevPosition = mount.position
      })
  })
  return mountDistanceLabelsData
}

/**
 * Generates distance data for cross rails in the given panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with rail information.
 * @returns An array of distance labels data for cross rails.
 *
 * The function processes each panel area section to identify cross rails and calculate
 * the distances between them. It then creates distance labels with the calculated distances
 * and other relevant information, such as start and end positions, orientation, and approval status.
 *
 */
export const getCrossRailDistanceData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const panelAreaRails = panelAreaSection.result.rails
    const crossRails = panelAreaRails.filter((rail) => rail.type === 'cross')

    if (crossRails.length > 0) {
      const crossRailOrientation = crossRails[0].angle
        ? 'vertical'
        : 'horizontal'
      const railDistances = panelAreaSection.result.railDistances
      const crossRailOffset = railDistances.reduce((acc, distance, index) => {
        if (index === railDistances.length - 1) {
          return acc + distance / 2
        }
        return acc + distance
      }, 0)

      crossRails.forEach((rail, index) => {
        if (index === 0) return

        const previousRail = crossRails[index - 1]
        const distance =
          crossRailOrientation === 'horizontal'
            ? rail.startPosition.x - previousRail.startPosition.x
            : rail.startPosition.y - previousRail.startPosition.y

        const startPositionY =
          crossRailOrientation === 'horizontal'
            ? previousRail.startPosition.y + crossRailOffset
            : previousRail.startPosition.y
        const endPositionY =
          crossRailOrientation === 'horizontal'
            ? rail.startPosition.y + crossRailOffset
            : rail.startPosition.y

        const startPositionX =
          crossRailOrientation === 'vertical'
            ? previousRail.startPosition.x + crossRailOffset
            : previousRail.startPosition.x
        const endPositionX =
          crossRailOrientation === 'vertical'
            ? rail.startPosition.x + crossRailOffset
            : rail.startPosition.x

        data.push({
          showLabel: true,
          startPosition: scaleMmToPixels<Position>({
            x: startPositionX,
            y: startPositionY
          }),
          endPosition: scaleMmToPixels<Position>({
            x: endPositionX,
            y: endPositionY
          }),
          value: scaleMmToPixels<number>(distance),
          type: 'cross',
          isApproved: panelAreaSection.result.isApproved,
          orientation: crossRailOrientation
        })
      })
    }
  })
  return data
}

/**
 * Generates distance data for main rails in the given panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with rail information.
 * @returns An array of distance labels data for the main rails.
 *
 * The function iterates over each panel area section and filters out the main rails.
 * It then calculates the distance between consecutive main rails and creates distance labels
 * data with the calculated distance, positions, and other relevant information.
 */
export const getMainRailDistanceData = (
  panelAreaSections: PanelAreaSection[]
) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    const panelAreaRails = panelAreaSection.result.rails
    const mainRails = panelAreaRails.filter((rail) => rail.type === 'main')

    if (mainRails.length > 0) {
      const mainRailOrientation = mainRails[0].angle ? 'vertical' : 'horizontal'

      mainRails.forEach((rail, index) => {
        if (index === 0) return

        const previousRail = mainRails[index - 1]
        const distance =
          mainRailOrientation === 'horizontal'
            ? rail.startPosition.x - previousRail.startPosition.x
            : rail.startPosition.y - previousRail.startPosition.y
        const position =
          mainRailOrientation === 'horizontal' ? 'startPosition' : 'endPosition'

        data.push({
          showLabel: true,
          startPosition: scaleMmToPixels<Position>(previousRail[position]),
          endPosition: scaleMmToPixels<Position>(rail[position]),
          value: scaleMmToPixels<number>(distance),
          type: 'rail',
          isApproved: panelAreaSection.result.isApproved,
          orientation: mainRailOrientation
        })
      })
    }
  })
  return data
}

/**
 * Generates an array of distance labels data for rail lengths based on the provided panel area sections.
 *
 * @param panelAreaSections - An array of panel area sections containing results with rail information.
 * @returns An array of distance label data containing information about the rail lengths.
 *
 */
export const getRailLengthsData = (panelAreaSections: PanelAreaSection[]) => {
  const data: DistanceLabel[] = []
  panelAreaSections.forEach((panelAreaSection) => {
    if (panelAreaSection.result.rails.length === 0) return
    const panelAreaRail = panelAreaSection.result.rails[0]
    data.push({
      showLabel: true,
      startPosition: scaleMmToPixels<Position>(panelAreaRail.startPosition),
      endPosition: scaleMmToPixels<Position>(panelAreaRail.endPosition),
      value: scaleMmToPixels<number>(
        panelAreaRail.angle === 0
          ? panelAreaRail.endPosition.y - panelAreaRail.startPosition.y
          : panelAreaRail.endPosition.x - panelAreaRail.startPosition.x
      ),
      type: 'rail-length',
      isApproved: panelAreaSection.result.isApproved,
      orientation: panelAreaRail.angle === 0 ? 'vertical' : 'horizontal'
    })
  })
  return data
}
