import { t } from 'i18next'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs'
import ProductSpecification from '~/components/ProductSpecification'
import Summary from '~/components/Summary'
import { ScrollArea } from '~/components/ui/scroll-area'
import RoofsInformation from '~/components/RoofsInformation'
import { useState } from 'react'

const ResultsTabs = () => {
  const [selectedRoofUid, setSelectedRoofUid] = useState<string | null>(null)

  return (
    <Tabs
      defaultValue="summary"
      className="h-full w-full"
    >
      <TabsList className="flex bg-transparent">
        <TabsTrigger
          value="summary"
          onClick={() => setSelectedRoofUid(null)}
        >
          {t('Sammanfattning')}
        </TabsTrigger>
        <TabsTrigger
          value="productSpecification"
          onClick={() => setSelectedRoofUid(null)}
        >
          {t('Artikelspecifikation')}
        </TabsTrigger>
        <TabsTrigger
          value="roofs"
          onClick={() => setSelectedRoofUid(null)}
        >
          {t('Takytor')}
        </TabsTrigger>
      </TabsList>
      <TabsContent
        value="summary"
        className="h-full"
      >
        <ScrollArea className="h-0 min-h-full flex-grow">
          <Summary />
        </ScrollArea>
      </TabsContent>
      <TabsContent
        value="productSpecification"
        className="h-full"
      >
        <ScrollArea className="h-0 min-h-full flex-grow">
          <ProductSpecification />
        </ScrollArea>
      </TabsContent>
      <TabsContent
        value="roofs"
        className="h-full"
      >
        <ScrollArea className="h-0 min-h-full flex-grow">
          <RoofsInformation
            selectedRoofUid={selectedRoofUid}
            setSelectedRoofUid={setSelectedRoofUid}
          />
        </ScrollArea>
      </TabsContent>
    </Tabs>
  )
}

export default ResultsTabs
