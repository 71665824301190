import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const SaveConfigurationDialog = ({ dialogActions, handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <h2 className="mb-6 text-xl">{t('Vill du spara konfiguration?')}</h2>
      <p className="mb-10">
        {t(
          'Om du sparar din konfiguration innan du avslutar så kommer du kunna ta upp den igen från din historik.'
        )}
      </p>
      <div className="flex gap-4">
        <Button
          onClick={() => {
            dialogActions?.closeWithoutSave()
          }}
          variant="ghost"
        >
          {t('Spara inte')}
        </Button>
        <Button
          onClick={() => {
            dialogActions?.save()
          }}
        >
          {t('Spara')}
        </Button>
      </div>
    </DialogBox>
  )
}

export default SaveConfigurationDialog
