import { Group, Line } from 'react-konva'
import MeasurementLabel from './MeasurementLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  visible: boolean
  pointerPosition: Position
  mainPosition: Position
  roofPosition: Position
  scale: number
}

const PointerGuides = React.memo(
  ({ visible, pointerPosition, mainPosition, roofPosition, scale }: Props) => {
    const { t } = useTranslation()
    return (
      <Group
        name="pointerGuides"
        visible={visible}
      >
        <Line
          x={pointerPosition.x}
          y={0}
          points={[0, 0, 0, window.innerHeight]}
          stroke="hsl(0, 0%, 22%)"
          strokeWidth={1}
          listening={false}
          dash={[4, 4]}
        />
        <MeasurementLabel
          x={pointerPosition.x}
          y={100}
          text={`${(
            (pointerPosition.x - roofPosition.x * scale - mainPosition.x) /
            1 /
            100 /
            scale
          ).toFixed(2)} ${t('m')}`}
        />
        <Line
          x={0}
          y={pointerPosition.y}
          points={[0, 0, window.innerWidth, 0]}
          stroke="hsl(0, 0%, 22%)"
          strokeWidth={1}
          listening={false}
          dash={[4, 4]}
        />
        <MeasurementLabel
          x={64}
          y={pointerPosition.y}
          text={`${(
            (pointerPosition.y - roofPosition.y * scale - mainPosition.y) /
            1 /
            100 /
            scale
          ).toFixed(2)} ${t('m')}`}
        />
      </Group>
    )
  }
)

export default PointerGuides
