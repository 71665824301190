import React from 'react'
import { ErrorMessage } from '@hookform/error-message'

interface IFieldErrorMessageProps {
  name: string
  errors: any
}

const FieldErrorMessage: React.FC<IFieldErrorMessageProps> = ({
  name,
  errors
}) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p className="mt-2 text-[11px] font-normal leading-4 text-red-100">
          {message}
        </p>
      )}
    />
  )
}

export default FieldErrorMessage
