import ShowPlan from './canvas/ShowPlan'
import Results from './Results'
import OverviewImageHandler from '~/components/canvas/konva/image/OverviewImageHandler'
import ImageHandler from './canvas/konva/image/ImageHandler'
import Canvas from './canvas/konva/Canvas'
import DrawerPanelSettings from './DrawerPanelSettings'
import DrawerConditions from './DrawerConditions'
import Compass from './canvas/Compass'
import AddPanelArea from './canvas/AddPanelArea'
import TopBar from './canvas/TopBar'
import SectionImageHandler from '~/components/canvas/konva/image/SectionImageHandler'

const Configurator = () => {
  return (
    <div className="fixed flex w-full flex-wrap bg-silver-50">
      <TopBar />
      <Canvas />
      <DrawerConditions />
      <DrawerPanelSettings />
      <Compass />
      <ShowPlan />
      <Results />
      <AddPanelArea />
      <OverviewImageHandler />
      <ImageHandler />
      <SectionImageHandler />
    </div>
  )
}

export default Configurator
