import { Group, Line } from 'react-konva'
import MeasurementLabel from '../MeasurementLabel'
import React from 'react'
import { round } from '~/lib/utils'
import { useTranslation } from 'react-i18next'

type Props = {
  visible: boolean
  panelAreaPosition: Position
  absolutePosition: Position
  scale: number
}

const PanelAreaGuideLines = React.memo(
  ({ visible, panelAreaPosition, absolutePosition, scale }: Props) => {
    const { t } = useTranslation()
    const dashSize = 4 / scale
    const strokeWidth = 2 / scale
    const textSize = 10
    const labelEdgeMargin = 100 / scale
    return (
      <Group
        name="PanelAreaGuidesLines"
        visible={visible}
      >
        <Line
          x={0}
          y={0}
          points={[
            0,
            -window.innerHeight / scale,
            0,
            window.innerHeight / scale
          ]}
          stroke="hsl(0, 0%, 22%)"
          strokeWidth={strokeWidth}
          listening={false}
          dash={[dashSize, dashSize]}
        />
        <MeasurementLabel
          x={0}
          y={labelEdgeMargin - absolutePosition.y / scale}
          text={`${round(panelAreaPosition.x / 1000, 2)} ${t('m')}`}
          textSize={textSize}
          scale={scale}
        />
        <Line
          x={0}
          y={0}
          points={[-window.innerWidth / scale, 0, window.innerWidth / scale, 0]}
          stroke="hsl(0, 0%, 22%)"
          strokeWidth={strokeWidth}
          listening={false}
          dash={[dashSize, dashSize]}
        />
        <MeasurementLabel
          x={labelEdgeMargin - 48 - absolutePosition.x / scale}
          y={0}
          text={`${round(panelAreaPosition.y / 1000, 2)} ${t('m')}`}
          textSize={textSize}
          scale={scale}
        />
      </Group>
    )
  }
)

export default PanelAreaGuideLines
