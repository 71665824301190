import { t } from 'i18next'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs'
import Projects from './Projects'

const ProjectTabs = () => {
  return (
    <Tabs
      defaultValue="my-projects"
      className="h-full w-full"
    >
      <TabsList className="mb-10 flex bg-transparent">
        <TabsTrigger value="my-projects">{t('Mina projekt')}</TabsTrigger>
        {/* <TabsTrigger value="organisation-projects">
          {t('Organisationens projekt')}
        </TabsTrigger> -------------> Will be implemented in a future release <-------------*/}
      </TabsList>
      <TabsContent
        value="my-projects"
        className="h-full"
      >
        <Projects />
      </TabsContent>
      <TabsContent
        value="organisation-projects"
        className="h-full"
      >
        <Projects />
      </TabsContent>
    </Tabs>
  )
}

export default ProjectTabs
