import i18n from '../../lib/i18n'
const { t } = i18n
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import Input from './fields/Input'
import Radio from './fields/Radio'
import { formOptions } from './formOptions'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  getRoofShapeImage,
  isRoofMeasurementCRequired
} from '../../utils/conditions'
import Slider from './fields/Slider'
import React, { useEffect } from 'react'

const validationSchema = z
  .object({
    roofType: z.string({ invalid_type_error: t('Taktyp är obligatorisk') }),
    ridgeHeight: z
      .number()
      .min(0.01, t('Värdet måste vara större än 0'))
      .max(100),
    roofSlope: z.number().min(1).max(75),
    roofShape: z.string({ invalid_type_error: t('Takform är obligatorisk') }),
    roofMeasurementA: z
      .number({ invalid_type_error: t('Obligatorisk') })
      .min(0.1, t('Värdet måste vara större än 0')),
    // .multipleOf(0.1),
    roofMeasurementB: z
      .number({ invalid_type_error: t('Obligatorisk') })
      .min(0.1, t('Värdet måste vara större än 0')),
    roofMeasurementC: z
      .number({ invalid_type_error: t('Obligatorisk') })
      .min(0.1, t('Värdet måste vara större än 0'))
      .optional()
      .nullable()
  })
  .refine(
    (data) => {
      return isRoofMeasurementCRequired(data.roofShape, data.roofMeasurementC)
    },
    {
      path: ['roofMeasurementC'],
      message: t('Obligatorisk')
    }
  )
  .refine(
    (obj) => {
      if (
        obj.roofMeasurementC === null ||
        obj.roofMeasurementC === undefined ||
        (obj.roofShape !== 'rhomb1' && obj.roofShape !== 'rhomb2')
      ) {
        return true
      }
      return obj.roofMeasurementC > obj.roofMeasurementB
    },
    {
      path: ['roofMeasurementC'],
      message: t('C måste vara längre än B')
    }
  )

type ValidationSchema = z.infer<typeof validationSchema>

z.setErrorMap(zodI18nMap)

type Props = {
  closeSection: () => void
  isOpen: boolean
}

const FormRoofProperties = React.memo(({ closeSection, isOpen }: Props) => {
  const {
    roof,
    conditions,
    isRoofPropertiesDataValid,
    isPositionDataValid,
    isRoofMaterialDataValid,
    updateRoof,
    updateConditions,
    setShowConditions,
    setIsRoofPropertiesDataValid,
    setShowPanelSettings
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.computed.currentRoof,
      conditions: state.conditions,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      updateRoof: state.updateRoof,
      updateConditions: state.updateConditions,
      setShowConditions: state.setShowConditions,
      setIsRoofPropertiesDataValid: state.setIsRoofPropertiesDataValid,
      setShowPanelSettings: state.setShowPanelSettings
    }),
    shallow
  )

  if (!roof) return null

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      roofType: roof.type,
      ridgeHeight: roof.ridgeHeight || undefined,
      roofSlope: roof.slope,
      roofShape: roof.shape,
      roofMeasurementA: roof.measurementA || undefined,
      roofMeasurementB: roof.measurementB || undefined,
      roofMeasurementC: roof.measurementC || undefined
    }
  })

  useEffect(() => {
    reset()
  }, [roof, conditions])

  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty }
  } = form

  const roofPropertiesFormData = z.object({
    roofType: z.string(),
    ridgeHeight: z.number().min(1),
    roofSlope: z.number().min(1).max(75),
    roofShape: z.string(),
    roofMeasurementA: z.number().min(0.1),
    roofMeasurementB: z.number().min(0.1),
    roofMeasurementC: z.union([z.number().min(0.1), z.undefined()])
  })

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    let validData = null
    try {
      validData = roofPropertiesFormData.parse(data)
      updateConditions({
        ...conditions
      })
      updateRoof({
        ...roof,
        type: validData.roofType,
        ridgeHeight: validData.ridgeHeight * 1000,
        slope: validData.roofSlope,
        shape: validData.roofShape,
        measurementA: validData.roofMeasurementA * 1000,
        measurementB: validData.roofMeasurementB * 1000,
        measurementC:
          validData.roofMeasurementC === undefined
            ? 0
            : validData.roofMeasurementC * 1000
      })
      closeSection()
      if (
        isPositionDataValid &&
        isRoofMaterialDataValid &&
        !isRoofPropertiesDataValid
      ) {
        setShowConditions(false)
        setTimeout(() => {
          setShowPanelSettings(true)
        }, 450)
      }
      setIsRoofPropertiesDataValid(true)
      setShowConditions(false)
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const resetForm = () => {
    reset({
      roofType: roof.type,
      ridgeHeight: roof.ridgeHeight / 1000 || undefined,
      roofSlope: roof.slope,
      roofShape: roof.shape,
      roofMeasurementA: roof.measurementA / 1000 || undefined,
      roofMeasurementB: roof.measurementB / 1000 || undefined,
      roofMeasurementC: roof.measurementC / 1000 || undefined
    })
  }

  useEffect(() => {
    resetForm()
  }, [roof, isOpen])

  const roofShape = watch('roofShape')

  useEffect(() => {
    if (roofShape === 'rectangle') {
      setValue('roofMeasurementC', undefined)
    }
  }, [roofShape])

  return (
    <FormProvider {...form}>
      <form
        className="col-span-full grid h-auto grid-cols-4 gap-4 overflow-visible pt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="heading-s col-span-full">{t('Taktäckning')}</h3>
        <Radio
          name="roofType"
          options={formOptions.roofType}
          vertical
          label="Taktyp"
        />
        <Input
          name="ridgeHeight"
          type="number"
          label={t('Nockhöjd') || ''}
          unit={t('m')}
          step="0.01"
          columnPosition={{ span: 2 }}
        />
        <Slider
          name="roofSlope"
          label={t('TAKLUTNING (1-75°)') || ''}
          min={1}
          max={75}
          unit="°"
          rules={{
            max: 75,
            min: 1
          }}
        />
        {/* <FieldWrapper label="Takform taksida"> */}
        <h3 className="heading-s col-span-full">
          {t('Takform på tilltänkt taksida')}
        </h3>
        <Radio
          name="roofShape"
          options={formOptions.roofShape}
          vertical
          rules={{}}
        />
        <h3 className="heading-s col-span-full">
          {t('Mått tilltänkt taksida')}
        </h3>
        <div className="col-span-full mb-6 grid auto-rows-auto grid-cols-3 gap-x-12 gap-y-2">
          <Input
            name="configurationSystem"
            type="hidden"
            value="parallel"
          />
          {roofShape !== null && roofShape !== undefined ? (
            <>
              <div className="col-span-1 row-span-1 flex flex-nowrap items-center">
                <div className="heading-s mr-4">A</div>
                <Input
                  name="roofMeasurementA"
                  type="number"
                  // label="A"
                  unit={t('m')}
                  step="0.1"
                  className="w-full"
                />
              </div>
              <div className="col-span-1 row-start-2 flex flex-nowrap items-center">
                <div className="heading-s mr-4">B</div>
                <Input
                  name="roofMeasurementB"
                  type="number"
                  // label="B"
                  unit="m"
                  step="0.1"
                  className="w-full"
                />
              </div>
            </>
          ) : null}
          {roofShape !== 'rectangle' &&
          roofShape !== null &&
          roofShape !== undefined ? (
            <div className="col-span-1 row-start-3 flex flex-nowrap items-center">
              <div className="heading-s mr-4">C</div>
              <Input
                name="roofMeasurementC"
                type="number"
                // label="C"
                unit={t('m')}
                step="0.1"
              />
            </div>
          ) : null}
          <div className="col-span-2 col-start-2 row-span-3">
            {roofShape !== null && roofShape !== undefined && (
              <img src={getRoofShapeImage(roofShape)} />
            )}
          </div>
        </div>
        {isRoofPropertiesDataValid ? (
          <div className="col-span-full flex justify-end">
            <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
          </div>
        ) : (
          <div className="col-span-full flex justify-end">
            <Button>
              {isPositionDataValid && isRoofMaterialDataValid
                ? t('Klar')
                : t('Uppdatera')}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  )
})

export default FormRoofProperties
