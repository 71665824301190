import React from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import PanelAreaSection from './PanelAreaSection'
import PanelAreaSectionLabel from './PanelAreaSectionLabel'
import { getSectionLabelText } from '~/utils/configurator'

type Props = {
  roof: Roof
  imageView?: boolean
}

const PanelAreaSections = React.memo(({ roof, imageView }: Props) => {
  const {
    panelAreas,
    panelAreaSections,
    sectionsOverviewImageView,
    roofImageScale
  } = useBoundStore(
    (state: StoreState) => ({
      panelAreaSections: state.panelAreaSections,
      panelAreas: state.panelAreas,
      activeArea: state.activeArea,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      sectionsOverviewImageView: state.sectionsOverviewImageView,
      roofImageScale: state.computed.roofImageScale
    }),
    shallow
  )

  if (!roof) return null

  return (
    <>
      {panelAreaSections
        .filter((panelAreaSection) => panelAreaSection.roofUid === roof.uid)
        .map((panelAreaSection, index) => {
          return (
            <React.Fragment key={index}>
              <PanelAreaSection
                key={panelAreaSection.panelAreaUid}
                roof={roof}
                panelAreaSection={panelAreaSection}
                imageView={imageView}
              />
              {imageView === true && sectionsOverviewImageView === true ? (
                <PanelAreaSectionLabel
                  key={`label-${panelAreaSection.panelAreaUid}`}
                  uid={panelAreaSection.uid}
                  position={panelAreaSection.position}
                  size={panelAreaSection.size}
                  labelText={getSectionLabelText(
                    panelAreaSection.uid,
                    panelAreaSection.panelAreaUid,
                    panelAreas,
                    panelAreaSections
                  )}
                  scale={roofImageScale}
                />
              ) : null}
            </React.Fragment>
          )
        })}
    </>
  )
})

export default PanelAreaSections
