import Rail from '../mounting/Rail'
import Mount from '../mounting/Mount'
import { StoreState, useBoundStore } from '~/store'
import Screws from '../mounting/Screws'
import ShortRail from '../mounting/ShortRail'
import { shallow } from 'zustand/shallow'
import React from 'react'
import { Rect } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'

type Props = {
  roof: Roof
  panelAreaSection: PanelAreaSection
  imageView?: boolean
}

const PanelAreaSection = React.memo(
  ({ roof, panelAreaSection, imageView }: Props) => {
    const { activeArea, panelAreas, positionsOverviewImageView } =
      useBoundStore(
        (state: StoreState) => ({
          panelAreas: state.panelAreas,
          sectionsOverviewImageView: state.sectionsOverviewImageView,
          positionsOverviewImageView: state.positionsOverviewImageView,
          activeArea: state.activeArea
        }),
        shallow
      )

    const panelArea = panelAreas.find(
      (panelArea) => panelArea.uid === panelAreaSection.panelAreaUid
    )

    if (panelArea === undefined || panelArea.roofUid !== roof.uid) return

    if (
      (imageView === true && positionsOverviewImageView) ||
      activeArea ||
      !roof
    )
      return null

    return (
      <>
        {!panelAreaSection.result.isApproved ? (
          <Rect
            x={scaleMmToPixels(panelAreaSection.position.x)}
            y={scaleMmToPixels(panelAreaSection.position.y)}
            width={scaleMmToPixels(panelAreaSection.size.width)}
            height={scaleMmToPixels(panelAreaSection.size.height)}
            strokeWidth={1}
            stroke={'#DA0A16'}
          />
        ) : null}
        {roof.attachment !== 'short_rail'
          ? panelAreaSection.result.rails.map((rail) => (
              <Rail
                key={rail.uid}
                rail={rail}
                isApproved={panelAreaSection.result.isApproved}
              />
            ))
          : null}
        {roof.attachment !== 'long_rail' && roof.attachment !== 'short_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <Mount
                key={index}
                mount={mount}
                railAngle={panelArea.railAngle}
                isApproved={panelAreaSection.result.isApproved}
              />
            ))
          : null}
        {roof.attachment === 'long_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <Screws
                key={index}
                position={mount.position}
                railAngle={panelArea.railAngle}
                isApproved={panelAreaSection.result.isApproved}
              />
            ))
          : null}
        {roof.attachment === 'short_rail'
          ? panelAreaSection.result.mounts.map((mount, index) => (
              <ShortRail
                key={index}
                position={mount.position}
                railAngle={panelArea.railAngle}
                isApproved={panelAreaSection.result.isApproved}
              />
            ))
          : null}
      </>
    )
  }
)

export default PanelAreaSection
