import compass_n from '~/assets/compass_n.svg'
import compass_arrow from '~/assets/compass_arrow.svg'
import { StoreState, useBoundStore } from '~/store'

const Compass = () => {
  const { configurationSystem, isConfigurationComplete, orientationFromSouth } =
    useBoundStore((state: StoreState) => ({
      orientationFromSouth: state.roofs[0].orientationFromSouth || 0,
      configurationSystem: state.conditions.configurationSystem,
      isConfigurationComplete: state.isConfigurationComplete
    }))
  if (configurationSystem !== 'low' || isConfigurationComplete) {
    return
  }
  return (
    <div
      style={{ transform: `rotate(${orientationFromSouth}deg)` }}
      className="absolute right-[58px] top-[104px] flex h-14 w-7 origin-center flex-col items-center gap-2"
    >
      <img
        src={compass_n}
        style={{ transform: `rotate(-${orientationFromSouth}deg)` }}
        className="w-3 px-[1px]"
      />
      <img src={compass_arrow} />
    </div>
  )
}

export default Compass
