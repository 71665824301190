import { StateCreator } from 'zustand'

interface ImageSliceData {
  panelSectionImagesData: SectionImageData[]
}

export interface ImageSlice extends ImageSliceData {
  setPanelSectionImagesData: (
    panelSectionImagesData: SectionImageData[]
  ) => void
  resetImage: () => void
}

const initialState: ImageSliceData = {
  panelSectionImagesData: []
}

export const createImageSlice: StateCreator<ImageSlice> = (set) => ({
  ...initialState,
  setPanelSectionImagesData: (panelSectionImagesData) =>
    set(() => ({ panelSectionImagesData })),
  resetImage: () => {
    set({ ...initialState })
  }
})
