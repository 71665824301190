import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sv from './../../locales/sv/translation.json'
import zod_sv from '../translations/zod/se/zod.json'
const resources = {
  sv: {
    translations: sv,
    zod: zod_sv
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'sv', // if you're using a language detector, do not define the lng option
    fallbackLng: 'sv',
    ns: ['translations'],
    defaultNS: ['translations'],
    keySeparator: false,
    nsSeparator: false,
    // debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

export default i18n
