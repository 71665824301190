import React, { ReactNode } from 'react'
import DialogModal from './DialogModal'

type Props = {
  children: ReactNode
  handleClose?: () => void
}

const DialogBox = React.memo(({ children, handleClose }: Props) => {
  const closeModal =
    handleClose === undefined
      ? undefined
      : () => {
          handleClose()
        }

  return (
    <DialogModal handleClose={closeModal}>
      <div className="m-auto flex max-w-sm flex-col items-center text-center">
        {children}
      </div>
    </DialogModal>
  )
})

export default DialogBox
