import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const ConfirmCloseDialog = ({ dialogActions, handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <h2 className="mb-6 text-xl">{t('Är du säker?')}</h2>
      <p className="mb-10">
        {t(
          'Är du säker på att du vill avsluta konfiguratorn? Alla ändringar i pågående konfiguration kommer att gå förlorade.'
        )}
      </p>
      <div className="flex gap-4">
        <Button
          onClick={handleClose}
          variant="ghost"
        >
          {t('Avbryt')}
        </Button>
        <Button
          onClick={() => {
            dialogActions?.closeConfigurator()
          }}
        >
          {t('Avsluta konfiguratorn')}
        </Button>
      </div>
    </DialogBox>
  )
}

export default ConfirmCloseDialog
