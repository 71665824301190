type Props = {
  message: string
}

const FieldMessage = ({ message }: Props) => {
  return (
    <p className="mt-2 text-[11px] font-normal leading-4">
      <b>Information:</b> {message}
    </p>
  )
}

export default FieldMessage
