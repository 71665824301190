import { StateCreator } from 'zustand'

export interface Message {
  title: string
  slug: string
  description: string
  content: string
  published_at: string
  readablePublishedAt: string
  read: boolean
}

interface Messages {
  posts: Message[]
  page: number
  total: number
  loaded: number
}

export interface MessageSlice {
  messages: Messages
  disableLoadMoreMessages: boolean
  setMessages: (messages: Messages) => void
  addMessages: (
    messages: Message[],
    total: number,
    loaded: number,
    page: number
  ) => void
  setDisableLoadMoreMessages: (disableLoadMoreMessages: boolean) => void
}

export const createMessageSlice: StateCreator<MessageSlice> = (set) => ({
  messages: {
    posts: [],
    page: 1,
    total: 0,
    loaded: 0
  },
  disableLoadMoreMessages: false,
  setMessages: (messages: Messages) => set(() => ({ messages })),
  addMessages: (
    messages: Message[],
    total: number,
    loaded: number,
    page: number
  ) =>
    set((state) => ({
      messages: {
        posts: [...state.messages.posts, ...messages],
        total,
        loaded,
        page,
        disableLoadMore: false
      }
    })),
  setDisableLoadMoreMessages: (disableLoadMoreMessages: boolean) =>
    set(() => ({ disableLoadMoreMessages }))
})
