import { useTranslation } from 'react-i18next'
import { cn } from '~/lib/utils'
import { formatCurrency } from '../utils/utils'

type Props = {
  products: Product[]
  variant?: 'cart'
}

const ProductList = ({ products }: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="table w-full border-collapse">
        <div className="table-row text-base">
          <div className="table-cell pb-2 pr-5 font-bold">{t('Antal')}</div>
          <div className="table-cell pb-2 pr-5 font-bold">{t('Art. nr')}</div>
          <div className="table-cell pb-2 pr-5 font-bold">{t('Benämning')}</div>

          {products.length > 0 && products[0].price !== undefined ? (
            <div className="table-cell pb-2 pr-5 text-right font-bold">
              {t('Pris/st')}
            </div>
          ) : null}
          {products.length > 0 && products[0].totalPrice !== undefined ? (
            <div className="table-cell pb-2 text-right font-bold">
              {t('Summa')}
            </div>
          ) : null}
        </div>
        {products.map((product, key) => (
          <div
            key={key}
            className="table-row border-b border-grayscale-25 text-base font-light"
          >
            <div className={cn('table-cell py-2 pr-5')}>{product.quantity}</div>
            <div className={cn('table-cell py-2 pr-5')}>{product.sku}</div>
            <div className={cn('table-cell py-2 pr-5')}>{product.name}</div>
            {product.price !== undefined ? (
              <div
                className={cn(
                  'table-cell whitespace-nowrap py-2 pr-5 text-right',
                  product.originalPrice != product.price && 'text-red-100'
                )}
              >
                {formatCurrency(product.price)}
              </div>
            ) : null}
            {product.totalPrice !== undefined ? (
              <div
                className={cn(
                  'table-cell whitespace-nowrap py-2 text-right',
                  product.originalTotalPrice != product.totalPrice &&
                    'text-red-100'
                )}
              >
                {formatCurrency(product.totalPrice)}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductList
