import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCircleExclamation
} from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { RoofSectionDropDown } from './RoofSectionDropDown'
import RoofSectionStatus from './RoofSectionStatus'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

type Props = {
  open: boolean
  handleOpen: (open: boolean) => void
}

const RoofsOverviewDialog = React.memo(({ open, handleOpen }: Props) => {
  const {
    roofs,
    panelAreas,
    isPositionDataValid,
    isRoofMaterialDataValidRoofSettings,
    isRoofPropertiesDataValidRoofSettings,
    setCurrentRoofUid
  } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      panelAreas: state.panelAreas,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValidRoofSettings:
        state.isRoofMaterialDataValidRoofSettings,
      isRoofPropertiesDataValidRoofSettings:
        state.isRoofPropertiesDataValidRoofSettings,
      setCurrentRoofUid: state.setCurrentRoofUid
    }),
    shallow
  )

  const numberOfCompletedRoofSections = () => {
    let numberOfCompletedRoofSections = 0
    roofs.forEach((roof) => {
      if (
        isPositionDataValid &&
        isRoofMaterialDataValidRoofSettings[roof.uid] &&
        isRoofPropertiesDataValidRoofSettings[roof.uid] &&
        panelAreas.filter((panelArea) => panelArea.roofUid === roof.uid)
          .length > 0
      ) {
        numberOfCompletedRoofSections++
      }
    })
    return numberOfCompletedRoofSections
  }

  const numberOfNonCompletedRoofSections = () => {
    let numberOfNonCompletedRoofSections = 0
    roofs.forEach((roof) => {
      if (
        !isPositionDataValid ||
        !isRoofMaterialDataValidRoofSettings[roof.uid] ||
        !isRoofPropertiesDataValidRoofSettings[roof.uid] ||
        panelAreas.filter((panelArea) => panelArea.roofUid === roof.uid)
          .length === 0
      ) {
        numberOfNonCompletedRoofSections++
      }
    })
    return numberOfNonCompletedRoofSections
  }

  const handleOnClick = (roofUid: string) => {
    setCurrentRoofUid(roofUid)
    handleOpen(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        handleOpen(!open)
      }}
    >
      <DialogContent className="h-full max-h-[calc(100%_-_192px)] max-w-[calc(100%_-_192px)] overflow-auto py-24">
        <VisuallyHidden.Root>
          <DialogTitle>''</DialogTitle>
          <DialogDescription>''</DialogDescription>
          <DialogHeader></DialogHeader>
        </VisuallyHidden.Root>
        <div className="w-full overflow-auto">
          <div className="mx-auto w-full max-w-7xl">
            <div className="mb-10 flex justify-end gap-4">
              {numberOfNonCompletedRoofSections() > 0 ? (
                <div>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faCircleExclamation}
                  />
                  {`${t('{{numberNonCompleted}} Ej kompletta', {
                    numberNonCompleted: numberOfNonCompletedRoofSections()
                  })}`}
                </div>
              ) : null}
              {/* {numberOfCompletedRoofSections() > 0 ? ( */}
              <div className="text-grayscale-50">
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faCheck}
                />
                {`${t('{{numberCompleted}} Kompletta', {
                  numberCompleted: numberOfCompletedRoofSections()
                })}`}
              </div>
              {/* ) : null} */}
            </div>
            <div className="grid grid-cols-3 gap-8">
              {roofs.map((roof) => (
                <div key={roof.uid}>
                  <div
                    className="mb-6 flex aspect-square w-full cursor-pointer cursor-pointer items-center justify-center bg-silver-50"
                    onClick={() => handleOnClick(roof.uid)}
                  >
                    {roof.roofImage ? (
                      <img
                        className="max-h-[calc(100%_-_100px)] max-w-[calc(100%_-_100px)]"
                        src={roof.roofImage}
                      />
                    ) : null}
                  </div>
                  <div className="relative flex font-bold">
                    <span
                      className="cursor-pointer"
                      onClick={() => handleOnClick(roof.uid)}
                    >
                      {roof.name}
                    </span>
                    <RoofSectionDropDown
                      className="block w-auto"
                      roofUid={roof.uid}
                    />
                  </div>
                  <RoofSectionStatus roofUid={roof.uid} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
})

export default RoofsOverviewDialog
