import { StateCreator } from 'zustand'

const initialState: DistanceLabelData = {
  showMountDistanceLabels: true,
  showMountSupportDistanceLabels: true,
  showRailDistanceLabels: true,
  showCrossRailDistanceLabels: true,
  showRailLengthDistanceLabels: true
}

interface DistanceLabelData {
  showMountDistanceLabels: boolean
  showMountSupportDistanceLabels: boolean
  showRailDistanceLabels: boolean
  showCrossRailDistanceLabels: boolean
  showRailLengthDistanceLabels: boolean
}

export interface DistanceLabelSlice extends DistanceLabelData {
  toggleMountDistanceLabels: () => void
  toggleMountSupportDistanceLabels: () => void
  toggleRailDistanceLabels: () => void
  toggleCrossRailDistanceLabels: () => void
  toggleRailLengthDistanceLabels: () => void
}

export const createDistanceLabelSlice: StateCreator<DistanceLabelSlice> = (
  set
) => ({
  ...initialState,
  toggleMountDistanceLabels: () =>
    set((state) => ({
      showMountDistanceLabels: !state.showMountDistanceLabels
    })),
  toggleMountSupportDistanceLabels: () =>
    set((state) => ({
      showMountSupportDistanceLabels: !state.showMountSupportDistanceLabels
    })),
  toggleRailDistanceLabels: () =>
    set((state) => ({ showRailDistanceLabels: !state.showRailDistanceLabels })),
  toggleCrossRailDistanceLabels: () =>
    set((state) => ({
      showCrossRailDistanceLabels: !state.showCrossRailDistanceLabels
    })),
  toggleRailLengthDistanceLabels: () =>
    set((state) => ({
      showRailLengthDistanceLabels: !state.showRailLengthDistanceLabels
    }))
})
