import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { NameProjectForm } from '../form/NameProjectForm'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { solarPanelClient } from '~/http/api'
import { AxiosResponse } from 'axios'

const ProjectNameDialog = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { triggerDialog, project, setTriggerDialog, updateUserProject } =
    useBoundStore((state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      project: state.computedProject.currentProject,
      setTriggerDialog: state.setTriggerDialog,
      updateUserProject: state.updateUserProject
    }))

  const handleResponse = (response: AxiosResponse, name: string) => {
    console.log('response', response)
    if (project !== undefined) {
      updateUserProject({ ...project, name })
    }
    setOpen(false)
    setIsLoading(false)
    setTriggerDialog('')
  }

  const handleFailedResponse = () => {
    setOpen(false)
    setIsLoading(false)
    setTriggerDialog('SomethingWentWrongDialog')
  }

  const handleFormSubmit = (name: string) => {
    setIsLoading(true)
    solarPanelClient
      .post('/rename', { reference: project?.reference, name })
      .then((res) => handleResponse(res, name))
      .catch(handleFailedResponse)
  }

  useEffect(() => {
    if (triggerDialog === 'ProjectNameDialog') {
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Projektnamn')}</DialogTitle>
        </DialogHeader>
        <NameProjectForm
          isLoading={isLoading}
          onFormSubmit={handleFormSubmit}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ProjectNameDialog
