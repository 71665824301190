import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { Button } from '~/components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faArrowDownToLine
} from '@fortawesome/sharp-regular-svg-icons'
import { cn, isRoofNotApproved } from '~/lib/utils'
import RoofInformation from './RoofInformation'
import RoofInformationDropDown from './RoofInformationDropDown'
import GenerateRoofPdfDialog from '~/components/dialogs/GenerateRoofPdfDialog'
import { faCircleExclamation } from '@fortawesome/sharp-solid-svg-icons'

type Props = {
  selectedRoofUid: string | null
  setSelectedRoofUid: (uid: string | null) => void
}

const RoofsInformation = ({ selectedRoofUid, setSelectedRoofUid }: Props) => {
  const { roofs, setTriggerDialog } = useBoundStore((state: StoreState) => ({
    roofs: state.roofs,
    setTriggerDialog: state.setTriggerDialog
  }))

  const [selectedRoof, setSelectedRoof] = useState<Roof | null>(null)
  const [selectedRoofUidForPdf, setSelectedRoofUidForPdf] = useState<string>('')

  const getSelectedRoof = (uid: string) => {
    const roof = roofs.find((roof) => roof.uid === uid)
    return roof !== undefined ? roof : null
  }

  useEffect(() => {
    if (selectedRoofUid !== null) {
      setSelectedRoof(getSelectedRoof(selectedRoofUid))
    }
  }, [selectedRoofUid])

  return (
    <>
      <div
        className={cn(
          'flex h-full flex-grow flex-col items-start',
          selectedRoofUid === null && 'hidden'
        )}
      >
        <Button
          variant="ghost"
          className="mb-6 h-auto p-0 hover:bg-transparent"
          onClick={() => setSelectedRoofUid(null)}
        >
          <FontAwesomeIcon
            className="mr-2"
            icon={faAngleLeft}
          />
          {t('Alla takytor')}
        </Button>
        {selectedRoof !== null ? <RoofInformation roof={selectedRoof} /> : null}
      </div>
      <div className={cn(selectedRoofUid !== null && 'hidden')}>
        <p className="mb-10">
          {t(
            'Nedan listas de takytor som konfigurerats. Klicka på en takyta i listan för mer information.'
          )}
        </p>
        <div>
          {roofs.map((roof) => (
            <div
              key={roof.uid}
              className="flex cursor-pointer items-center border-t border-grayscale-25 py-3 text-base last:border-b hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                if (e.target === e.currentTarget) {
                  setSelectedRoofUid(roof.uid)
                }
              }}
            >
              <div
                className={cn(
                  'pointer-events-none font-light',
                  isRoofNotApproved(roof.uid) &&
                    'flex items-center text-red-500'
                )}
              >
                {isRoofNotApproved(roof.uid) ? (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="mr-2 text-lg"
                  />
                ) : null}
                {roof.name}
              </div>
              <div className="ml-auto flex items-center">
                <Button
                  variant="round"
                  className="h-8 w-8"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedRoofUidForPdf(roof.uid)
                    setTriggerDialog('GenerateRoofPdfDialog')
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowDownToLine}
                    size="lg"
                  />
                </Button>
                <RoofInformationDropDown
                  roofUid={roof.uid}
                  onGeneratePdf={() => {
                    setSelectedRoofUidForPdf(roof.uid)
                    setTriggerDialog('GenerateRoofPdfDialog')
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <GenerateRoofPdfDialog roofUid={selectedRoofUidForPdf} />
    </>
  )
}

export default RoofsInformation
