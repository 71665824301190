import { StateCreator } from 'zustand'
import { v4 as uuidv4 } from 'uuid'
import i18n from '~/lib/i18n'
const { t } = i18n

const roofUid = uuidv4()

export const initialRoofState: Roof[] = [
  {
    uid: roofUid,
    name: 'Takyta 1',
    snowGuard: false,
    covering: 'concrete_tile',
    tileWidth: 300,
    tileHeight: 330,
    topDistance: 100,
    attachment: 'base_plate',
    lathDimension: '45x70',
    sheetMetalMaterial: 'steel_0.5',
    type: 'gable',
    shape: 'rectangle',
    measurementA: 0,
    measurementB: 0,
    measurementC: 0,
    slope: 1,
    ridgeHeight: 0,
    deleted: false,
    position: {
      x: 0,
      y: 0
    }
  }
]

type RoofBooleanSettings = { [key: string]: boolean }
type RoofNumberSettings = { [key: string]: number }

export interface RoofSlice {
  roofs: Roof[]
  currentRoofUid: string
  showConditionsRoofSettings: RoofBooleanSettings
  showPanelSettingsRoofSettings: RoofBooleanSettings
  isRoofMaterialDataValidRoofSettings: RoofBooleanSettings
  isRoofPropertiesDataValidRoofSettings: RoofBooleanSettings
  isCompleteRoofSettings: RoofBooleanSettings
  roofImageScaleRoofSettings: RoofNumberSettings
  sectionsOverviewImageViewRoofSettings: RoofBooleanSettings
  positionsOverviewImageViewRoofSettings: RoofBooleanSettings
  panelSectionsImageViewRoofSettings: RoofBooleanSettings
  roofsCoordinates: { [key: string]: number[][] }
  roofsUpdateImage: { [key: string]: number }
  sectionsOverviewImageView: boolean
  positionsOverviewImageView: boolean
  computed: {
    activeRoofs: Roof[]
    showPanelSettings: boolean
    currentRoof: Roof | undefined
    isRoofMaterialDataValid: boolean
    isRoofPropertiesDataValid: boolean
    isComplete: boolean
    roofImageScale: number
    panelSectionsImageView: boolean
    roofCoordinates: number[][]
    roofsUpdateImage: number
  }
  addRoof: (roof: Roof) => void
  getCurrentRoof: () => Roof | undefined
  setRoofs: (roofs: Roof[]) => void
  updateRoof: (roof: Roof) => void
  resetRoofs: () => void
  setCurrentRoofUid: (uid: string) => void
  createRoof: () => void
  setIsComplete: (isComplete: boolean) => void
  setIsRoofMaterialDataValid: (isRoofMaterialDataValid: boolean) => void
  setIsRoofPropertiesDataValid: (isRoofPropertiesDataValid: boolean) => void
  setRoofImageScale: (roofImageScale: number, currentRoofUid: string) => void
  setSectionsOverviewImageView: (sectionsOverviewImageView: boolean) => void
  setPositionsOverviewImageView: (positionsOverviewImageView: boolean) => void
  setPanelSectionsImageView: (panelSectionsImageView: boolean) => void
  setShowPanelSettings: (showPanelSettings: boolean) => void
  setRoofCoordinates: (
    roofCoordinates: number[][],
    currentRoofUid: string
  ) => void
  setRoofsUpdateImage: () => void
  setIsRoofMaterialDataValidRoofSettings: (
    settings: RoofBooleanSettings
  ) => void
  setIsRoofPropertiesDataValidRoofSettings: (
    settings: RoofBooleanSettings
  ) => void
}

export const createRoofSlice: StateCreator<RoofSlice> = (set, get) => {
  return {
    roofs: initialRoofState,
    currentRoofUid: roofUid,
    roofCoordinates: {},
    showConditionsRoofSettings: {},
    showPanelSettingsRoofSettings: {},
    isApprovedRoofSettings: {},
    isRoofMaterialDataValidRoofSettings: {},
    isRoofPropertiesDataValidRoofSettings: {},
    isCompleteRoofSettings: {},
    roofImageScaleRoofSettings: {},
    sectionsOverviewImageViewRoofSettings: {},
    positionsOverviewImageViewRoofSettings: {},
    panelSectionsImageViewRoofSettings: {},
    roofsCoordinates: {},
    roofsUpdateImage: {},
    sectionsOverviewImageView: false,
    positionsOverviewImageView: false,

    computed: {
      get activeRoofs() {
        return get().roofs.filter((roof) => !roof.deleted)
      },
      get currentRoof() {
        return get().roofs.find((roof) => roof.uid === get().currentRoofUid)
      },
      get isRoofMaterialDataValid() {
        return get().isRoofMaterialDataValidRoofSettings[get().currentRoofUid]
      },
      get isRoofPropertiesDataValid() {
        return get().isRoofPropertiesDataValidRoofSettings[get().currentRoofUid]
      },
      get isComplete() {
        return get().isCompleteRoofSettings[get().currentRoofUid]
      },
      get roofImageScale() {
        return get().roofImageScaleRoofSettings[get().currentRoofUid]
      },
      get panelSectionsImageView() {
        return get().panelSectionsImageViewRoofSettings[get().currentRoofUid]
      },
      get showPanelSettings() {
        return get().showPanelSettingsRoofSettings[get().currentRoofUid]
      },
      get roofCoordinates() {
        return get().roofsCoordinates[get().currentRoofUid]
      },
      get roofsUpdateImage() {
        return get().roofsUpdateImage[get().currentRoofUid]
      }
    },
    getCurrentRoof: () =>
      get().roofs.find((roof) => roof.uid === get().currentRoofUid),
    setRoofs: (roofs: Roof[]) => set({ roofs }),
    updateRoof: (roofData: Roof) =>
      set((state) => ({
        roofs: state.roofs.map((roof) =>
          roofData.uid === roof.uid ? roofData : roof
        )
      })),
    resetRoofs: () => {
      set({ roofs: initialRoofState, currentRoofUid: roofUid })
    },
    setCurrentRoofUid: (uid: string) => set({ currentRoofUid: uid }),
    addRoof: (roof: Roof) =>
      set((state) => {
        return { roofs: [...state.roofs, roof] }
      }),
    createRoof: () =>
      set((state) => {
        const roofUid = uuidv4()
        return {
          roofs: [
            ...state.roofs,
            {
              ...initialRoofState[0],
              name: `${t('Takyta')} ${state.roofs.length + 1}`,
              uid: roofUid
            }
          ],
          currentRoofUid: roofUid
        }
      }),
    setIsComplete: (isComplete: boolean) =>
      set((state) => ({
        isCompleteRoofSettings: {
          ...state.isCompleteRoofSettings,
          [state.currentRoofUid]: isComplete
        }
      })),
    setIsRoofMaterialDataValid: (isRoofMaterialDataValid: boolean) =>
      set((state) => ({
        isRoofMaterialDataValidRoofSettings: {
          ...state.isRoofMaterialDataValidRoofSettings,
          [state.currentRoofUid]: isRoofMaterialDataValid
        }
      })),
    setIsRoofPropertiesDataValid: (isRoofPropertiesDataValid: boolean) =>
      set((state) => ({
        isRoofPropertiesDataValidRoofSettings: {
          ...state.isRoofPropertiesDataValidRoofSettings,
          [state.currentRoofUid]: isRoofPropertiesDataValid
        }
      })),
    setRoofImageScale: (roofImageScale: number, currentRoofUid: string) =>
      set((state) => ({
        roofImageScaleRoofSettings: {
          ...state.roofImageScaleRoofSettings,
          [currentRoofUid]: roofImageScale
        }
      })),
    setSectionsOverviewImageView: (sectionsOverviewImageView: boolean) =>
      set(() => ({
        sectionsOverviewImageView: sectionsOverviewImageView
      })),
    setPositionsOverviewImageView: (positionsOverviewImageView: boolean) =>
      set(() => ({
        positionsOverviewImageView: positionsOverviewImageView
      })),
    setPanelSectionsImageView: (panelSectionsImageView: boolean) =>
      set((state) => ({
        panelSectionsImageViewRoofSettings: {
          ...state.panelSectionsImageViewRoofSettings,
          [state.currentRoofUid]: panelSectionsImageView
        }
      })),
    setShowPanelSettings: (showPanelSettings: boolean) =>
      set((state) => ({
        showPanelSettingsRoofSettings: {
          ...state.showPanelSettingsRoofSettings,
          [state.currentRoofUid]: showPanelSettings
        }
      })),
    setRoofCoordinates: (roofCoordinates: number[][], currentRoofUid: string) =>
      set((state) => ({
        roofsCoordinates: {
          ...state.roofsCoordinates,
          [currentRoofUid]: roofCoordinates
        }
      })),
    setRoofsUpdateImage: () =>
      set((state) => ({
        roofsUpdateImage: {
          ...state.roofsUpdateImage,
          [state.currentRoofUid]:
            state.roofsUpdateImage[state.currentRoofUid] === undefined
              ? 0
              : state.roofsUpdateImage[state.currentRoofUid] + 1
        }
      })),
    setIsRoofMaterialDataValidRoofSettings: (settings: RoofBooleanSettings) =>
      set(() => ({
        isRoofMaterialDataValidRoofSettings: settings
      })),
    setIsRoofPropertiesDataValidRoofSettings: (settings: RoofBooleanSettings) =>
      set(() => ({
        isRoofPropertiesDataValidRoofSettings: settings
      }))
  }
}
