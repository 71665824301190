import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLayerGroup,
  faXmarkLarge
} from '@fortawesome/sharp-regular-svg-icons'
import { useCallback, useState } from 'react'
import { t } from 'i18next'
import { cn } from '~/lib/utils'
import { faEye, faEyeSlash } from '@fortawesome/sharp-solid-svg-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Button } from '~/components/ui/button'

const DistanceLabelActions = () => {
  const {
    system,
    panelAreaSections,
    isConfigurationComplete,
    showMountDistanceLabels,
    showMountSupportDistanceLabels,
    showRailDistanceLabels,
    showCrossRailDistanceLabels,
    showRailLengthDistanceLabels,
    toggleMountDistanceLabels,
    toggleMountSupportDistanceLabels,
    toggleRailDistanceLabels,
    toggleCrossRailDistanceLabels,
    toggleRailLengthDistanceLabels
  } = useBoundStore(
    (state: StoreState) => ({
      system: state.conditions.configurationSystem,
      panelAreaSections: state.panelAreaSections,
      isConfigurationComplete: state.isConfigurationComplete,
      showMountDistanceLabels: state.showMountDistanceLabels,
      showMountSupportDistanceLabels: state.showMountSupportDistanceLabels,
      showRailDistanceLabels: state.showRailDistanceLabels,
      showCrossRailDistanceLabels: state.showCrossRailDistanceLabels,
      showRailLengthDistanceLabels: state.showRailLengthDistanceLabels,
      toggleMountDistanceLabels: state.toggleMountDistanceLabels,
      toggleMountSupportDistanceLabels: state.toggleMountSupportDistanceLabels,
      toggleRailDistanceLabels: state.toggleRailDistanceLabels,
      toggleCrossRailDistanceLabels: state.toggleCrossRailDistanceLabels,
      toggleRailLengthDistanceLabels: state.toggleRailLengthDistanceLabels
    }),
    shallow
  )

  const [openActions, setOpenActions] = useState(false)

  const handleToggleMountDistanceLabels = () => {
    toggleMountDistanceLabels()
    if (showMountDistanceLabels && showMountSupportDistanceLabels) {
      toggleMountSupportDistanceLabels()
    }
    if (!showMountDistanceLabels && !showMountSupportDistanceLabels) {
      toggleMountSupportDistanceLabels()
    }
  }

  const handleToggleMountSupportDistanceLabels = () => {
    toggleMountSupportDistanceLabels()
    if (!showMountDistanceLabels) {
      toggleMountDistanceLabels()
    }
  }

  const hasSupportPlates = useCallback(() => {
    return panelAreaSections.some((section) =>
      section.result.mounts.some((mount) => mount.isSupportPlate)
    )
  }, [panelAreaSections])

  if (!isConfigurationComplete) {
    return <></>
  }

  return (
    <div>
      <DropdownMenu
        open={openActions}
        onOpenChange={setOpenActions}
        modal={false}
      >
        <DropdownMenuTrigger asChild>
          <div className="flex items-center">
            <Button
              onClick={(event) => event.stopPropagation()}
              className={cn(
                'mr-1 h-10 min-w-0 bg-white px-3 py-2 text-black hover:bg-silver-25',
                openActions && 'bg-silver-25'
              )}
            >
              <FontAwesomeIcon icon={faLayerGroup} />
            </Button>
            <span className="block h-6 w-[1px] bg-silver-50"></span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          alignOffset={0}
          side="top"
          sideOffset={16}
          className="py-0"
        >
          <button
            className="absolute right-2 top-2 text-sm"
            onClick={() => setOpenActions(!openActions)}
          >
            <FontAwesomeIcon icon={faXmarkLarge} />
          </button>
          <div className="px-4 py-3 text-base font-bold">
            {t('Visning av avstånd (cm)')}
          </div>
          <DropdownMenuGroup>
            <DropdownMenuItem
              className={cn(
                'flex w-full items-center border-b border-grayscale-25 px-0 py-0 font-normal',
                !showMountDistanceLabels && 'text-grayscale-25'
              )}
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleToggleMountDistanceLabels()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <span
                className={cn(
                  'my-[1px] ml-[1px] mr-3.5 block h-9 w-0.5 bg-green-100',
                  !showMountDistanceLabels && 'bg-grayscale-25'
                )}
              ></span>
              {showMountDistanceLabels ? (
                <FontAwesomeIcon
                  icon={faEye}
                  className="mr-3 text-green-100"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="mr-3 text-grayscale-25"
                />
              )}
              {t('Infästning')}
            </DropdownMenuItem>
            {hasSupportPlates() ? (
              <DropdownMenuItem
                className={cn(
                  'flex w-full items-center border-b border-grayscale-25 px-0 py-0 font-normal',
                  !showMountSupportDistanceLabels && 'text-grayscale-25'
                )}
                onSelect={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  handleToggleMountSupportDistanceLabels()
                }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <span
                  className={cn(
                    'my-[1px] ml-[1px] mr-10 block h-9 w-0.5 bg-green-100',
                    !showMountSupportDistanceLabels && 'bg-grayscale-25'
                  )}
                ></span>
                {showMountSupportDistanceLabels ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    className="mr-3 text-green-100"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="mr-3 text-grayscale-25"
                  />
                )}
                {t('Inklusive stödplatta')}
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem
              className={cn(
                'flex w-full items-center border-b border-grayscale-25 px-0 py-0 font-normal',
                !showRailDistanceLabels && 'text-grayscale-25'
              )}
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                toggleRailDistanceLabels()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <span
                className={cn(
                  'my-[1px] ml-[1px] mr-3.5 block h-9 w-0.5 bg-gold-100',
                  !showRailDistanceLabels && 'bg-grayscale-25'
                )}
              ></span>
              {showRailDistanceLabels ? (
                <FontAwesomeIcon
                  icon={faEye}
                  className="mr-3 text-gold-100"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="mr-3 text-grayscale-25"
                />
              )}
              {t('Skenor')}
            </DropdownMenuItem>
            {system === 'low' ? (
              <DropdownMenuItem
                className={cn(
                  'flex w-full items-center border-b border-grayscale-25 px-0 py-0 font-normal',
                  !showCrossRailDistanceLabels && 'text-grayscale-25'
                )}
                onSelect={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  toggleCrossRailDistanceLabels()
                }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <span
                  className={cn(
                    'my-[1px] ml-[1px] mr-3.5 block h-9 w-0.5 bg-orange-600',
                    !showCrossRailDistanceLabels && 'bg-grayscale-25'
                  )}
                ></span>
                {showCrossRailDistanceLabels ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    className="mr-3 text-orange-600"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="mr-3 text-grayscale-25"
                  />
                )}
                {t('Stabilisatorskenor')}
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem
              className={cn(
                'flex w-full items-center px-0 py-0 font-normal',
                !showRailLengthDistanceLabels && 'text-grayscale-25'
              )}
              onSelect={(event) => {
                event.stopPropagation()
                event.preventDefault()
                toggleRailLengthDistanceLabels()
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <span
                className={cn(
                  'my-[1px] ml-[1px] mr-3.5 block h-9 w-0.5 bg-blue-700',
                  !showRailLengthDistanceLabels && 'bg-grayscale-25'
                )}
              ></span>
              {showRailLengthDistanceLabels ? (
                <FontAwesomeIcon
                  icon={faEye}
                  className="mr-3 text-blue-700"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="mr-3 text-grayscale-25"
                />
              )}
              {t('Längd skena')}
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default DistanceLabelActions
