import { Group, Label, Tag, Text } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'

type Props = {
  type: 'panelArea' | 'panelAreaSection'
  position: Position
  size: Size
  labelText: string
  scale: number
}

const PanelAreaLabel = ({ position, size, labelText, scale }: Props) => {
  const textWidth = 38 / scale
  const fontSize = 12 / scale
  const textHeight = 22 / scale
  const scaledPosition = scaleMmToPixels<Position>(position)
  const scaledSize = scaleMmToPixels<Size>(size)
  return (
    <Group
      x={scaledPosition.x}
      y={scaledPosition.y}
    >
      <Label
        x={scaledSize.width / 2}
        y={scaledSize.height / 2}
        offsetY={textHeight / 2}
        offsetX={textWidth / 2}
      >
        <Tag fill="#fff" />
        <Text
          fontSize={fontSize}
          text={labelText}
          width={textWidth}
          height={textHeight}
          align="center"
          verticalAlign="middle"
          fill="#000"
          fontFamily="Roboto"
          fontStyle="bold"
        />
      </Label>
    </Group>
  )
}

export default PanelAreaLabel
