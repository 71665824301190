import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { Button } from '~/components/ui/button'
import { cn } from '~/lib/utils'
import { solarPanelClient } from '~/http/api'
import * as Sentry from '@sentry/react'

const DeletePanelDialog = () => {
  const { currentPanel, user, setUser, triggerDialog, setTriggerDialog } =
    useBoundStore((state: StoreState) => ({
      currentPanel: state.currentPanel,
      user: state.user, // User can't be null, must be logged in here.
      setUser: state.setUser,
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    }))

  const [open, setOpen] = useState(false)
  const [_, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const closeAndReset = () => {
    setOpen(false)
    setTriggerDialog('')
  }

  const closeAndError = () => {
    setOpen(false)
    setTriggerDialog('SomethingWentWrongDialog')
  }

  const handleResponse = (res: AxiosResponse) => {
    setUser({ ...user, panels: res.data.panels } as User)
    setSubmitted(true)
    closeAndReset()
  }

  const handleFailedResponse = (error: any) => {
    Sentry.captureException(error)
    closeAndError()
    setTriggerDialog('SomethingWentWrongDialog')
  }

  const handleDelete = (panel: SavedPanel | null) => {
    if (!panel) {
      closeAndError()
      return
    }

    setIsLoading(true)

    setTimeout(() => {
      solarPanelClient
        .delete('/panels/delete', {
          data: {
            panels: [currentPanel]
          }
        })
        .then((res) => handleResponse(res))
        .catch((error) => {
          handleFailedResponse(error)
        })

      setIsLoading(false)
      closeAndReset()
    }, 1000)
  }

  useEffect(() => {
    if (triggerDialog === 'DeletePanelDialog') {
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Är du säker?')}</DialogTitle>
        </DialogHeader>
        <div>
          <p className={cn('mb-10')}>
            {t(
              'Är du säker att du vill ta bort panelen? Denna åtgärd kan inte ångras.'
            )}
          </p>
          <div className="mt-6 flex justify-end space-x-4">
            <Button
              variant="ghost"
              className={cn(
                'col-span-2 border border-grayscale-75 bg-transparent text-grayscale-75 hover:bg-grayscale-75 hover:text-white disabled:text-white'
              )}
              onClick={() => closeAndReset()}
            >
              {t('Avbryt')}
            </Button>
            <Button
              onClick={() => handleDelete(currentPanel)}
              isloading={isLoading}
            >
              {t('Ta bort')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeletePanelDialog
