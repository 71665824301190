import { useTranslation } from 'react-i18next'
import { convertGramToKilo } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine } from '@fortawesome/sharp-regular-svg-icons'
import Button from '../buttons/Button'
import { useState } from 'react'
import { StoreState, useBoundStore } from '../../store'
import CartPdfLinks from './CartPdfLinks'

interface Props {
  cart: Cart
}

const CartSummary = ({ cart }: Props) => {
  const { t } = useTranslation()
  const { user } = useBoundStore((state: StoreState) => ({
    user: state.user
  }))

  const [showPdfLinks, setShowPdfLinks] = useState(false)
  const [showProductListLinks, setShowProductListLinks] = useState(false)

  return (
    <div className="text-base">
      <div className="mb-6 flex items-end justify-between bg-silver-25 p-8">
        <div className="flex gap-8">
          <div className="flex flex-col gap-2 font-bold">
            <div>{t('Totalt antal paneler')}</div>
            <div>{t('Total vikt infästning')}</div>
            <div>{t('Total vikt paneler')}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div>{`${cart.summary.numberOfPanels} ${t('st')}`}</div>
            <div>{`${convertGramToKilo(cart.summary.totalMountingWeight)} ${t(
              'kg'
            )}`}</div>
            <div>{`${convertGramToKilo(cart.summary.totalPanelWeight)} ${t(
              'kg'
            )}`}</div>
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            className="relative flex gap-2"
            variant="ghost"
            onClick={() => {
              if (user?.canSeePrices) {
                setShowPdfLinks(!showPdfLinks)
              } else {
                window.open(cart.summary.pdfUrl, '_blank')
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowDownToLine} />
            {t('Alla rapporter')}
            {showPdfLinks && user ? (
              <CartPdfLinks pdfUrl={cart.summary.pdfUrl ?? ''} />
            ) : null}
          </Button>
          <Button
            className="relative flex gap-2"
            onClick={() => {
              if (user?.canSeePrices) {
                setShowProductListLinks(!showProductListLinks)
              } else {
                window.open(cart.summary.productListPdfUrl, '_blank')
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowDownToLine} />
            {t('Fullständig artikelspecifikation')}
            {showProductListLinks && user ? (
              <CartPdfLinks pdfUrl={cart.summary.productListPdfUrl ?? ''} />
            ) : null}
          </Button>
        </div>
      </div>
      <div className="pb-36 italic">
        {t(
          'För köp av aktuella konfigurationer rekommenderar vi att du handlar genom våra etablerade kanaler.'
        )}
      </div>
    </div>
  )
}

export default CartSummary
