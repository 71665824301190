import { t } from 'i18next'
import { StoreState, useBoundStore } from '../store'
import ProductList from './ProductList'

const ProductSpecification = () => {
  const { products } = useBoundStore((state: StoreState) => ({
    products: state.products
  }))

  return (
    <div>
      <p className="mb-10">
        {t(
          'Följande artikelspecifikation avser hela projektet. För att se artikelspecifikationer för varje enskild takyta, ladda ner separata rapporter under fliken “Takytor”.'
        )}
      </p>
      <ProductList products={products} />
    </div>
  )
}

export default ProductSpecification
