import { Rect } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'

type Props = {
  rail: Rail
  isApproved: boolean
}

const Rail = ({ rail, isApproved }: Props) => {
  const { angle, startPosition, endPosition } = rail
  const railWidth = 60
  const offset =
    rail.angle == 0 ? { x: railWidth / 2, y: 0 } : { x: 0, y: railWidth / 2 }
  const size =
    angle === 0
      ? { width: railWidth, height: endPosition.y - startPosition.y - 20 }
      : { width: endPosition.x - startPosition.x - 20, height: railWidth }
  const scaledSize = scaleMmToPixels<Size>(size)
  const scaledPosition = scaleMmToPixels<Position>(startPosition)
  const scaledOffset = scaleMmToPixels<Position>(offset)

  return (
    <Rect
      width={scaledSize.width}
      height={scaledSize.height}
      x={scaledPosition.x + (angle === 0 ? 0 : 1)}
      y={scaledPosition.y + (angle === 0 ? 1 : 0)}
      offsetX={scaledOffset.x}
      offsetY={scaledOffset.y}
      strokeWidth={1}
      stroke={isApproved ? '#404040' : '#DA0A16'}
      fill={'#FFF'}
    />
  )
}

export default Rail
