import { t } from 'i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const SaveChangesAsNewSuccessCloseDialog = ({
  dialogActions,
  handleClose
}: Props) => {
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="mr-4 text-[32px] text-green-100"
        />
        <h2 className="text-xl">{t('Konfiguration sparad')}</h2>
      </div>
      <p className="mb-10">
        {t(
          'Ändringarna har sparats i en ny konfiguration. Du kan nu hitta både den nuvarande och tidigare konfigurationen i din historik.'
        )}
      </p>
      <div className="flex gap-4">
        <Button onClick={() => dialogActions?.closeConfigurator()}>
          {t('Avsluta konfiguration')}
        </Button>
      </div>
    </DialogBox>
  )
}

export default SaveChangesAsNewSuccessCloseDialog
