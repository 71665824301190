import * as React from 'react'
import FieldErrorMessage from '../FieldErrorMessage'
import { FieldValues, useFormContext, RegisterOptions } from 'react-hook-form'
import { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import ErrorIcon from '../ErrorIcon'
import { getColEnd, getColSpan, getColStart } from '~/utils/tailwind'
import { cn } from '~/lib/utils'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: keyof FieldValues
  label?: string
  info?: string
  icon?: React.ReactElement
  rules?: RegisterOptions<FieldValues>
  placeholder?: string
  unit?: string | React.ReactElement
  columnPosition?: {
    start?: number
    span?: number
    end?: number
  }
  hideError?: boolean
  width?: string
  className?: string
}

const Input: React.FC<IInputProps> = ({
  name,
  label,
  info,
  icon,
  type,
  step,
  rules,
  placeholder,
  disabled,
  unit,
  columnPosition,
  hideError,
  width,
  onKeyDown,
  onChange,
  className
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  if (typeof rules === 'object' && rules !== null && type === 'number') {
    rules['setValueAs'] = (v) => (v === '' ? undefined : parseFloat(v))
  } else if (type === 'number') {
    rules = { setValueAs: (v) => (v === '' ? undefined : parseFloat(v)) }
  }

  const { start, end, span } =
    columnPosition === undefined
      ? { start: false, span: false, end: false }
      : columnPosition

  const hasError = !!Object.getOwnPropertyDescriptor(errors, name)

  return (
    <div
      className={cn(
        'relative mb-2 flex h-fit flex-wrap items-start text-base',
        type === 'hidden' && 'hidden',
        columnPosition === undefined && 'col-span-full',
        typeof start === 'number' && getColStart(start),
        typeof span === 'number' && getColSpan(span),
        typeof end === 'number' && getColEnd(end),
        width !== undefined && width,
        hasError && 'mb-0',
        className
      )}
    >
      {label && (
        <label
          className="mb-2 flex w-full text-sm font-bold uppercase items-center"
          htmlFor={name}
        >
          {label}
          <span>
            {icon}
            {info}
          </span>
        </label>
      )}
      <div
        className={clsx(
          'relative flex w-full border border-grayscale-25 bg-white focus-within:border-grayscale-75',
          hasError && 'border-red-100'
        )}
      >
        <input
          className="flex w-full min-w-0 grow border-none py-[15px] pl-4 leading-4 focus:outline-none disabled:text-grayscale-50" //focus-visible:outline-0
          {...register(name, { ...rules, onChange: onChange })}
          id={name}
          type={type}
          step={step}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={onKeyDown}
          min={(rules?.min as string) || ''}
          max={(rules?.max as string) || ''}
        />
        {unit && (
          <div
            className={cn(
              'h-100 flex items-center bg-white pr-4 text-grayscale-50',
              disabled && 'bg-[rgba(239,239,239,0.3)]'
            )}
          >
            {unit}
          </div>
        )}
        {hasError && !(hideError === true) ? <ErrorIcon /> : null}
      </div>

      {!(hideError === true) ? (
        <FieldErrorMessage
          name={name}
          errors={errors}
        />
      ) : null}
    </div>
  )
}

export default Input
