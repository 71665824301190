import React from 'react'
import Konva from 'konva'
import { Line, Image, Stage, Layer, Arrow, Text, Rect } from 'react-konva'
import { scaleMmToPixels } from '~/utils/configurator'
import { useTranslation } from 'react-i18next'

type Props = {
  system: string
  imageSection: SectionImageData
  index: number
  setRef: (element: Konva.Stage, index: number) => void
}

const getRailLengthText = (length: number) => {
  const { t } = useTranslation()
  if (length < 170) {
    return getDimensionText(length)
  }
  return `${t('Längd skena:')} ${(length / 1000).toFixed(2)} ${t('m')}`
}

const getDimensionText = (length: number) => {
  const { t } = useTranslation()
  return `${(length / 100).toFixed(2)} ${t('m')}`
}

const SectionImageStage = React.memo(
  ({ system, imageSection, index, setRef }: Props) => {
    const {
      uid,
      images,
      imageSize,
      size,
      sectionSize,
      split,
      railAngle,
      railLength
    } = imageSection
    const scaledSize = scaleMmToPixels<Size>(size)
    const scaledSectionSize = scaleMmToPixels<Size>(sectionSize)
    const scaledImageSize = scaleMmToPixels<Size>(imageSize)
    const canvasTopMargin = 40
    const canvasRightMargin = canvasTopMargin
    const canvasLeftMargin = 10
    const canvasBottomMargin = 15
    const imageDividerWidth = 25
    const fontSize = 16
    const horizontalTextWidth =
      railAngle === 90 && scaledSize.width >= 170 ? 170 : 65
    const verticalTextWidth =
      railAngle === 0 && scaledSize.height >= 170 ? 170 : 65
    const textHeight = 20
    const textWidthString =
      railAngle === 90
        ? getRailLengthText(railLength)
        : getDimensionText(scaledSectionSize.width)
    const textHeightString =
      railAngle === 0
        ? getRailLengthText(railLength)
        : getDimensionText(scaledSectionSize.height)
    const labelPaddingX = railAngle === 0 ? 0 : 25
    const labelPaddingY = railAngle === 0 ? 25 : 0
    const imageWidth = split.horizontal
      ? scaledImageSize.width * 2
      : scaledImageSize.width
    const imageHeight = split.vertical
      ? scaledImageSize.height * 2
      : scaledImageSize.height
    const canvasWidth = imageWidth + canvasLeftMargin + canvasRightMargin
    const canvasHeight = imageHeight + canvasTopMargin + canvasBottomMargin

    const horizontalArrowOffsetLeft =
      system === 'low' && railAngle === 90
        ? 16 - imageSection.edgeDistance / 10
        : 0

    const horizontalArrowOffsetRight =
      (system === 'low' && railAngle === 90
        ? split.horizontal === true
          ? -12
          : -6
        : 0) - labelPaddingX

    const verticalArrowOffsetTop =
      (system === 'low' && railAngle === 0
        ? 8 + imageSection.edgeDistance / 10
        : 0) + labelPaddingY

    const verticalArrowOffsetBottom =
      system === 'low' && railAngle === 0
        ? split.vertical === true
          ? -9
          : -10
        : 0

    return (
      <Stage
        key={uid}
        id={uid}
        width={canvasWidth}
        height={canvasHeight}
        ref={(element: Konva.Stage) => {
          setRef(element, index)
        }}
        className="absolute left-[99999px] top-[99999px] bg-white"
      >
        <Layer>
          <Rect
            fill="#fff"
            x={0}
            y={0}
            width={canvasWidth}
            height={canvasHeight}
          />
          {/* Horizontal dimension arrows */}
          <Line
            stroke="#000"
            strokeWidth={2}
            points={[
              canvasLeftMargin + horizontalArrowOffsetLeft,
              canvasTopMargin / 2 + 5,
              canvasLeftMargin + horizontalArrowOffsetLeft,
              canvasTopMargin / 2 - 5
            ]}
          />
          <Line
            stroke="#000"
            strokeWidth={2}
            points={[
              imageWidth + canvasLeftMargin + horizontalArrowOffsetRight,
              canvasTopMargin / 2 + 5,
              imageWidth + canvasLeftMargin + horizontalArrowOffsetRight,
              canvasTopMargin / 2 - 5
            ]}
          />
          <Arrow
            points={[
              canvasLeftMargin + horizontalArrowOffsetLeft,
              canvasTopMargin / 2,
              imageWidth / 2 - horizontalTextWidth / 2,
              canvasTopMargin / 2
            ]}
            pointerAtEnding={false}
            fill="black"
            stroke="black"
            strokeWidth={2}
          />
          <Arrow
            points={[
              imageWidth / 2 + horizontalTextWidth / 2 + canvasLeftMargin,
              canvasTopMargin / 2,
              imageWidth + canvasLeftMargin + horizontalArrowOffsetRight,
              canvasTopMargin / 2
            ]}
            pointerAtEnding={false}
            fill="black"
            stroke="black"
            strokeWidth={2}
          />
          <Text
            fontSize={fontSize}
            text={textWidthString}
            width={horizontalTextWidth}
            height={textHeight}
            x={imageWidth / 2 + canvasLeftMargin / 2}
            y={canvasTopMargin / 2}
            offsetX={horizontalTextWidth / 2}
            offsetY={textHeight / 2}
            align="center"
            verticalAlign="middle"
            fill="#000"
            fontFamily="Roboto"
            fontStyle="bold"
          />
          {/* Vertical dimension arrows */}
          <Line
            stroke="#000"
            strokeWidth={2}
            points={[
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin + 5,
              imageHeight + canvasTopMargin + verticalArrowOffsetBottom,
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin - 5,
              imageHeight + canvasTopMargin + verticalArrowOffsetBottom
            ]}
          />
          <Line
            stroke="#000"
            strokeWidth={2}
            points={[
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin + 5,
              canvasTopMargin + verticalArrowOffsetTop,
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin - 5,
              canvasTopMargin + verticalArrowOffsetTop
            ]}
          />
          <Arrow
            points={[
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin,
              canvasTopMargin + verticalArrowOffsetTop,
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin,
              imageHeight / 2 +
                canvasTopMargin -
                verticalTextWidth / 2 +
                verticalArrowOffsetTop / 2
            ]}
            pointerAtEnding={false}
            fill="black"
            stroke="black"
            strokeWidth={2}
          />
          <Arrow
            points={[
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin,
              imageHeight / 2 +
                canvasTopMargin +
                verticalTextWidth / 2 +
                verticalArrowOffsetBottom +
                verticalArrowOffsetTop / 2,
              imageWidth + canvasRightMargin / 2 + canvasLeftMargin,
              imageHeight + canvasTopMargin + verticalArrowOffsetBottom
            ]}
            pointerAtEnding={false}
            fill="black"
            stroke="black"
            strokeWidth={2}
          />
          <Text
            fontSize={fontSize}
            text={textHeightString}
            width={verticalTextWidth}
            height={textHeight}
            x={imageWidth + canvasRightMargin / 2 + canvasLeftMargin}
            y={imageHeight / 2 + canvasTopMargin + verticalArrowOffsetTop / 2}
            offsetX={verticalTextWidth / 2}
            offsetY={textHeight / 2}
            rotation={90}
            align="center"
            verticalAlign="middle"
            fill="#000"
            fontFamily="Roboto"
            fontStyle="bold"
          />
          {images.map((element, index) => {
            const image = new window.Image()
            image.src = element.image

            const scaledSize = scaleMmToPixels<Size>(element.size)
            const scaledPosition = scaleMmToPixels<Position>(element.position)

            return (
              <Image
                key={index}
                image={image}
                x={scaledPosition.x + canvasLeftMargin}
                y={scaledPosition.y + canvasTopMargin}
                width={scaledSize.width}
                height={scaledSize.height}
              />
            )
          })}

          {split.vertical == true && (
            <>
              {/* Horizontal divider */}
              <Line
                points={[
                  canvasLeftMargin - canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2,
                  imageWidth + canvasLeftMargin,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2,
                  imageWidth + canvasLeftMargin,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2,
                  canvasLeftMargin - canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2,
                  canvasLeftMargin - canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2
                ]}
                x={0}
                y={0}
                fill="hsl(0, 0%, 100%)"
                name="middle"
                closed
              />

              {/* top horizontal dashed line */}
              <Line
                stroke="black"
                strokeWidth={2}
                dash={[8, 8]}
                points={[
                  canvasLeftMargin - canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2 + 1,
                  imageWidth + canvasLeftMargin + canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2 + 1
                ]}
              />

              {/* bottom horizontal dashed line */}
              <Line
                stroke="black"
                strokeWidth={2}
                dash={[8, 8]}
                points={[
                  canvasLeftMargin - canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2 - 1,
                  imageWidth + canvasLeftMargin + canvasLeftMargin / 4,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2 - 1
                ]}
              />
            </>
          )}

          {split.horizontal == true && (
            <>
              {/* Vertical divider */}
              <Line
                points={[
                  imageWidth / 2 + canvasLeftMargin,
                  canvasTopMargin - canvasTopMargin / 4,
                  imageWidth / 2 + imageDividerWidth + canvasLeftMargin,
                  canvasTopMargin - canvasTopMargin / 4,
                  imageWidth / 2 + canvasLeftMargin,
                  imageHeight + canvasTopMargin + canvasTopMargin / 4,
                  imageWidth / 2 - imageDividerWidth + canvasLeftMargin,
                  imageHeight + canvasTopMargin + canvasTopMargin / 4,
                  imageWidth / 2 + canvasLeftMargin,
                  canvasTopMargin - canvasTopMargin / 4
                ]}
                x={0}
                y={0}
                fill="hsl(0, 0%, 100%)"
                name="middle"
                closed
              />

              {/* Left vertical dashed line */}
              <Line
                stroke="black"
                strokeWidth={2}
                dash={[8, 8]}
                points={[
                  imageWidth / 2 + canvasLeftMargin + 1,
                  canvasTopMargin - canvasTopMargin / 4,
                  imageWidth / 2 - imageDividerWidth + canvasLeftMargin + 1,
                  imageHeight + canvasTopMargin + canvasTopMargin / 4 + 5
                ]}
              />

              {/* Right vertical dashed line */}
              <Line
                stroke="black"
                strokeWidth={2}
                dash={[8, 8]}
                points={[
                  imageWidth / 2 + imageDividerWidth + canvasLeftMargin - 1,
                  canvasTopMargin - canvasTopMargin / 4,
                  imageWidth / 2 + canvasLeftMargin - 1,
                  imageHeight + canvasTopMargin + canvasTopMargin / 4 + 5
                ]}
              />
            </>
          )}

          {split.horizontal == true && split.vertical == true && (
            <>
              {/* Center dashed line break fix */}
              <Line
                points={[
                  canvasLeftMargin + imageWidth / 2 - imageDividerWidth / 2 - 5,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2 + 2,
                  canvasLeftMargin + imageWidth / 2 + imageDividerWidth / 2 + 5,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2 + 2,
                  canvasLeftMargin + imageWidth / 2 + imageDividerWidth / 2 + 5,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2 - 2,
                  canvasLeftMargin + imageWidth / 2 - imageDividerWidth / 2 - 5,
                  canvasTopMargin + imageHeight / 2 + imageDividerWidth / 2 - 2,
                  canvasLeftMargin + imageWidth / 2 - imageDividerWidth / 2 - 5,
                  canvasTopMargin + imageHeight / 2 - imageDividerWidth / 2 + 2
                ]}
                x={0}
                y={0}
                fill="hsl(0, 0%, 100%)"
                name="middle"
                closed
              />
            </>
          )}
        </Layer>
      </Stage>
    )
  }
)

export default SectionImageStage
