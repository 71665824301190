import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog'
import { Button } from '../ui/button'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { solarPanelClient } from '~/http/api'
import { refreshProjects } from '~/lib/projectsUtils'

const DeleteProjectDialog = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { project, triggerDialog, sortingProjects, setTriggerDialog } =
    useBoundStore((state: StoreState) => ({
      ...state,
      project: state.computedProject.currentProject
    }))

  useEffect(() => {
    if (triggerDialog === 'DeleteProjectDialog') {
      setOpen(true)
      setTriggerDialog('')
    }
  }, [triggerDialog])

  if (project === undefined) {
    return null
  }

  const handleResponse = () => {
    setOpen(false)
    setIsLoading(false)
    refreshProjects('', sortingProjects)
  }

  const handleFailedResponse = () => {
    setIsLoading(false)
    setTriggerDialog('SomethingWentWrongDialog')
  }

  const handleDelete = () => {
    setIsLoading(true)
    solarPanelClient
      .post('/delete', { references: [project.reference] })
      .then(handleResponse)
      .catch(handleFailedResponse)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => setOpen(!open)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('Är du säker?')}</DialogTitle>
          <DialogDescription>
            {t(
              'Är du säker på att du vill ta bort projekt med references: {{reference}} ? Denna åtgärd kan inte ångras.',
              { reference: project.reference }
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={(event) => {
              event.stopPropagation()
              setOpen(!open)
            }}
          >
            {t('Avbryt')}
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              handleDelete()
            }}
            isloading={isLoading}
          >
            {t('Ta bort')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteProjectDialog
