import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { shallow } from 'zustand/shallow'
import { Button } from '~/components/ui/button'
import { StoreState, useBoundStore } from '~/store'
import NewSelect from './fields/NewSelect'
import Checkbox from './fields/Checkbox'

const FormSchema = z.object({
  pdfType: z.string(),
  'project-details': z.boolean(),
  'roof-details': z.boolean(),
  'roof-product-list': z.boolean(),
  'roof-position': z.boolean(),
  'section-overview': z.boolean(),
  'section-details': z.boolean(),
  'section-results': z.boolean(),
  'include-prices': z.boolean(),
  documents: z.boolean()
})

type Props = {
  onFormSubmit: () => void
  roofUid: string
}

export function FormGenerateRoofPDF({ onFormSubmit, roofUid }: Props) {
  const { user, pdfUrl, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      user: state.user,
      pdfUrl: state.pdfUrl,
      setTriggerDialog: state.setTriggerDialog
    }),
    shallow
  )

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pdfType: 'full',
      'project-details': true,
      'roof-details': true,
      'roof-product-list': true,
      'roof-position': true,
      'section-overview': true,
      'section-details': true,
      'section-results': true,
      'include-prices': true,
      documents: true
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (pdfUrl !== undefined) {
      const url = getPdfUrl(data, pdfUrl)
      openPdf(url)
    } else {
      setTriggerDialog('SomethingWentWrongDialog')
    }
    onFormSubmit()
  }

  function getPdfUrl(data: z.infer<typeof FormSchema>, pdfUrl: string) {
    const pages: (keyof z.infer<typeof FormSchema>)[] = [
      'project-details',
      'roof-details',
      'roof-product-list',
      'roof-position',
      'section-overview',
      'section-details',
      'section-results',
      'documents'
    ]

    const selectedPages = pages.filter((page) => data[page]).join(',')
    const allPagesSelected = selectedPages.split(',').length === pages.length

    let downloadUrl = new URL(pdfUrl)
    downloadUrl.searchParams.set('roof', roofUid)
    if (!allPagesSelected) {
      downloadUrl.searchParams.set('pages', selectedPages)
    }
    if (!data['include-prices']) {
      downloadUrl.searchParams.set('hide-prices', 'true')
    }

    return downloadUrl.toString()
  }

  function openPdf(url: string) {
    window.open(url, '_blank')?.focus()
  }

  const { watch } = form

  const pdfType = watch('pdfType')

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-1"
        >
          <NewSelect
            name="pdfType"
            label={t('Rapporttyp')}
            className="mb-8"
            options={[
              {
                value: 'full',
                label: t('Fullständig rapport')
              },
              {
                value: 'customized',
                label: t('Anpassad')
              }
            ]}
            rules={{ required: { value: true, message: t('Obligatorisk') } }}
          />
          {pdfType === 'customized' ? (
            <>
              <Checkbox
                name="project-details"
                label={t('Projektinformation')}
              />
              <Checkbox
                name="roof-details"
                label={t('Takinformation')}
              />
              <Checkbox
                name="roof-product-list"
                label={t('Artikelspecifikation')}
              />
              <Checkbox
                name="roof-position"
                label={t('Position på tak')}
              />
              <Checkbox
                name="section-overview"
                label={t('Sektionsöversikt')}
              />
              <Checkbox
                name="section-details"
                label={t('Sektionsinformation')}
              />
              {user ? (
                <Checkbox
                  name="section-results"
                  label={t('Resultat')}
                />
              ) : null}
              <Checkbox
                name="documents"
                label={t('Monteringsanvisningar')}
              />
            </>
          ) : null}
          {user ? (
            <>
              <hr className="!my-8 bg-grayscale-25" />
              <Checkbox
                name="include-prices"
                label={t('Inkludera pris i rapport')}
              />
            </>
          ) : null}
          <div className="flex justify-end">
            <Button type="submit">{t('Ladda ner')}</Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
