import { ButtonHTMLAttributes, ReactNode } from "react"
import { cn } from "~/lib/utils"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    className?: string
}

/** A really simple link button with no extra magic */
const LinkButton: React.FC<ButtonProps> = ({
    children,
    disabled,
    onClick,
    className
}) => {
    return (
        <button
            onClick={onClick}
            className={cn(
                className,
                'ml-auto font-light col-start-1 col-span-full underline text-sm text-black',
                disabled && ('cursor-not-allowed text-grayscale-50')
            )}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default LinkButton