import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog'
import { t } from 'i18next'
import { StoreState, useBoundStore } from '~/store'
import { useEffect, useState } from 'react'
import CreateEditPanelForm from '../form/CreateEditPanelForm'
import { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'

const CreateEditPanelDialog = () => {
  const { user, currentPanel, setUser, triggerDialog, setTriggerDialog } = useBoundStore(
    (state: StoreState) => ({
      user: state.user, // User can't be null, must be logged in here.
      currentPanel: state.currentPanel,
      setUser: state.setUser,
      triggerDialog: state.triggerDialog,
      setTriggerDialog: state.setTriggerDialog
    })
  )

  const [open, setOpen] = useState(false)

  const closeAndReset = () => {
    setOpen(false)
    setTriggerDialog('')
  }

  const closeAndError = () => {
    setOpen(false)
    setTriggerDialog('SomethingWentWrongDialog')
  }

  const handleSaveResponse = (res: AxiosResponse) => {
    setUser({ ...user, panels: res.data.panels } as User)
    closeAndReset()
  }

  const handleFailedResponse = (error: AxiosResponse) => {
    Sentry.captureException(error)
    closeAndError()
  }

  useEffect(() => {
    if (triggerDialog === 'CreateEditPanelDialog') {
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [triggerDialog])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        setOpen(!open)
        setTriggerDialog('')
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{currentPanel ? t('Redigera panel'): t('Ny panel')}</DialogTitle>
        </DialogHeader>
        <CreateEditPanelForm
          handleSaveResponse={handleSaveResponse}
          handleFailedResponse={handleFailedResponse}
          onClose={closeAndReset}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CreateEditPanelDialog
