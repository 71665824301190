import { Button } from '../ui/button'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  onClick: () => void
}

const PdfButton = (props: Props) => {
  return (
    <Button
      variant="outline"
      className="min-w-min"
      {...props}
    >
      <FontAwesomeIcon
        className="mr-1"
        icon={faArrowDownToLine}
      />
      {t('PDF')}
    </Button>
  )
}

export default PdfButton
