import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { cn } from '~/lib/utils.ts'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button: React.FC<IButtonProps> = ({
  type,
  children,
  disabled,
  onClick,
  className
}) => {
  return (
    <button
      className={cn(
        'h-12 min-w-[100px] bg-grayscale-75 px-6 py-4 text-base font-medium leading-4 text-white',
        className
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
