import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'

import { t } from 'i18next'
import {
  faClone,
  faEllipsisV,
  faPenLine,
  faTrashCan
} from '@fortawesome/sharp-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '~/components/ui/button'
import { cn } from '~/lib/utils'
import { useState } from 'react'

type DropDownProps = {
  id: string | number
  onClick?: (
    id: string | number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void
  handleView?: (id: string | number) => void
  handleEdit?: (id: string | number) => void
  handleDelete?: (id: string | number) => void
  handleDuplicate?: (id: string | number) => void
}

const Dropdown = ({
  id,
  onClick,
  handleView,
  handleDelete,
  handleEdit,
  handleDuplicate
}: DropDownProps) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)

  return (
    <DropdownMenu
      open={dropDownOpen}
      onOpenChange={setDropDownOpen}
      modal={false}
    >
      <DropdownMenuTrigger
        asChild
        onClick={(e) => (onClick ? onClick(id, e) : null)}
      >
        <Button
          className={cn(
            'rounded-full text-lg hover:bg-silver-25 group-hover:hover:bg-silver-25',
            dropDownOpen && 'bg-silver-25'
          )}
          variant="ghost"
          size="icon"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        alignOffset={16}
        side="top"
        sideOffset={0}
      >
        {handleView && (
          <DropdownMenuItem onClick={() => handleView(id)}>
            <span className="ml-2">{t('Visa')}</span>
          </DropdownMenuItem>
        )}

        {handleEdit && (
          <DropdownMenuItem onClick={() => handleEdit(id)}>
            <FontAwesomeIcon icon={faPenLine} />
            <span className="ml-2">{t('Redigera')}</span>
          </DropdownMenuItem>
        )}

        {handleDuplicate && (
          <DropdownMenuItem onClick={() => handleDuplicate(id)}>
            <FontAwesomeIcon icon={faClone} />
            <span className="ml-2">{t('Duplicera')}</span>
          </DropdownMenuItem>
        )}

        {handleDelete && (
          <DropdownMenuItem
            onClick={() => handleDelete(id)}
            className="text-red-500"
          >
            <FontAwesomeIcon icon={faTrashCan} />
            <span className="ml-2">{t('Ta bort')}</span>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default Dropdown
