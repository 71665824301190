import React, { useEffect, useState, createRef } from 'react'
import { scalePixelsToMm, scaleMmToPixels } from '~/utils/configurator'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import Panel from './Panel'
import PanelAreaAmountLabel from './PanelAreaAmountLabel'
import MeasurementIndicator from '../MeasurementIndicator'
import RemovePanelAreaPopup from '../RemovePanelAreaPopup'
import Konva from 'konva'
import { Rect, Group, Transformer } from 'react-konva'
import { KonvaEventObject } from 'konva/lib/Node'
import PanelAreaActions from './PanelAreaActions'
import PanelAreaGuideLines from './PanelAreaGuideLines'
import {
  drawPanels,
  getMaxPanels,
  trimPanels,
  getPanelAreaPosition,
  matchHoverPanelEastWest,
  getTransformSize,
  getTransformPosition,
  getTransformWidth,
  getTransformerHeight,
  getHorizontalMeasurementIndicatorPosition,
  getVerticalMeasurementIndicatorPosition,
  getPanelAreaActionsPosition,
  getPanelAreaAmountLabelPosition,
  getRemovePanelAreaPopupPosition
} from '~/lib/panelAreaUtils'
import { useTranslation } from 'react-i18next'

interface Props extends PanelArea {
  panelArea: PanelArea
  isSelected: boolean
  scale: number
  isDrawing: boolean
  isRedrawing: boolean
  isScrolling: boolean
  imageView?: boolean
}

const PanelArea = React.memo(
  ({
    panelArea,
    isSelected,
    scale,
    isDrawing,
    isRedrawing,
    isScrolling,
    imageView
  }: Props) => {
    if (imageView) {
      isSelected = false
    }

    const { t } = useTranslation()
    const { uid, system, size, position, panelInfo, panels, removedPanels } =
      panelArea
    const pixelPosition = scaleMmToPixels<Position>(position)
    const pixelSize = scaleMmToPixels<Size>(size)
    const panelAreaRef = createRef<Konva.Rect>()
    const transformerRef = createRef<Konva.Transformer>()
    const groupRef = createRef<Konva.Group>()
    const [currentPanelArea, setCurrentPanelArea] = useState<Konva.Rect | null>(
      null
    )
    const [currentTransformer, setCurrentTransformer] =
      useState<Konva.Transformer | null>(null)
    const [currentGroup, setCurrentGroup] = useState<Konva.Group | null>(null)
    const [showRemovePopup, setShowRemovePopup] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [hoveringPanelRowColumn, setHoveringPanelRowColumn] = useState<{
      row: number
      column: number
    } | null>(null)
    const [statePanelAreaPosition, setStatePanelAreaPosition] =
      useState(position)
    const {
      showPanelSettings,
      roofCoordinates,
      isConfigurationComplete,
      updatePanelArea,
      updatePanelAndNearByPanel,
      updatePanel,
      setIsRedrawing,
      deletePanelArea,
      setShowPanelSettings,
      setActiveArea,
      setRoofsUpdateImage
    } = useBoundStore(
      (state: StoreState) => ({
        showPanelSettings: state.computed.showPanelSettings,
        roofCoordinates: state.computed.roofCoordinates,
        isConfigurationComplete: state.isConfigurationComplete,
        updatePanelArea: state.updatePanelArea,
        updatePanelAndNearByPanel: state.updatePanelAndNearByPanel,
        updatePanel: state.updatePanel,
        setIsRedrawing: state.setIsRedrawing,
        deletePanelArea: state.deletePanelArea,
        setShowPanelSettings: state.setShowPanelSettings,
        setActiveArea: state.setActiveArea,
        setRoofsUpdateImage: state.setRoofsUpdateImage
      }),
      shallow
    )

    useEffect(() => {
      if (isSelected) {
        if (currentGroup) {
          currentGroup.moveToTop()
        }
      }
      if (showRemovePopup) {
        setShowRemovePopup(false)
      }
    }, [isSelected])

    let timer: any = null

    useEffect(() => {
      if (imageView !== true) {
        if (timer !== null) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          setRoofsUpdateImage()
        }, 200)
        return () => {
          clearTimeout(timer)
        }
      }
    }, [panelArea])

    useEffect(() => {
      if (groupRef.current !== null && currentGroup === null) {
        setCurrentGroup(groupRef.current)
      }
      if (panelAreaRef.current !== null && currentPanelArea === null) {
        setCurrentPanelArea(panelAreaRef.current)
      }
      if (transformerRef.current !== null && currentTransformer === null) {
        setCurrentTransformer(transformerRef.current)
      }
    }, [groupRef, panelAreaRef, transformerRef])

    useEffect(() => {
      if (currentPanelArea && currentTransformer) {
        currentTransformer.nodes([currentPanelArea])
        currentTransformer.getLayer()?.batchDraw()
      }
    }, [currentPanelArea, currentTransformer])

    useEffect(() => {
      if (currentPanelArea && roofCoordinates && currentTransformer) {
        const stage = currentPanelArea.findAncestor('#stage') as Konva.Stage
        if (!stage) return
        const shouldTrimStyling = isDrawing || isRedrawing
        const trimStyling = shouldTrimStyling ? true : false
        const updatedPanels = drawPanels(
          currentPanelArea,
          panelInfo,
          uid,
          removedPanels,
          roofCoordinates,
          trimStyling
        )
        const maxPanels = getMaxPanels(currentPanelArea, panelInfo)
        updatePanelArea({
          ...panelArea,
          panels: updatedPanels,
          columns: maxPanels.x,
          rows: maxPanels.y
        })
        currentTransformer.size(size)
        currentPanelArea.scale({ x: 1, y: 1 })
        currentTransformer.forceUpdate()
      }
    }, [size, isRedrawing, isDrawing])

    useEffect(() => {
      if (currentPanelArea !== null && isSelected) {
        const trimSize = trimPanels(currentPanelArea, panelInfo)
        const newPosition = { ...pixelPosition }
        if (size.width < 0 && size.height < 0) {
          newPosition.x -= trimSize.width
          newPosition.y -= trimSize.height
        } else if (size.width < 0) {
          newPosition.x -= trimSize.width
        } else if (size.height < 0) {
          newPosition.y -= trimSize.height
        }
        updatePanelArea({
          ...panelArea,
          size: scalePixelsToMm<Size>(trimSize),
          position: scalePixelsToMm<Position>(newPosition)
        })
      }
    }, [isDrawing])

    const handleDeletePanelArea = (uid: string) => {
      deletePanelArea(uid)
      setActiveArea(null)
    }

    const [triggerScrollRefresh, setTriggerScrollRefresh] = useState(0)

    useEffect(() => {
      const timer = setTimeout(() => {
        if (isSelected && currentTransformer !== null) {
          setTriggerScrollRefresh(triggerScrollRefresh + 1)
        }
      }, 10)
      return () => {
        clearTimeout(timer)
      }
    }, [isScrolling])

    useEffect(() => {
      const removedPanels = panels.filter((panel) => {
        if (panel.removed) {
          return { column: panel.column, row: panel.row }
        }
      })
      const isOutsideRoof = panels.some(
        (panel) => panel.fill === '#EC848A' && !panel.removed
      )
      updatePanelArea({
        ...panelArea,
        removedPanels,
        isOutsideRoof
      })
    }, [panels])

    return (
      <Group
        name="panel-area-group"
        draggable={isSelected}
        ref={groupRef}
        x={pixelPosition.x}
        y={pixelPosition.y}
        onDragStart={() => {
          setIsDragging(true)
        }}
        onDragEnd={(event: KonvaEventObject<Event>) => {
          if (currentPanelArea !== null && roofCoordinates !== undefined) {
            const updatedPanels = drawPanels(
              currentPanelArea,
              panelInfo,
              uid,
              removedPanels,
              roofCoordinates
            )
            updatePanelArea({
              ...panelArea,
              position: getPanelAreaPosition(
                event.target.getStage() as Konva.Stage,
                uid
              ),
              isOutsideRoof: updatedPanels.some(
                (panel) => panel.fill === '#EC848A' && !panel.removed
              ),
              panels: updatedPanels
            })
          }
          setIsDragging(false)
        }}
        onDragMove={(event: KonvaEventObject<Event>) => {
          setStatePanelAreaPosition(
            getPanelAreaPosition(event.target.getStage() as Konva.Stage, uid)
          )
        }}
      >
        <Rect
          name="panel-area"
          id={uid}
          panelAreaUid={uid}
          ref={panelAreaRef}
          draggable={false}
          width={pixelSize.width}
          height={pixelSize.height}
          onTransformStart={() => {
            const anchor = transformerRef?.current?.getActiveAnchor()
            if (anchor === null || anchor === undefined) return
            const transformSize = getTransformSize(anchor, pixelSize)
            const transformPosition = getTransformPosition(
              anchor,
              pixelSize,
              pixelPosition
            )
            currentPanelArea?.width(transformSize.width)
            currentPanelArea?.height(transformSize.height)
            updatePanelArea({
              ...panelArea,
              size: scalePixelsToMm<Size>(transformSize),
              position: scalePixelsToMm<Position>(transformPosition)
            })

            setIsRedrawing(true)
          }}
          onTransform={() => {
            if (!isRedrawing) return
            const anchor = transformerRef?.current?.getActiveAnchor()
            if (anchor === null || anchor === undefined) return
            let width = getTransformWidth(
              anchor,
              currentTransformer?.width() ?? 0
            )
            let height = getTransformerHeight(
              anchor,
              currentTransformer?.height() ?? 0
            )
            currentPanelArea?.width(width / scale)
            currentPanelArea?.height(height / scale)
            updatePanelArea({
              ...panelArea,
              size: scalePixelsToMm<Size>({
                width: width / scale,
                height: height / scale
              })
            })
          }}
          onTransformEnd={(event: KonvaEventObject<Event>) => {
            if (panelAreaRef.current === null || currentPanelArea === null)
              return
            const roofPosition = event.target
              .getStage()
              ?.findOne('.roof')
              ?.absolutePosition()
            const transformerAbsolutePosition =
              transformerRef?.current?.absolutePosition()
            if (
              roofPosition === undefined ||
              transformerAbsolutePosition === undefined
            ) {
              console.log('error getting position')
              return
            }
            const trimSize = trimPanels(currentPanelArea, panelInfo)
            const calculatedPosition = getPanelAreaPosition(
              event.target.getStage() as Konva.Stage,
              uid,
              transformerRef?.current?.getActiveAnchor() || undefined,
              trimSize
            )

            currentGroup?.offsetX(currentPanelArea?.x() || 0)
            currentGroup?.offsetY(currentPanelArea?.y() || 0)
            updatePanelArea({
              ...panelArea,
              size: scalePixelsToMm<Size>(trimSize),
              position: calculatedPosition,
              isOutsideRoof: panels.some(
                (panel) => panel.fill === '#EC848A' && !panel.removed
              )
            })
            currentPanelArea?.width(trimSize.width)
            currentPanelArea?.height(trimSize.height)
            setIsRedrawing(false)
            setStatePanelAreaPosition(calculatedPosition)
          }}
        />
        {!isDrawing && !isRedrawing ? (
          <PanelAreaGuideLines
            visible={isSelected}
            panelAreaPosition={statePanelAreaPosition}
            absolutePosition={currentTransformer?.position() || { x: 0, y: 0 }}
            scale={scale}
          />
        ) : null}
        {panels.map((panel) => {
          return isSelected || !panel.removed ? (
            <Panel
              key={panel.uid}
              {...panel}
              scale={scale}
              edit={isSelected}
              isAreaSelected={isSelected}
              updatePanel={
                system === 'east/west' ? updatePanelAndNearByPanel : updatePanel
              }
              isDragging={isDragging}
              isDrawingOrRedrawing={isDrawing || isRedrawing}
              system={'system' in panelInfo ? panelInfo.system : ''}
              hoveringPanelMatch={
                'system' in panelInfo &&
                panelInfo.system === 'east/west' &&
                hoveringPanelRowColumn !== null
                  ? matchHoverPanelEastWest(hoveringPanelRowColumn, {
                      row: panel.row,
                      column: panel.column
                    })
                  : false
              }
              setHoveringPanelRowColumn={setHoveringPanelRowColumn}
              isConfigurationComplete={isConfigurationComplete}
            />
          ) : null
        })}
        <Transformer
          name="panel-area-tr"
          ref={transformerRef}
          keepRatio={false}
          rotateEnabled={false}
          anchorCornerRadius={50}
          enabledAnchors={[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right'
          ]}
          borderEnabled={isSelected}
          borderStroke="#383838"
          anchorStroke="#383838"
          anchorFill="#383838"
          flipEnabled={false}
          resizeEnabled={isSelected}
          visible={!isScrolling}
        />
        {isSelected && currentPanelArea !== null && !isScrolling ? (
          <>
            <MeasurementIndicator
              position={getHorizontalMeasurementIndicatorPosition(
                currentPanelArea
              )}
              text={`${(
                Math.abs(currentPanelArea.width() * currentPanelArea.scaleX()) /
                100
              ).toFixed(2)} ${t('m')}`}
              scale={scale}
            />
            <MeasurementIndicator
              position={getVerticalMeasurementIndicatorPosition(
                currentPanelArea
              )}
              text={`${(
                Math.abs(
                  currentPanelArea.height() * currentPanelArea.scaleY()
                ) / 100
              ).toFixed(2)} ${t('m')}`}
              scale={scale}
            />
            <PanelAreaActions
              position={getPanelAreaActionsPosition(currentPanelArea)}
              scale={scale}
              handleShowPanelSettings={() => {
                setShowPanelSettings(!showPanelSettings)
              }}
              handleShowRemovePopup={() => {
                setShowRemovePopup(!showRemovePopup)
              }}
            />
            <PanelAreaAmountLabel
              position={getPanelAreaAmountLabelPosition(currentPanelArea)}
              scale={scale}
              isDrawing={isDrawing}
              isRedrawing={isRedrawing}
              maxPanels={getMaxPanels(currentPanelArea, panelInfo)}
              panelAreaWidth={currentPanelArea.width()}
            />
          </>
        ) : null}
        {showRemovePopup && currentPanelArea !== null && !isScrolling ? (
          <RemovePanelAreaPopup
            position={getRemovePanelAreaPopupPosition(currentPanelArea, scale)}
            confirmClick={() => handleDeletePanelArea(uid)}
            denyClick={() => setShowRemovePopup(false)}
            scale={scale}
          />
        ) : null}
      </Group>
    )
  }
)

export default PanelArea
