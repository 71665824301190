import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/sharp-solid-svg-icons'
import { ReactNode } from 'react'
import { cn } from '~/lib/utils'

type Props = {
  className?: string
  children: ReactNode
}

const ErrorMessage = ({ className, children }: Props) => (
  <div className={cn('mb-6 flex bg-red-25 p-4', className)}>
    <FontAwesomeIcon
      icon={faExclamationCircle}
      className="self-center pr-4 text-red-100"
    />
    {children}
  </div>
)

export default ErrorMessage
