import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const SaveChangesDialog = ({ dialogActions, handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <h2 className="text-xl">{t('Vill du spara ändringar?')}</h2>
      </div>
      <p className="mb-10">
        {t('Vill du spara ändringar som gjorts på pågående konfiguration?')}
      </p>
      <div className="flex gap-4">
        <Button
          onClick={() => dialogActions?.NoSave()}
          variant="ghost"
        >
          {t('Spara inte')}
        </Button>
        <Button onClick={() => dialogActions?.Save()}>{t('Spara')}</Button>
      </div>
    </DialogBox>
  )
}

export default SaveChangesDialog
