import { ReactNode } from 'react'
import CloseButton from './buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/sharp-regular-svg-icons'
import { cn } from '~/lib/utils'

type Props = {
  children: ReactNode
  isOpen: boolean
  handleClose: () => void
  className?: string
  hideCloseButton?: boolean
}

const Drawer = ({
  children,
  isOpen,
  handleClose,
  className,
  hideCloseButton
}: Props) => {
  return (
    <div
      className={cn(
        'pointer-events-none fixed left-[120px] top-[104px] z-10 flex max-h-[calc(100%-152px)] w-full max-w-[596px] flex-col bg-white opacity-0 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-200 ease-in',
        isOpen && 'pointer-events-auto opacity-100',
        className
      )}
    >
      {hideCloseButton !== true ? (
        <CloseButton
          className="mb-2 ml-auto mr-4 mt-2 flex-shrink-0"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      ) : null}
      <div className="overflow-y-auto px-14 pb-10">{children}</div>
    </div>
  )
}

export default Drawer
