import { StoreState, useBoundStore } from '~/store'
import CloseConfigurator from '~/components/CloseConfigurator'
import RoofsOverviewButton from './RoofsOverviewButton'
import RoofTabs from './RoofTabs'
import { Button } from '~/components/ui/button'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { cn, handleSaveResponseData, saveConfiguration } from '~/lib/utils'
import { AxiosResponse } from 'axios'
import Loader from '~/components/Loader'
import InfoDialog from '../dialogs/InfoDialog'
import { Trans } from 'react-i18next'
import { faArrowRightFromBracket } from '@fortawesome/sharp-regular-svg-icons'

const TopBar = () => {
  const {
    projectName,
    isEdited,
    isLoaded,
    isConfigurationComplete,
    panelAreaSections,
    setIsEdited,
    setTriggerDialog
  } = useBoundStore((state: StoreState) => ({
    isEdited: state.isEdited,
    isLoaded: state.isLoaded,
    isConfigurationComplete: state.isConfigurationComplete,
    panelAreaSections: state.panelAreaSections,
    projectName: state.conditions.name,
    setIsEdited: state.setIsEdited,
    setTriggerDialog: state.setTriggerDialog
  }))

  const [isSectionImagesGenerated, setIsSectionImagesGenerated] =
    useState(false)

  const isPanelAreaSectionsImagesGenerated = () => {
    return panelAreaSections.some(
      (panelAreaSection) => panelAreaSection.image !== undefined
    )
  }

  useEffect(() => {
    if (isPanelAreaSectionsImagesGenerated()) {
      setIsSectionImagesGenerated(true)
    } else {
      setIsSectionImagesGenerated(false)
    }
  }, [panelAreaSections])

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const save = () => {
    if (!projectName) {
      setTriggerDialog('SaveProjectNameDialog')
    } else {
      setLoading(true)
      setTimeout(() => {
        saveConfiguration(handleSaveResponse, handleFailedResponse)
      }, 500)
    }
  }

  const handleSaveResponse = (response: AxiosResponse) => {
    console.log('Save response:', response)
    setIsEdited(false)
    setLoading(false)
    handleSaveResponseData(response)
  }

  const handleFailedResponse = (error: any) => {
    console.log('Save failed:', error)
    setTriggerDialog('SomethingWentWrongDialog')
    setLoading(false)
  }

  if (isConfigurationComplete) {
    return (
      <div className="absolute right-[60px] top-[52px] z-10 flex items-center gap-8">
        {isSectionImagesGenerated || isLoaded ? (
          !isEdited ? (
            <span className="mr-6 font-medium">{t('Sparad')}</span>
          ) : loading ? (
            <Loader
              size="2xl"
              className="mr-8"
            />
          ) : (
            <Button onClick={save}>{t('Spara')}</Button>
          )
        ) : null}
        <CloseConfigurator
          className={cn(
            'z-50 ml-auto mr-12 h-8',
            isConfigurationComplete && 'mr-0 h-12'
          )}
          iconProps={{
            icon: faArrowRightFromBracket,
            color: 'black',
            size: 'lg'
          }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="absolute z-10 flex w-full items-center justify-start overflow-x-auto overflow-y-hidden bg-grayscale-25">
        <RoofTabs />
        <RoofsOverviewButton />
        <CloseConfigurator
          className={cn(
            'z-50 ml-auto mr-12 h-8',
            isConfigurationComplete && 'mr-0 h-12'
          )}
          iconProps={{
            icon: faArrowRightFromBracket,
            size: '1x',
            color: 'black'
          }}
        />
        <InfoDialog
          open={open}
          setOpen={setOpen}
          title={t('Konfiguration ej möjlig')}
          content={
            <Trans>
              <p className="max-w-md">
                Konfigurationen är inte möjlig eftersom panelfästens kapacitet
                överskrids. Försök med andra inställningar eller kontakta oss
                på&nbsp;
                <span className="font-bold">0321-261 60</span>.
              </p>
            </Trans>
          }
        />
      </div>
    </>
  )
}

export default TopBar
