import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const cssFix = `
  .gm-style-moc {
    height: unset !important;
  }
`

interface PortalProps {
  children: ReactNode
  isOpen: boolean
}

const Portal = ({ children, isOpen }: PortalProps) => {
  const el = useRef(document.createElement('div'))
  el.current.style.display = isOpen ? 'block' : 'none'
  useEffect(() => {
    const currentEl = el.current
    currentEl.style.position = 'absolute'
    currentEl.style.right = '0'
    currentEl.style.height = '100%'
    currentEl.style.width = 'calc(100% - 596px)'
    document
      .querySelector('#weland-google-maps-container')
      ?.appendChild(currentEl)

    return () => {
      document
        .querySelector('#weland-google-maps-container')
        ?.removeChild(currentEl)
    }
  }, [])

  return ReactDOM.createPortal(children, el.current)
}

type Props = {
  zoom: number
  coordinates: {
    latitude: number
    longitude: number
  }
  address: string
  handleMapInteraction: (address: string, lat: number, lng: number) => void
  isOpen: boolean
  usePortal: boolean
}

const Map = ({
  zoom,
  coordinates,
  address,
  handleMapInteraction,
  isOpen,
  usePortal
}: Props) => {
  const mapCenter = {
    lat: coordinates.latitude,
    lng: coordinates.longitude
  }

  const { latitude, longitude } = coordinates

  const handlePosition = (event: google.maps.MapMouseEvent) => {
    handleMapInteraction(
      address,
      event?.latLng?.lat() || coordinates.latitude,
      event?.latLng?.lng() || coordinates.longitude
    )
  }

  return usePortal ? (
    <Portal isOpen={isOpen}>
      <div className="absolute bottom-0 right-0 top-0 z-40 col-span-full h-full w-full">
        <style>{cssFix}</style>
        <GoogleMap
          mapContainerStyle={{
            height: '100%'
            // marginBottom: '24px'
          }}
          zoom={zoom}
          center={mapCenter}
          onClick={handlePosition}
          options={{
            disableDefaultUI: true,
            zoomControl: true
          }}
        >
          <MarkerF
            position={{
              lat: latitude,
              lng: longitude
            }}
            icon={{
              url: 'pointer_icon.svg',
              scaledSize: new google.maps.Size(26, 40)
            }}
            onDragEnd={handlePosition}
            draggable
          />
        </GoogleMap>
      </div>
    </Portal>
  ) : (
    <div className="col-span-full mb-4 h-[240px]">
      <style>{cssFix}</style>
      <GoogleMap
        mapContainerStyle={{
          height: '100%'
          // marginBottom: '24px'
        }}
        zoom={zoom}
        center={mapCenter}
        onClick={handlePosition}
        options={{
          disableDefaultUI: true,
          zoomControl: true
        }}
      >
        <MarkerF
          position={{
            lat: latitude,
            lng: longitude
          }}
          icon={{
            url: 'pointer_icon.svg',
            scaledSize: new google.maps.Size(26, 40)
          }}
          onDragEnd={handlePosition}
          draggable
        />
      </GoogleMap>
    </div>
  )
}

export default Map
