import React, { createRef, useEffect, useState } from 'react'
import { Group, Line, Text } from 'react-konva'
import { StoreState, useBoundStore } from '~/store'
import PanelAreas from './panelarea/PanelAreas'
import EdgeZone from './EdgeZone'
import { AxiosResponse } from 'axios'
import { getRoofPoints } from '~/utils/configurator'
import PanelAreaSections from './panelarea/PanelAreaSections'
import { shallow } from 'zustand/shallow'
import { solarPanelClient } from '~/http/api'
import { KonvaEventObject } from 'konva/lib/Node'
import {
  getCoordinatesFromPoints,
  getMaxOfEvenIndex,
  getMaxOfOddIndex,
  getMinOfEvenIndex,
  getMinOfOddIndex,
  getPositionImageScale
} from '~/lib/utils'
import Konva from 'konva'
import DistanceLabels from './DistanceLabels'
import { cacheNode, clearNodeCache } from '~/lib/canvasUtils'
import { t } from 'i18next'

type Props = {
  children?: React.ReactNode
  scale: number
  imageView?: boolean
  isScrolling: boolean
  isDragging: boolean
  roof: Roof
  visible?: boolean
}

const Roof = React.memo(
  ({ scale, imageView, isScrolling, isDragging, roof, visible }: Props) => {
    const {
      conditions,
      positionsOverviewImageView,
      isLoaded,
      setRoofImageScale,
      setRoofCoordinates
    } = useBoundStore(
      (state: StoreState) => ({
        conditions: state.conditions,
        positionsOverviewImageView: state.positionsOverviewImageView,
        isLoaded: state.isLoaded,
        setRoofImageScale: state.setRoofImageScale,
        setRoofCoordinates: state.setRoofCoordinates
      }),
      shallow
    )

    const [roofPoints, setRoofPoints] = useState<number[]>([])
    const [coordinates, setCoordinates] = useState<Position[]>([])
    const [currentRoof, setCurrentRoof] = useState<Konva.Group | null>(null)
    const roofRef = createRef<Konva.Group>()

    useEffect(() => {
      if (currentRoof && (isScrolling || isDragging)) {
        cacheNode(currentRoof, 1)
      } else if (currentRoof && !isScrolling && !isDragging) {
        clearNodeCache(currentRoof)
      }
    }, [isScrolling, isDragging])

    useEffect(() => {
      if (roofRef.current !== null && currentRoof === null) {
        setCurrentRoof(roofRef.current)
      }
    }, [roofRef])

    const handleResponse = (res: AxiosResponse) => {
      setCoordinates(res.data.coordinates)
    }

    useEffect(() => {
      const abortController = new AbortController()
      if (roof !== undefined) {
        const { shape, measurementA, measurementB, measurementC = 0 } = roof
        const points = getRoofPoints(shape, {
          a: measurementA,
          b: measurementB,
          c: measurementC
        })
        setRoofPoints(points)
        if (
          roof.type !== '' &&
          roof.slope >= 0 &&
          measurementA > 0 &&
          measurementB > 0
        ) {
          solarPanelClient
            .post(
              '/edge-zone',
              {
                roof,
                conditions
              },
              {
                signal: abortController.signal
              }
            )
            .then(handleResponse)
            .catch((err) => {
              console.log(err)
            })
        }
      }
      return () => {
        abortController.abort()
      }
    }, [
      roof.measurementA,
      roof.measurementB,
      roof.measurementC,
      roof.shape,
      roof.slope,
      roof.type,
      roof.uid
    ])

    const handleRoofImageScale = () => {
      const maxYValue = getMaxOfOddIndex(roofPoints)
      const minYValue = getMinOfOddIndex(roofPoints)
      const maxXValue = getMaxOfEvenIndex(roofPoints)
      const minXValue = getMinOfEvenIndex(roofPoints)

      const xValue = maxXValue - minXValue
      const yValue = maxYValue - minYValue

      const scaleValue = yValue > xValue ? yValue : xValue

      const scale = getPositionImageScale(scaleValue)

      setRoofImageScale(scale, roof.uid)
    }

    useEffect(() => {
      if (roofPoints.length > 0 && !isLoaded) {
        handleRoofImageScale()
        setRoofImageScale(scale, roof.uid)
        setRoofCoordinates(getCoordinatesFromPoints(roofPoints), roof.uid)
      }
    }, [roofPoints, isLoaded])

    if (roof === undefined || coordinates.length === 0) {
      return null
    }

    return (
      <Group
        name="roof-group"
        ref={roofRef}
        visible={visible}
        onMouseEnter={(event: KonvaEventObject<MouseEvent>) => {
          const stage = event.target.getStage()
          if (stage) {
            const containerStyle = stage.container().style
            containerStyle.cursor = 'move'
          }
        }}
        onMouseLeave={(event: KonvaEventObject<MouseEvent>) => {
          const stage = event.target.getStage()
          if (stage) {
            const containerStyle = stage.container().style
            containerStyle.cursor = 'default'
          }
        }}
        position={
          imageView
            ? { x: Math.abs(Math.min(...roofPoints)), y: 0 } // Min abs value to handle roof shapes that has part of the roof negative x value
            : roof.position
        }
      >
        <Text
          text={roof.name ?? t('Namnlös')}
          y={-20 / scale}
          fontSize={10}
          fontStyle="Roboto"
          fill="#808080"
          scale={{ x: 1 / scale, y: 1 / scale }}
        />
        <Line
          points={roofPoints}
          name="roof"
          fill="#fff"
          closed
          stroke="#696969"
          strokeEnabled={imageView === true && positionsOverviewImageView}
        />
        <EdgeZone
          coordinates={coordinates}
          scale={scale}
          imageView={imageView}
        />

        <Group>
          <PanelAreas
            scale={scale}
            imageView={imageView}
            isScrolling={isScrolling}
            roofPosition={roof.position}
            mappedRoof={roof}
          />
        </Group>
        <Group>
          <PanelAreaSections
            roof={roof}
            imageView={imageView}
          />
          <Group>
            <DistanceLabels roof={roof} />
          </Group>
        </Group>
      </Group>
    )
  }
)

export default Roof
