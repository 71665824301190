import { getLocalCartString } from '../../utils/cart'
import { useEffect, useState } from 'react'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import CartListing from './CartListing'
import EmptyCart from './EmptyCart'
import LoadingCart from './LoadingCart'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../utils/utils'
import Button from '../buttons/Button'
import CartSummary from './CartSummary'
import { solarPanelClient } from '../../http/api'
import { StoreState, useBoundStore } from '../../store'
import * as Sentry from '@sentry/react'
// import ExternalInput from '../form/ExternalInput'
// import { useDebounce } from 'use-debounce'

const Status = {
  Loading: 1,
  Error: 2,
  Success: 3,
  Empty: 4
}

interface Params {
  configs?: string
  discount?: string
}

const Cart = () => {
  const { t } = useTranslation()
  const {
    user,
    localCart,
    canSeePrices,
    setLocalCart,
    setShowDialog,
    setDialogActions
  } = useBoundStore((state: StoreState) => ({
    user: state.user,
    localCart: state.localCart,
    canSeePrices: state.user?.canSeePrices,
    setLocalCart: state.setLocalCart,
    setShowDialog: state.setShowDialog,
    setDialogActions: state.setDialogActions
  }))

  //const [localCart, setLocalCart] = useState<LocalCart>(getLocalCart())
  const [cart, setCart] = useState<Cart>()
  const [status, setStatus] = useState(Status.Loading)
  // const [discount, setDiscount] = useState<string>()
  // const [debouncedDiscount] = useDebounce(discount, 300)
  // const [discountChanged, setDiscountChanged] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    updateCart(abortController)
    return () => {
      abortController.abort()
    }
  }, [localCart])

  // useEffect(() => {
  //   updateCart()
  // }, [debouncedDiscount])

  const updateCart = (abortController: AbortController | null = null) => {
    const string = getLocalCartString(localCart)
    const params: Params = {}
    if (string.length && user === null) {
      params.configs = string
    }

    let data: AxiosRequestConfig = { params }

    if (abortController) {
      data.signal = abortController.signal
    }

    // if (discountChanged) {
    //   params.discount = discount ?? '0'
    // }

    solarPanelClient
      .get('/cart', data)
      .then(handleCartLoaded)
      .catch(handleCartError)
  }

  const handleCartLoaded = (res: AxiosResponse<Cart>) => {
    setCart(res.data)
    setStatus(res.data.configurations.length ? Status.Success : Status.Empty)
    // if (!discountChanged) {
    //   setDiscount(res.data.discount.toString())
    // }
  }

  const handleCartError = (error: AxiosError) => {
    Sentry.captureException(error)
    setStatus(Status.Error)
  }

  const clearCart = () => {
    setStatus(Status.Loading)
    setLocalCart({})
    if (user) {
      solarPanelClient.post('/clear-cart', {}).then(() => updateCart())
    }
  }

  const removeItem = (uid: string, reference: string) => {
    if (localCart[uid] === undefined) {
      solarPanelClient
        .post('/remove-from-cart', { reference })
        .then(() => updateCart())
      return
    }

    setLocalCart(
      Object.fromEntries(
        Object.entries(localCart).filter(([key]) => key !== uid)
      )
    )
  }

  const decreaseQuantity = (
    uid: string,
    reference: string,
    quantity: number
  ) => {
    quantity -= 1
    if (quantity <= 0) {
      removeItem(uid, reference)
      return
    }

    if (localCart[uid] === undefined) {
      changeStoredQuantity(reference, quantity)
      return
    }
    changeQuantity(uid, quantity)
  }

  const increaseQuantity = (
    uid: string,
    reference: string,
    quantity: number
  ) => {
    if (localCart[uid] === undefined) {
      changeStoredQuantity(reference, quantity + 1)
      return
    }
    changeQuantity(uid, quantity + 1)
  }

  const changeStoredQuantity = (reference: string, quantity: number) => {
    solarPanelClient
      .post('/update-cart', { reference, quantity })
      .then(() => updateCart())
  }

  const changeQuantity = (uid: string, quantity: number) => {
    setLocalCart({
      ...localCart,
      [uid]: {
        ...localCart[uid],
        quantity: quantity
      }
    })
  }

  return (
    <div className="h-full w-full">
      {status === Status.Empty ? <EmptyCart /> : null}
      {status === Status.Loading ? <LoadingCart /> : null}
      {status === Status.Error ? <EmptyCart /> : null}
      {status === Status.Success && cart ? (
        <div>
          <CartListing
            cart={cart}
            increaseQuantity={increaseQuantity}
            decreaseQuantity={decreaseQuantity}
            removeItem={removeItem}
          />
          {canSeePrices ? (
            <div className="mb-2 flex justify-end">
              <div className="font-bold">{t('Totalt')}:</div>&nbsp;
              <div>{formatCurrency(cart.summary.price)}</div>
            </div>
          ) : null}
          <div className="mb-[72px] flex items-end gap-6">
            <Button
              className="font-medium"
              variant="ghost"
              onClick={() => {
                if (user) {
                  setShowDialog('EmptyCartDialog')
                } else {
                  setShowDialog('EmptyCartGuestDialog')
                }
                setDialogActions({ clearCart })
              }}
            >
              {t('Töm projektkorgen')}
            </Button>
            {/*
            Removed but don't delete discount 
            {canSeePrices ? (
              <div className="max-w-[90px]">
                <ExternalInput
                  name="discount"
                  label={t('Rabatt (%)')}
                  unit="%"
                  value={discount}
                  onChange={(event) => {
                    setDiscount(event.target.value)
                    setDiscountChanged(true)
                  }}
                />
              </div>
            ) : null} */}
          </div>
          <CartSummary cart={cart} />
        </div>
      ) : null}
    </div>
  )
}

export default Cart
