import { useEffect, useState } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { t } from 'i18next'
import Loader from '../Loader'

const LoadingProjectDuplicationDialog = () => {
  const [open, setOpen] = useState(false)

  const { triggerDialog, isConfigurationComplete, setTriggerDialog } =
    useBoundStore((state: StoreState) => ({
      triggerDialog: state.triggerDialog,
      isConfigurationComplete: state.isConfigurationComplete,
      setTriggerDialog: state.setTriggerDialog
    }))

  useEffect(() => {
    if (triggerDialog === 'LoadingProjectDuplicationDialog') {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [triggerDialog])

  useEffect(() => {
    if (isConfigurationComplete) {
      setTriggerDialog('')
    }
  }, [isConfigurationComplete])

  if (!open) {
    return null
  }

  return (
    <div data-state={open ? 'open' : 'closed'}>
      <div className="fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
        <div className="absolute left-1/2 top-1/2 flex w-[300px] -translate-x-1/2 -translate-y-1/2 flex-col items-center bg-white py-10">
          <h3 className="mb-7 text-lg font-bold">
            {t('Duplicerar projekt...')}
          </h3>
          <Loader
            size="3x"
            color="#001489"
          />
        </div>
      </div>
    </div>
  )
}

export default LoadingProjectDuplicationDialog
