import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCheckCircle
} from '@fortawesome/sharp-solid-svg-icons'
import { t } from 'i18next'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'

type Props = {
  roofUid: string
}

const RoofSectionStatus = ({ roofUid }: Props) => {
  const {
    // isPositionDataValidRoofSettings,
    isRoofMaterialDataValidRoofSettings,
    isRoofPropertiesDataValidRoofSettings,
    panelAreas
  } = useBoundStore(
    (state: StoreState) => ({
      // isPositionDataValidRoofSettings: state.isPositionDataValidRoofSettings,
      isRoofMaterialDataValidRoofSettings:
        state.isRoofMaterialDataValidRoofSettings,
      isRoofPropertiesDataValidRoofSettings:
        state.isRoofPropertiesDataValidRoofSettings,
      panelAreas: state.panelAreas
    }),
    shallow
  )

  if (
    panelAreas.filter((panelArea) => panelArea.roofUid === roofUid).length > 0
  ) {
    return (
      <div>
        <FontAwesomeIcon
          className="mr-2 text-lg text-green-100"
          icon={faCheckCircle}
        />
        {`${t('Komplett')}`}
      </div>
    )
  } else {
    return (
      <div className="text-base">
        <FontAwesomeIcon
          className="mr-2 text-lg text-gold-100"
          icon={faExclamationCircle}
        />
        {`${t('Ej komplett:')} `}
        {!isRoofMaterialDataValidRoofSettings[roofUid]
          ? `${t('Takmaterial')}, `
          : null}
        {!isRoofPropertiesDataValidRoofSettings[roofUid]
          ? `${t('Takegenskaper')}, `
          : null}
        {!(
          panelAreas.filter((panelArea) => panelArea.roofUid === roofUid)
            .length > 0
        )
          ? `${t('Panelutritning')}`
          : null}
      </div>
    )
  }
  // else if (
  //   isPositionDataValidRoofSettings[roofUid] &&
  //   isRoofMaterialDataValidRoofSettings[roofUid] &&
  //   isRoofPropertiesDataValidRoofSettings[roofUid]
  // ) {
  //   return 'valid'
  // } else {
  //   return 'invalid'
  // }

  // return (
  //   <div>
  //     <FontAwesomeIcon
  //       className="mr-2 text-green-100"
  //       icon={faCheckCircle}
  //     />
  //     {`${t('Komplett')}`}
  //   </div>
  // )
}

export default RoofSectionStatus
