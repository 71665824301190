import ConfirmCloseDialog from './ConfirmCloseDialog'
import InfoDialogs from './InfoDialogs'
import GeneratePdfDialog from './GeneratePdfDialog'
import LoadingConfigurationDialog from './LoadingConfigurationDialog'
import PdfSaveProjectDialog from './PdfSaveProjectDialog'
import ProjectNameDialog from './ProjectNameDialog'
import SaveProjectNameDialog from './SaveProjectNameDialog'
import SaveQuestionDialog from './SaveQuestionDialog'
import ProjectInfoDialog from './ProjectInfoDialog'
import DeleteProjectDialog from './DeleteProjectDialog'
import LocaleStateProjectNameDialog from './LocaleStateProjectNameDialog'
import DeletePanelDialog from './DeletePanelDialog'
import CreateEditPanelForm from './CreateEditPanelDialog'
import LoadingProjectDuplicationDialog from './LoadingProjectDuplicationDialog'
import SaveProjectNameAndCloseDialog from '~/components/dialogs/SaveProjectNameAndCloseDialog'

const Dialogs = () => {
  return (
    <>
      <InfoDialogs />
      <ProjectNameDialog />
      <LocaleStateProjectNameDialog />
      <PdfSaveProjectDialog />
      <GeneratePdfDialog />
      <ConfirmCloseDialog />
      <SaveProjectNameDialog />
      <SaveProjectNameAndCloseDialog />
      <SaveQuestionDialog />
      <LoadingConfigurationDialog />
      <LoadingProjectDuplicationDialog />
      <ProjectInfoDialog />
      <DeleteProjectDialog />
      <DeletePanelDialog />
      <CreateEditPanelForm />
    </>
  )
}

export default Dialogs
