import { t } from 'i18next'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs'
import Profile from './Profile'
import { cn } from '~/lib/utils'
import SavedPanels from './SavedPanels'

const ProfileTabs = () => {
  return (
    <Tabs
      defaultValue="my-profile"
      className={cn('h-full w-full')}
    >
      <TabsList className="mb-10 flex bg-transparent">
        <TabsTrigger value="my-profile">{t('Kontoinställningar')}</TabsTrigger>
        <TabsTrigger value="my-panels">{t('Mina paneler')}</TabsTrigger>
      </TabsList>

      <TabsContent
        value="my-profile"
        className="h-full"
      >
        <Profile />
      </TabsContent>

      <TabsContent
        value="my-panels"
        className="h-full"
      >
        <SavedPanels />
      </TabsContent>
    </Tabs>
  )
}

export default ProfileTabs
