import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Button from './buttons/Button'
import { authClient } from '../http/api'
import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

const ResetPasswordSent = () => {
  const { t } = useTranslation()
  const { email } = useParams()
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleApiResponse = () => {
    setDisableSubmit(false)
  }

  const handleFailedApiResponse = (error: AxiosError) => {
    Sentry.captureException(error)
    setDisableSubmit(false)
  }

  return (
    <div className="max-w-[464px] bg-white text-center">
      <h1 className="heading-l mb-6">{t('Instruktioner skickade')}</h1>
      <p className="mb-5">
        {t('Instruktioner om återställning av lösenord har nu skickats till ')}
        <strong>{email}</strong>.
        {t(
          '  Kolla din inkorg och klicka på länken för att slutföra återställningen av ditt lösenord.'
        )}
      </p>
      <p className="mb-10">
        {t('Om du inte hittar mailet, kolla din skräppost.')}
      </p>
      <Button
        disabled={disableSubmit}
        onClick={() => {
          setDisableSubmit(true)
          authClient
            .post('/forgot-password', {
              email,
              loginUrl: window.location.origin
            })
            .then(handleApiResponse)
            .catch(handleFailedApiResponse)
        }}
      >
        {t('Skicka igen')}
      </Button>
    </div>
  )
}

export default ResetPasswordSent
