// import { StoreState, useBoundStore } from '~/store'
// import { Button } from './ui/button'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faArrowDownToLine,
//   faEllipsisVertical
// } from '@fortawesome/sharp-regular-svg-icons'
import UtilizationRates from './UtilizationRates'
import { t } from 'i18next'
import { cn, getRoofName, isRoofNotApproved, round } from '~/lib/utils'
import { StoreState, useBoundStore } from '~/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/sharp-solid-svg-icons'

type Props = {
  roof: Roof
}

const RoofInformation = ({ roof }: Props) => {
  const { summary, panelAreaSections } = useBoundStore((state: StoreState) => ({
    summary: state.summary,
    panelAreaSections: state.panelAreaSections
  }))

  const roofPanelAreaSections = panelAreaSections?.filter(
    (section) => section.roofUid === roof.uid
  )

  return (
    <>
      <div className="pt-4">
        <h1
          className={cn(
            'heading-m mb-6',
            isRoofNotApproved(roof.uid) && 'flex items-center text-red-100'
          )}
        >
          {isRoofNotApproved(roof.uid) ? (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="mr-2 text-xl"
            />
          ) : null}
          {roof.name}
        </h1>
        <h3 className="heading-s mb-4">{t('Takinformation')}</h3>
        <div className="mb-8 text-base">
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Mått A')}</div>
            <div className="font-light">
              {round(roof.measurementA / 1000, 3)} {t('m')}
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Mått B')}</div>
            <div className="font-light">
              {round(roof.measurementB / 1000, 3)} {t('m')}
            </div>
          </div>
          {roof.measurementC > 0 ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Mått C')}</div>
              <div className="font-light">
                {round(roof.measurementC / 1000, 3)} {t('m')}
              </div>
            </div>
          ) : null}
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Byggnadens höjd')}</div>
            <div className="font-light">
              {round(roof.ridgeHeight / 1000, 3)} {t('m')}
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Taklutning')}</div>
            <div className="font-light">
              {roof.slope}
              {t('°')}
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Taktyp')}</div>
            <div className="font-light">{getRoofName(roof.type)}</div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Infästning')}</div>
            <div className="font-light">
              <div>{summary.projectDetails.attachment}</div>
            </div>
          </div>
          {roof.covering === 'concrete_tile' ||
          roof.covering === 'brick_tile' ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Storlek panna')}</div>
              <div className="font-light">
                {`${roof.tileWidth} x ${roof.tileHeight}`} {t('mm')}
              </div>
            </div>
          ) : null}
          {roof.covering === 'corrugated_tin_metal' ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Avstånd mellan falsar')}</div>
              <div className="font-light">
                {roof.topDistance} {t('mm')}
              </div>
            </div>
          ) : null}
          {roof.covering === 'profiled_sheet_metal' ||
          roof.covering === 'tile_profiled_sheet_metal' ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Avstånd mellan toppar')}</div>
              <div className="font-light">
                {roof.topDistance} {t('mm')}
              </div>
            </div>
          ) : null}
          {roof.attachment === 'lath_mount' ? (
            <div className="mb-2 flex">
              <div className="w-56 font-bold">{t('Dimension läkt')}</div>
              <div className="font-light">
                {roof.lathDimension} {t('mm')}
              </div>
            </div>
          ) : null}
          {(roof.attachment === 'long_rail' ||
            roof.attachment === 'short_rail') &&
          roofPanelAreaSections !== undefined ? (
            <>
              <div className="mb-2 flex">
                <div className="w-56 font-bold">{t('Antal skruv/meter')}</div>
                <div className="font-light">
                  {t(
                    '{{numberOfScrews}} st ({{numberOfScrewPairs}} skruvpar)',
                    {
                      numberOfScrews:
                        (
                          roofPanelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).hi.screwsPerMeter ?? 0,
                      numberOfScrewPairs:
                        ((
                          roofPanelAreaSections[0]?.result
                            .values as ResultValuesParallell
                        ).hi.screwsPerMeter ?? 0) / 2
                    }
                  )}
                </div>
              </div>
              <div className="mb-2 flex">
                <div className="w-56 font-bold">
                  {t('Antal skruv/meter (randzon)')}
                </div>
                <div className="font-light">
                  <div>
                    {t(
                      '{{numberOfScrews}} st ({{numberOfScrewPairs}} skruvpar)',
                      {
                        numberOfScrews:
                          (
                            roofPanelAreaSections[0]?.result
                              .values as ResultValuesParallell
                          ).fgj.screwsPerMeter ?? 0,
                        numberOfScrewPairs:
                          ((
                            roofPanelAreaSections[0]?.result
                              .values as ResultValuesParallell
                          ).fgj.screwsPerMeter ?? 0) / 2
                      }
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <h3 className="heading-s mb-4">{t('System')}</h3>
        <div className="mb-8 text-base">
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Typ av system')}</div>
            <div className="font-light">{summary.roofs[roof.uid].system}</div>
          </div>
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Antal paneler')}</div>
            <div className="font-light">
              {summary.roofs[roof.uid].numberOfPanels}
            </div>
          </div>
          {/* <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Vikt infästning')}</div>
            <div className="font-light">{summary.roofs[roof.uid].system}</div>
          </div> */}
          <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Vikt paneler')}</div>
            <div className="font-light">
              {`${round(
                summary.roofs[roof.uid].totalPanelWeight / 1000,
                1
              )} ${t('kg')}`}
            </div>
          </div>
          {/* <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Vikt för systemet')}</div>
            <div className="font-light">{9999999}</div>
          </div> */}
          {/* <div className="mb-2 flex">
            <div className="w-56 font-bold">{t('Vikt per kvadratmeter')}</div>
            <div className="font-light">{9999999}</div>
          </div> */}
          <div></div>
        </div>

        <div className="dashed mb-8 h-[1px]"></div>

        <UtilizationRates roofUid={roof.uid} />
        {/* <div className="h-[1px] border-grayscale-25 bg-im"></div> */}
        {/* <div className="h-1 bg-im bg-repeat-x bg-size-[1px_5px]"></div> */}
        {/* <div className="bg-custom-gradient  bg-custom-size h-1 bg-bottom bg-repeat-x"></div> */}
        {/* <div className="h-32 bg-gradient-to-r from-blue-500 to-purple-500"></div> */}
        {/* <div className="bg-im  h-[1px] border-grayscale-25"></div> */}
        {/* <div className="dashed h-[1px]"></div> */}
      </div>
    </>
  )
}

export default RoofInformation
