import { AxiosResponse } from 'axios'
import { useBoundStore } from '~/store'
import { Message } from '~/slices/messageSlice'

interface MessagesResponse {
  messages: {
    current_page: number
    data: Message[]
    total: number
    to: number
  }
}

export const handleMessagesResponse = (
  res: AxiosResponse<MessagesResponse>
) => {
  const { setMessages, setDisableLoadMoreMessages } = useBoundStore.getState()

  setMessages({
    posts: res.data.messages.data,
    total: res.data.messages.total,
    loaded: res.data.messages.to ?? 0,
    page: 1
  })
  setDisableLoadMoreMessages(false)
}

export const handleLoadMoreMessagesResponse = (
  res: AxiosResponse<MessagesResponse>
) => {
  const { addMessages, setDisableLoadMoreMessages } = useBoundStore.getState()

  addMessages(
    res.data.messages.data,
    res.data.messages.total,
    res.data.messages.to ?? 0,
    res.data.messages.current_page
  )
  setDisableLoadMoreMessages(false)
}
