import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from '~/components/form/fields/Input'
import Button from '~/components/buttons/Button'
import Radio from '~/components/form/fields/Radio'
import Checkbox from '~/components/form/fields/Checkbox'
import NewSelect from '../fields/NewSelect'
import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { defaultLowSlopePanelInfoValues } from '~/slices/ConfiguratorSlice'
import eastWestSystemImage from '~/assets/east_west_system.png'
import SouthSystemImage from '~/assets/south_system.png'
import panelMounting_2_2 from '~/assets/PANELMONTERING_2_2.svg'
import Info from '~/components/Info'
import Slider from '../fields/Slider'
import { getPanelHeightMounted, getPanelWidthMounted } from '~/lib/panel'
import {
  validateRailDistance,
  DistanceValidation,
  inferRailDistanceFromPanel,
  resetRailDistance,
  removeFromSessionStorage,
  savePanel,
  shouldGenerateNewPanelUid,
  calculatePanelWeight
} from '~/lib/utils'
import { t } from 'i18next'
import { cn } from '~/lib/utils'
import { v4 as uuidv4 } from 'uuid'
import LinkButton from '~/components/buttons/LinkButton'

const validationSchema = z
  .object({
    uid: z.string(),
    name: z.union([z.string(), z.undefined()]),
    system: z.string(),
    width: z.number(),
    height: z.number(),
    weight: z.number(),
    mounting: z.string(),
    useSupportPlates: z.boolean(),
    gapRow: z.number(),
    selectedRailDistance: z.number(),
    doSavePanel: z.boolean(),
    customRailDistanceIsSet: z.union([z.string(), z.boolean()])
  })
  .superRefine((schema, ctx) => {
    lowSlopeRailDistanceRefiner(schema, ctx)
  })
  .superRefine((schema, ctx) => {
    lowSlopeDimensionsRefiner(schema, ctx)
  })
  .refine(
    (schema) =>
      (schema.system === 'south' && schema.gapRow >= 105) ||
      schema.system !== 'south',
    {
      message: t(
        'Avstånd mellan panelrader måste vara större eller lika med 105'
      ),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'south' && schema.gapRow <= 720) ||
      schema.system !== 'south',
    {
      message: t('Avstånd mellan panelrader måste mindre eller lika med 720'),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'east/west' && schema.gapRow >= 160) ||
      schema.system !== 'east/west',
    {
      message: t(
        'Avstånd mellan panelrader måste vara större eller lika med 160'
      ),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.system === 'east/west' && schema.gapRow <= 720) ||
      schema.system !== 'east/west',
    {
      message: t('Avstånd mellan panelrader måste mindre eller lika med 720'),
      path: ['gapRow']
    }
  )
  .refine(
    (schema) =>
      (schema.doSavePanel && schema.name !== undefined && schema.name !== '') ||
      !schema.doSavePanel,
    {
      message: t('Ange panelnamn'),
      path: ['name']
    }
  )

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const lowSlopeRailDistanceRefiner = (
  schema: ValidationSchema,
  ctx: z.RefinementCtx
) => {
  const distanceValidation = validateRailDistance(
    schema.width,
    schema.height,
    { mounting: schema.mounting, system: schema.system },
    schema.selectedRailDistance
  )
  switch (distanceValidation) {
    case DistanceValidation.TooSmall:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Önskat avstånd är för litet'),
        path: ['selectedRailDistance']
      })
      break
    case DistanceValidation.TooLarge:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Önskat avstånd är för stort'),
        path: ['selectedRailDistance']
      })
      break
    default:
      break
  }
}

const lowSlopeDimensionsRefiner = (
  schema: ValidationSchema,
  ctx: z.RefinementCtx
) => {
  const isAPanelSelected = schema.uid !== ''
  const { system, height, width } = schema
  const addIssue = (path: string[], message: string) => {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message,
      path
    })
  }

  if (system === 'south' && height < 980) {
    addIssue(
      isAPanelSelected ? ['uid'] : ['height'],
      isAPanelSelected
        ? t('Höjd på panel måste vara större eller lika med 980')
        : t('Höjd måste vara större eller lika med 980')
    )
  }

  if (system === 'east/west' && width < 980) {
    addIssue(
      isAPanelSelected ? ['uid'] : ['width'],
      isAPanelSelected
        ? t('Bredd på panel måste vara större eller lika med 980')
        : t('Bredd måste vara större eller lika med 980')
    )
  }

  if (system === 'south' && height >= width) {
    addIssue(isAPanelSelected ? ['uid'] : ['height'], t('Panelens höjd får inte överstiga bredd'))
  }

  if (system === 'east/west' && width >= height) {
    addIssue(isAPanelSelected ? ['uid'] : ['width'], t('Panelens bredd får inte överstiga höjd'))
  }
}

/**
 * Validates and merges panel information form data with default panel information.
 *
 * @param data - The form data to be validated and merged. If the same attributes exists in data and defaultPanelInfo,
 * data will overwrite corresponding attributes in defaultPanelInfo.
 * @param defaultPanelInfo - The default panel information to merge with the validated data.
 * @returns The merged and validated panel information.
 */
const getValidPanelInfoFormData = (
  data: ValidationSchema,
  defaultPanelInfo: PanelInfoLow
): ValidationSchema => {
  const validData = validationSchema.parse(data)

  return {
    ...defaultPanelInfo,
    ...validData,
    uid: shouldGenerateNewPanelUid(validData.doSavePanel, validData.uid)
      ? uuidv4()
      : validData.uid,
    widthMounted: validData.width,
    heightMounted: validData.height
  } as ValidationSchema
}

/**
 * Generates an array of options for LowSlope settings based on the roof properties.
 *
 * @param {Roof} roof - The roof object containing properties such as covering and attachment.
 * @returns {NameAndLabel[]} An array of objects, each containing a name and label for the options.
 */
const getLowSlopeOptions = (roof: Roof): NameAndLabel[] => {
  const options = []

  if (
    roof?.covering == 'flat' &&
    ['sealing_plate_flat', 'sealing_plate_perforated'].includes(
      roof?.attachment
    )
  ) {
    options.push({
      name: 'useSupportPlates',
      label: t('Använd stödplattor')
    })
  }

  return options
}

/**
 * Determines if the given system and orientation are conflicting.
 *
 * @param {string} system - The system type, either 'south' or 'east/west'.
 * @param {boolean} isPortrait - The orientation of the panel, true if portrait, false if landscape.
 * @returns {boolean} - Returns true if the system and orientation are conflicting, otherwise false.
 */
const isSystemAndOrientationConflicting = (
  system: string,
  isPortrait: boolean
): boolean => {
  return (
    (system === 'south' && isPortrait) ||
    (system === 'east/west' && !isPortrait)
  )
}

/**
 * Infers the correct mounting orientation based on the provided mounting string and orientation flag.
 *
 * @param {string} mounting - The mounting string in the format "ANGLE-ORIENTATION" e.g 90-portrait.
 * @param {boolean} isPortrait - A flag indicating if panel is in portrait mode or landscape (standing vertically or lying horizontally).
 * @returns {string} - The inferred mounting string with the correct orientation.
 */
const inferSystem = (system: string, isPortrait: boolean): string => {
  if (isSystemAndOrientationConflicting(system, isPortrait)) {
    switch (system) {
      case 'south':
        return 'east/west'
      default:
        return 'south'
    }
  }

  return system
}

const LowSlopePanelSettings = React.memo(() => {
  const { t } = useTranslation()

  const {
    roof,
    activeArea,
    panelAreas,
    panelInfoLow,
    user,
    setUser,
    setShowPanelSettings,
    updatePanelArea,
    setPanelInfoLow
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.computed.currentRoof,
      ...state
    }),
    shallow
  )

  const selectedArea = activeArea !== null
  const defaultFormValues = {
    uid: panelInfoLow.uid,
    name: panelInfoLow.name,
    system: panelInfoLow.system,
    width: panelInfoLow.width,
    height: panelInfoLow.height,
    weight: panelInfoLow.weight,
    mounting: '2',
    useSupportPlates: true,
    gapRow: panelInfoLow.gapRow,
    selectedRailDistance: panelInfoLow.selectedRailDistance,
    customRailDistanceIsSet: panelInfoLow.customRailDistanceIsSet
  }

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultFormValues
  })

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { isDirty }
  } = form

  const width = watch('width')
  const height = watch('height')
  const system = watch('system')
  const gapRow = watch('gapRow')
  const doSavePanel = watch('doSavePanel')
  const uid = watch('uid')
  const customRailDistanceIsSet = watch('customRailDistanceIsSet')

  const [options, setOptions] = useState<NameAndLabel[]>([] as NameAndLabel[])
  const [gapRowMin, setGapRowMin] = useState(system === 'south' ? 105 : 160)

  const optionsToShow = options.map((option) => {
    return (
      <Checkbox
        key={option.name}
        name={option.name}
        label={option.label}
        className={cn('mb-6')}
        disabled
      />
    )
  })

  const panelAreaIndex = panelAreas.findIndex(
    (panelArea) => panelArea.uid === activeArea
  )
  const currentPanel = user?.panels?.find((panel) => panel.uid === uid)
  const currentPanelIsSet = currentPanel !== undefined
  const calculatedRailDistance = inferRailDistanceFromPanel(width, height, {
    mounting: '2',
    system
  })
  const isPortrait = height > width
  const activePanelAreaInfo = panelAreaIndex != -1 ? panelAreas[panelAreaIndex].panelInfo : null

  useEffect(() => {
    if (selectedArea && panelAreaIndex !== -1) {
      const panelInfoLow = panelAreas[panelAreaIndex].panelInfo as PanelInfoLow
      reset({ ...panelInfoLow, doSavePanel: false })
    }
  }, [activeArea])

  useEffect(() => {
    if (roof === undefined) return
    setOptions(getLowSlopeOptions(roof))
  }, [roof])

  useEffect(() => {
    if (system === 'south') {
      setGapRowMin(105)
      setValue('gapRow', 400)
    } else {
      setGapRowMin(160)
      setValue('gapRow', 350)
    }

    if (isSystemAndOrientationConflicting(system, isPortrait)) {
      setValue('width', activePanelAreaInfo ? activePanelAreaInfo.height : height)
      setValue('height', activePanelAreaInfo ? activePanelAreaInfo.width : width)
    }

    if (! customRailDistanceIsSet && ! activeArea) {
      setValue('selectedRailDistance', inferRailDistanceFromPanel(width, height, {system, mounting: '2'}))
    }
  }, [system])

  useEffect(() => {
    if (activeArea) return

    if (isSystemAndOrientationConflicting(system, isPortrait)) {
      setValue('width', height)
      setValue('height', width)
    }

    if (! customRailDistanceIsSet) {
      setValue('selectedRailDistance', calculatedRailDistance)
    }
  }, [width, height])

  const selectPanel = (uid: string) => {
    const selectedPanel = user?.panels?.find((panel) => panel.uid === uid)
    if (selectedPanel == undefined || activeArea) return

    setValue('customRailDistanceIsSet', false)

    const formValues = getValues()

    /** When repopulating the form fields with chosen panel data,
     * we have to check the width and height of panel and
     * the chosen system to see if the values should be flipped */
    const height = isSystemAndOrientationConflicting(
      system,
      selectedPanel.width < selectedPanel.height
    )
      ? selectedPanel.width
      : selectedPanel.height
    const width = isSystemAndOrientationConflicting(
      system,
      selectedPanel.width < selectedPanel.height
    )
      ? selectedPanel.height
      : selectedPanel.width

    try {
      const validData = getValidPanelInfoFormData(formValues, panelInfoLow)
      reset({
        ...validData,
        ...selectedPanel,
        // Ensure the form resets with the uid from the active area if it's set, not always using the most recently selected panel.
        uid: activeArea ? validData.uid : selectedPanel.uid,
        selectedRailDistance: inferRailDistanceFromPanel(width, height, {system, mounting: '2'}),
        height,
        width
      })
    } catch (error) {
      const zodErr = error as z.ZodError
      reset({ ...selectedPanel, ...formValues, height, width })
      zodErr.issues.forEach((issue) => {
        form.setError(issue.path[0] as any, {
          type: 'manual',
          message: issue.message
        })
      })
    }
  }

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    try {
      const panelInfoFormData = getValidPanelInfoFormData(data, panelInfoLow)

      const validData = {
        ...panelInfoLow,
        ...panelInfoFormData,
        heightMounted: getPanelHeightMounted(
          panelInfoFormData.system,
          panelInfoFormData.height
        ),
        widthMounted: getPanelWidthMounted(
          panelInfoFormData.system,
          panelInfoFormData.width
        )
      }

      if (selectedArea) {
        updatePanelArea({
          ...panelAreas[panelAreaIndex],
          panelInfo: validData as PanelInfoLow,
          useSupportPlates: validData.useSupportPlates,
          railsPerRow: parseInt(validData.mounting)
        })
      } else {
        setPanelInfoLow(validData as PanelInfoLow)

        if (doSavePanel) {
          savePanel(validData as PanelInfoLow, (res) => {
            setUser({ ...user, panels: res.data.panels } as User)

            /**
             * Have to set uid here, because savePanel is async.
             * If we don't, the uid will be empty when the form is reset.
             * This is because no corresponding panel is found in the
             * user.panels after re-render, since the savePanel function
             * hasn't finished yet. Therefore we need to set the uid here,
             * after the savePanel function has finished.
             */
            setValue('uid', validData.uid)
          })
        }

        localStorage.setItem('LowSlopePanelMounting', validData.mounting)
        localStorage.setItem(
          'useSupportPlates',
          validData.useSupportPlates.toString()
        )
        localStorage.setItem('panelSystem', validData.system)
        localStorage.setItem('gapRow', validData.gapRow.toString())
        sessionStorage.setItem(
          'selectedRailDistance',
          validData.selectedRailDistance.toString()
        )
      }

      setShowPanelSettings(false)

      reset({
        uid: validData.uid,
        name: validData.name,
        system: validData.system,
        height: validData.height,
        width: validData.width,
        weight: validData.weight,
        mounting: validData.mounting,
        useSupportPlates: validData.useSupportPlates,
        gapRow: validData.gapRow,
        selectedRailDistance: validData.selectedRailDistance,
        doSavePanel: false
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  return (
    <FormProvider {...form}>
      <form
        className="mb-6 grid w-full grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="heading-m col-span-full mb-6">
          {panelAreaIndex > -1
            ? `${t('Redigera inställningar - Panelyta')} ${panelAreaIndex + 1}`
            : t('Panelinställningar')}
        </h2>

        {/* Show saved panels if user has any */}
        {user && user.panels && user.panels.length > 0 ? (
          <div className={cn('col-span-full mb-4')}>
            <NewSelect
              name="uid"
              label={t('Mina paneler')}
              placeholder={t('Välj panel')}
              className={cn('mb-2')}
              disabled={selectedArea}
              options={user.panels
                .map((panel) => {
                  return { value: panel.uid, label: panel.name }
                })
                .toSorted((a, b) => a.label.localeCompare(b.label))}
              onChange={selectPanel}
            />

            {currentPanel && (
              <div className={cn('col-span-full flex')}>
                <p className={cn('text-sm font-normal')}>
                  <strong className={cn('font-bold')}>
                    {t('Dimensioner: ')}
                  </strong>
                  {currentPanel.width} x {currentPanel.height} mm,{' '}
                  {currentPanel.weight} kg
                </p>
                <LinkButton
                  onClick={(e) => {
                    e.preventDefault()
                    reset(defaultLowSlopePanelInfoValues)

                    setValue('uid', '')
                  }}
                  disabled={activeArea != null}
                >
                  {t('Återställ')}
                </LinkButton>
              </div>
            )}
          </div>
        ) : null}

        <h3 className="col-span-full text-lg font-bold">
          {t('Typ av system')}
        </h3>
        <Radio
          name="system"
          options={[
            {
              value: 'east/west',
              label: t('Öst/väst-system'),
              image: eastWestSystemImage
            },
            {
              value: 'south',
              label: t('Sydsystem'),
              image: SouthSystemImage
              // rules: { disabled: true }
            }
          ]}
          rules={{
            required: { value: true, message: t('Obligatorisk') },
            onChange: () => setValue('customRailDistanceIsSet', false)
          }}
          disabled={selectedArea}
        />

        {/* Dimensions section */}
        {! currentPanel ? (
          <h3 className="col-span-full text-lg font-bold">
          {t('Dimensioner')}{' '}
          <Info
            id={'dimension-tooltip'}
            text={'Panelen ska ha en bredd på minst 980mm och max 1134mm.'}
          />
        </h3>
        ): null}
        
        <>
          <Input
            name="width"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Bredd') || ''}
            onChange={(e) => {
              const selectedWidth = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0
              const isPortrait = height > selectedWidth

              setValue('selectedRailDistance', inferRailDistanceFromPanel(selectedWidth, height, {system: system, mounting: '2'}))
              setValue('weight', calculatePanelWeight(selectedWidth, height))
              setValue('customRailDistanceIsSet', false)

              setValue('system', inferSystem(system, isPortrait))
            }}
            className="col-span-1"
            required
            disabled={selectedArea}
            unit={t('mm')}
          />
          <Input
            name="height"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Höjd') || ''}
            className="col-span-1"
            required
            disabled={selectedArea}
            onChange={(e) => {
              const selectedHeight = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0
              const isPortrait = selectedHeight > width

              setValue('selectedRailDistance', inferRailDistanceFromPanel(width, selectedHeight, {system, mounting: '2'}))
              setValue('weight', calculatePanelWeight(width, selectedHeight))
              setValue('customRailDistanceIsSet', false)

              setValue('system', inferSystem(system, isPortrait))
            }}
            unit={t('mm')}
          />
          <Input
            name="weight"
            type={!currentPanelIsSet ? 'number' : 'hidden'}
            label={t('Vikt') || ''}
            className="col-span-1 mb-4"
            required
            disabled={selectedArea}
            unit={t('kg')}
          />

          {/* Save panel section */}
          <Checkbox
            name="doSavePanel"
            label={t('Spara panel till min profil')}
            className={cn([doSavePanel ? 'mb-2' : 'mb-6', currentPanelIsSet ? 'hidden' : ''])}
            disabled={selectedArea}
          />
        </>

        <Input
          name="name"
          type={doSavePanel ? 'text' : 'hidden'}
          label={t('Panelens namn')}
          className="col-span-full"
          disabled={selectedArea}
        />

        {/** Mounting section */}
        <h3 className="col-span-full text-lg font-bold">
          {t('Panelmontering')}
        </h3>
        <Radio
          name="mounting"
          options={[
            {
              value: '2',
              image: panelMounting_2_2
            }
          ]}
          vertical
          disabled={selectedArea}
        />
        <Slider
          name="gapRow"
          label={
            t('Avstånd panelrader ({{gapRowMin}}-720mm)', {
              gapRowMin
            }) || ''
          }
          min={gapRowMin}
          max={720}
          unit={t('mm')}
          wide
          disabled={selectedArea}
          infoMessage={
            gapRow < 350 && system === 'south'
              ? t('Ger dålig instrålningsvinkel')
              : undefined
          }
        />

        {/* Rail distance section */}
        <div className={cn('mb-2 flex flex-col')}>
          <Input
            name="selectedRailDistance"
            type="number"
            icon={
              <Info
                iconSize="lg"
                id="selectedRailDistance-icon"
                text={t('Måttet som anges avser CC-mått.')}
              />
            }
            onChange={() => setValue('customRailDistanceIsSet', true)}
            label={t('Avstånd mellan klämzoner') || ''}
            className="col-span-1"
            required
            unit={t('mm')}
          />
          {customRailDistanceIsSet ? (
            <button
              onClick={(e) => {
                e.preventDefault()
                resetRailDistance(
                  width,
                  height,
                  { mounting: '2', system: system },
                  (railDistance) => {
                    setValue('selectedRailDistance', railDistance)
                    setValue('customRailDistanceIsSet', false)
                    removeFromSessionStorage('selectedRailDistance')
                    clearErrors('selectedRailDistance')
                  }
                )
              }}
              className={cn('ml-auto text-sm font-light underline')}
            >
              {t('Återställ')}
            </button>
          ) : null}
        </div>

        {options.length > 0 ? (
          <>
            <h3 className="col-span-full text-lg font-bold">
              {t('Tillval')}{' '}
              <span className="font-normal">{t('(valfritt)')}</span>
            </h3>
            {optionsToShow}
          </>
        ) : null}

        <div className="col-start-2 flex justify-end">
          <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default LowSlopePanelSettings
