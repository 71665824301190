import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faFiles,
  faPenToSquare,
  faRightFromBracket,
  faRightToBracket,
  faUser
} from '@fortawesome/sharp-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { cn } from '~/lib/utils'
import { authClient } from '../http/api'
import { StoreState, useBoundStore } from '../store'
import { Button } from './ui/button'

const SideBar = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { user, setUser } = useBoundStore((store: StoreState) => ({
    user: store.user,
    setUser: store.setUser
  }))

  const logout = () => {
    authClient.post('/logout').then(() => {
      setUser(null)
      navigateTo('/login')
    })
  }

  return (
    <div className="inset-y fixed left-0 flex h-full w-64 flex-col justify-between bg-silver-25">
      <div className="mt-14">
        <div className="px-4">
          <Link
            to={'/system'}
            className=""
          >
            <Button className="w-full">
              {' '}
              <FontAwesomeIcon
                icon={faPenToSquare}
                fixedWidth
                className="mr-4 text-lg"
              />{' '}
              {t('Nytt projekt')}
            </Button>
          </Link>
          <hr className="my-4 h-0.5 bg-grayscale-25" />
        </div>
        {/* <NavLink
          to="/"
          className={({ isActive }) =>
            cn(
              'flex w-full flex-1 items-center py-4 pl-12 font-medium text-grayscale-50 no-underline hover:text-black',
              isActive && 'bg-silver-50 text-black'
            )
          }
        >
          <img
            src={newConfiguration}
            className="mr-6 block h-6"
          />

          {t('Konfigurator')}
        </NavLink> */}
        <NavLink
          to="/projects"
          className={({ isActive }) =>
            cn(
              'flex w-full flex-1 items-center py-4 pl-12 font-medium text-grayscale-50 no-underline hover:text-black',
              isActive && 'bg-silver-50 text-black'
            )
          }
        >
          <FontAwesomeIcon
            icon={faFiles}
            fixedWidth
            className="mr-4 text-lg"
          />
          {t('Mina projekt')}
        </NavLink>
        <NavLink
          to="/messages"
          className={({ isActive }) =>
            cn(
              'flex w-full flex-1 items-center py-4 pl-12 font-medium text-grayscale-50 no-underline hover:text-black',
              isActive && 'bg-silver-50 text-black'
            )
          }
        >
          {({ isActive }) => (
            <>
              <FontAwesomeIcon
                icon={faBell}
                fixedWidth
                className="mr-4 text-lg"
              />
              {t('Meddelanden')}
              {user && user.unreadMessages > 0 ? (
                <span
                  className={cn(
                    'ml-4 flex h-4 w-[22px] items-center justify-center rounded-sm bg-silver-75 text-[12px] font-medium',
                    isActive && 'bg-silver-100'
                  )}
                >
                  {user.unreadMessages > 99 ? '99+' : user.unreadMessages}
                </span>
              ) : null}
            </>
          )}
        </NavLink>
        {user ? (
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              cn(
                'flex w-full flex-1 items-center py-4 pl-12 font-medium text-grayscale-50 no-underline hover:text-black',
                isActive && 'bg-silver-50 text-black'
              )
            }
          >
            <FontAwesomeIcon
              icon={faUser}
              fixedWidth
              className="mr-4 text-lg"
            />
            {t('Min profil')}
          </NavLink>
        ) : null}
      </div>
      <div className="mb-14 mt-auto text-base text-grayscale-50">
        {user ? (
          <Link
            to=""
            className={cn(
              'flex w-full flex-1 items-center py-4 pl-12 font-medium no-underline'
            )}
            onClick={logout}
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              fixedWidth
              className="mr-4 text-lg"
            />
            {t('Logga ut')}
          </Link>
        ) : (
          <Link
            to="/login"
            className={cn(
              'flex w-full flex-1 items-center py-4 pl-12 font-medium no-underline'
            )}
          >
            <FontAwesomeIcon
              icon={faRightToBracket}
              fixedWidth
              className="mr-4 text-lg"
            />
            {t('Logga in')}
          </Link>
        )}
      </div>
    </div>
  )
}

export default SideBar
