import i18n from '~/lib/i18n'
const { t } = i18n

import flatRoof from '~/assets/slata-tak.png'
import sealingPlateOpti from '~/assets/tatplat_opti.jpg'
import pent from '~/assets/pulpet.svg'
import rectangleSmall from '~/assets/roof_shape_small_rectangle.png'
import hotGalvanized from '~/assets/varmforzinkat.png'
import blackLacquered from '~/assets/svartlackerat.png'

export type formOptionsType = {
  roofType: formOptionsItemType[]
  roofShape: formOptionsItemType[]
  roofCovering: formOptionsItemType[]
  attachment: formOptionsItemType[]
  surfaceTreatment: formOptionsItemType[]
  lifeSpan: formOptionsItemType[]
  safetyClass: formOptionsItemType[]
}

export const lowSlopeFormOptions: formOptionsType = {
  roofType: [{ value: 'pent', label: t('Pulpet'), image: pent }],
  roofShape: [
    { value: 'rectangle', label: t('Rektangel'), image: rectangleSmall }
  ],
  roofCovering: [{ value: 'flat', label: t('Släta tak'), image: flatRoof }],
  attachment: [
    {
      value: 'sealing_plate_flat',
      label: t('Tätplåt Opti'),
      image: sealingPlateOpti
    }
  ],
  surfaceTreatment: [
    { value: '', label: t('Varmförzinkad'), image: hotGalvanized },
    { value: 'S', label: t('Svartlackerad'), image: blackLacquered }
  ],
  lifeSpan: [
    { label: '30 år', value: '30' },
    { label: '50 år', value: '50' }
  ],
  safetyClass: [
    {
      label: '1',
      value: '1',
      rules: {
        disabled: true
      }
    },
    { label: '2', value: '2' },
    { label: '3', value: '3' }
  ]
}
