import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { Button } from '../ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import {
  faClone,
  faPenLine,
  faTrashCan
} from '@fortawesome/sharp-regular-svg-icons'
import { t } from 'i18next'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '~/store'
import { RenameRoofForm } from './RenameRoofForm'
import { cn, deleteRoof, duplicateRoof } from '~/lib/utils'

type Props = {
  roofUid: string
  className?: string
  handleDropDownOpen?: (value: boolean) => void
}

export function RoofSectionDropDown({ roofUid, className }: Props) {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [changeNameDialog, setChangeNameDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)

  const { roofs, updateRoof } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      updateRoof: state.updateRoof
    }),
    shallow
  )

  const roof = roofs.find((roof) => roof.uid === roofUid)

  const handleNameChange = (name: string) => {
    if (roof === undefined) {
      return null
    }
    updateRoof({
      ...roof,
      name
    })
    setChangeNameDialog(false)
    setDropDownOpen(false)
  }

  const handleDuplicate = (roofUid: string) => {
    setDropDownOpen(false)
    const roof = roofs.find((roof) => roof.uid === roofUid)
    if (roof === undefined) {
      return
    }
    duplicateRoof(roofUid)
  }

  return (
    <div className={cn('block h-8 w-full group-hover:block', className)}>
      <DropdownMenu
        open={dropDownOpen}
        onOpenChange={setDropDownOpen}
        modal={false}
      >
        <DropdownMenuTrigger asChild>
          <Button
            className={cn(
              'hover:bg-silver-100 group absolute right-2 z-20 ml-10 rounded-full text-lg group-hover:hover:bg-grayscale-25',
              dropDownOpen && 'bg-silver-100'
            )}
            variant="ghost"
            size="icon"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          alignOffset={16}
          side="top"
          sideOffset={0}
          className="pointer-events-auto"
        >
          <DropdownMenuGroup>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
                setChangeNameDialog(true)
              }}
            >
              <FontAwesomeIcon icon={faPenLine} />
              <span className="ml-2">{t('Ändra namn')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
                handleDuplicate(roofUid)
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faClone} />
              <span className="ml-2">{t('Duplicera')}</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(
                'text-red-100',
                roofs.length <= 1 &&
                  'text-grayscale-25 focus:bg-transparent focus:text-grayscale-25'
              )}
              onSelect={(event) => {
                event.preventDefault()
                if (roofs.length <= 1) {
                  return
                }
                setDeleteDialog(true)
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
              <span className="ml-2">{t('Ta bort')}</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={changeNameDialog}
        onOpenChange={() => {
          setChangeNameDialog(!changeNameDialog)
          setDropDownOpen(false)
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('Ändra namn')}</DialogTitle>
            <RenameRoofForm
              handleNameChange={handleNameChange}
              roofName={roof?.name ?? ''}
            />
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteDialog}
        onOpenChange={() => {
          setDeleteDialog(!deleteDialog)
          setDropDownOpen(false)
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('Är du säker?')}</DialogTitle>
            <DialogDescription>
              {t(
                'Är du säker på att du vill ta bort {{roofName}}? Denna åtgärd kan inte ångras.',
                { roofName: roof?.name }
              )}
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-4">
            <Button
              variant="outline"
              onClick={(event) => {
                event.stopPropagation()
                setDeleteDialog(!deleteDialog)
                setDropDownOpen(false)
              }}
            >
              {t('Avbryt')}
            </Button>
            <Button
              onClick={(event) => {
                event.stopPropagation()
                setDeleteDialog(!deleteDialog)
                setDropDownOpen(false)
                deleteRoof(roofUid)
              }}
            >
              {t('Ta bort')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
