import { useEffect, useRef } from 'react'
import { StoreState, useBoundStore } from '~/store'
import SectionImageStage from './SectionImageStage'
import Konva from 'konva'
import { scalePixelsToMm } from '~/utils/configurator'
import { getImage } from '~/utils/images'
import { shallow } from 'zustand/shallow'

const SectionImageHandler = () => {
  const {
    system,
    runImageCreation,
    panelAreaSections,
    panelSectionImagesData,
    setIsComplete,
    setRunImageCreation,
    updatePanelAreaSection
  } = useBoundStore(
    (state: StoreState) => ({
      system: state.conditions.configurationSystem,
      runImageCreation: state.runImageCreation,
      panelAreaSections: state.panelAreaSections,
      panelSectionImagesData: state.panelSectionImagesData,
      updatePanelAreaSection: state.updatePanelAreaSection,
      setIsComplete: state.setIsComplete,
      setRunImageCreation: state.setRunImageCreation
    }),
    shallow
  )

  const sectionImageStageRefs = useRef<Konva.Stage[]>([])

  const setRef = (ref: Konva.Stage, index: number = 0) => {
    sectionImageStageRefs.current[index] = ref
  }

  useEffect(() => {
    if (runImageCreation) {
      sectionImageStageRefs.current.forEach((stage) => {
        if (stage !== null) {
          const size = scalePixelsToMm<Size>(stage.size())
          const image = getImage(stage, { x: 0, y: 0 }, size, 1)
          const panelAreaSection = panelAreaSections.find(
            (panelAreaSection) => panelAreaSection.uid === stage.id()
          )
          if (panelAreaSection) {
            updatePanelAreaSection({
              ...panelAreaSection,
              image
            })
          }
        }
      })
    }
  }, [runImageCreation])

  useEffect(() => {
    const isPanelAreaSectionsImagesGenerated = () =>
      panelAreaSections.some(
        (panelAreaSection) => panelAreaSection.image !== undefined
      )

    if (isPanelAreaSectionsImagesGenerated()) {
      setIsComplete(true)
      setRunImageCreation(false)
    }
  }, [panelAreaSections])

  return (
    <>
      {panelSectionImagesData.map((panelSectionImageData, index) => (
        <SectionImageStage
          system={system ?? 'parallel'}
          setRef={setRef}
          index={index}
          key={panelSectionImageData.uid}
          imageSection={panelSectionImageData}
        />
      ))}
    </>
  )
}

export default SectionImageHandler
