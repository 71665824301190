import React, { useState } from 'react'
import RoundButton from '../buttons/RoundButton'
import { t } from 'i18next'
import RoofsOverviewDialog from './RoofsOverveiwDialog'

const RoofsOverviewButton = React.memo(() => {
  const handleClick = () => {
    setOpenDialog(true)
  }

  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <RoundButton
        onClick={() => handleClick()}
        className=" z-50 mr-12 h-8 w-auto bg-white px-4 text-[14px]"
      >
        {t('Takytor översikt')}
      </RoundButton>
      <RoofsOverviewDialog
        open={openDialog}
        handleOpen={setOpenDialog}
      />
    </>
  )
})

export default RoofsOverviewButton
