import PageWrapper from '~/components/pages/PageWrapper'
import { useTranslation } from 'react-i18next'
import CloseConfigurator from '~/components/CloseConfigurator'
import FormSystemPage from '~/components/form/FormSystemPage'
import { useEffect } from 'react'
import { StoreState, useBoundStore } from '~/store'
import { shallow } from 'zustand/shallow'
import { faArrowRightFromBracket } from '@fortawesome/sharp-regular-svg-icons'

const SystemPage = () => {
  const { t } = useTranslation()
  const {
    resetConfigurator,
    resetConditions,
    resetRoofs,
    resetPanelArea,
    resetPanelAreaSection,
    resetImage,
    setIsLoaded
  } = useBoundStore(
    (state: StoreState) => ({
      resetConfigurator: state.resetConfigurator,
      resetConditions: state.resetConditions,
      resetRoofs: state.resetRoofs,
      resetPanelArea: state.resetPanelArea,
      resetPanelAreaSection: state.resetPanelAreaSection,
      resetImage: state.resetImage,
      setIsLoaded: state.setIsLoaded
    }),
    shallow
  )

  const resetAllConfiguratorStates = () => {
    resetConfigurator()
    resetConditions()
    resetRoofs()
    resetPanelArea()
    resetPanelAreaSection()
    resetImage()
    setIsLoaded(false)
  }

  useEffect(() => {
    resetAllConfiguratorStates()
  }, [])

  return (
    <PageWrapper className=" justify-center">
      <CloseConfigurator
        className="absolute right-14 top-14 z-50 h-12 bg-white"
        iconProps={{
          icon: faArrowRightFromBracket,
          size: 'lg',
          color: 'black'
        }}
      />
      <div className="max-w-lg text-center">
        <h1 className="heading-l mb-14">
          {t('Vilket system vill du konfigurera?')}
        </h1>
      </div>
      <div className="flex min-h-[332px] flex-col">
        <FormSystemPage />
      </div>
    </PageWrapper>
  )
}

export default SystemPage
