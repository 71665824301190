import { ReactNode } from 'react'
import CloseButton from './../buttons/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  children: ReactNode
  handleClose?: () => void
}

const DialogModal = ({ children, handleClose }: Props) => {
  return (
    <div
      data-name="parent"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-[15%]"
    >
      <div className="flex h-full w-full max-w-[640px] flex-col py-36">
        <div className="relative my-auto w-full overflow-auto bg-white px-36 py-24">
          {handleClose !== undefined ? (
            <CloseButton
              className="absolute right-8 top-8"
              onClick={handleClose}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="text-xl"
              />
            </CloseButton>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  )
}

export default DialogModal
