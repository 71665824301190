import { StateCreator } from 'zustand'

export interface PanelAreaSectionSlice {
  panelAreaSections: PanelAreaSection[]
  setPanelAreaSections: (panelAreaSections: PanelAreaSection[]) => void
  updatePanelAreaSection: (panelAreaSection: PanelAreaSection) => void
  resetPanelAreaSection: () => void
}

export const createPanelAreaSectionSlice: StateCreator<
  PanelAreaSectionSlice
> = (set) => ({
  panelAreaSections: [],
  setPanelAreaSections: (panelAreaSections) =>
    set(() => ({ panelAreaSections })),
  updatePanelAreaSection: (updatedPanelAreaSection) =>
    set((state) => ({
      panelAreaSections: state.panelAreaSections.map((panelAreaSection) =>
        updatedPanelAreaSection.uid === panelAreaSection.uid
          ? updatedPanelAreaSection
          : panelAreaSection
      )
    })),
  resetPanelAreaSection: () => {
    set({ panelAreaSections: [] })
  }
})
