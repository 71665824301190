import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/sharp-regular-svg-icons'
import { StoreState, useBoundStore } from '../../store'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { solarPanelClient } from '../../http/api'
import Button from '../buttons/Button'
import { shallow } from 'zustand/shallow'
import React from 'react'
import { getPanelAreasForAPI } from '~/lib/panelAreaUtils'
import * as Sentry from '@sentry/react'

const ShowPlan = React.memo(() => {
  const { t } = useTranslation()
  const {
    roofs,
    conditions,
    panelAreas,
    isDrawing,
    isRedrawing,
    showResults,
    isPositionDataValid,
    isRoofMaterialDataValid,
    isRoofPropertiesDataValid,
    setPanelAreaSections,
    updateProducts,
    setActiveArea,
    setShowResults,
    setIsConfigurationComplete,
    updatePanelArea,
    setSummary,
    setIsApproved,
    setIsLoadingResults,
    setIsLoaded,
    setShowConditions,
    setShowPanelSettings,
    setTriggerDialog
  } = useBoundStore(
    (state: StoreState) => ({
      roofs: state.roofs,
      conditions: state.conditions,
      panelAreas: state.panelAreas,
      isDrawing: state.isDrawing,
      isRedrawing: state.isRedrawing,
      showResults: state.showResults,
      isPositionDataValid: state.isPositionDataValid,
      isRoofMaterialDataValid: state.computed.isRoofMaterialDataValid,
      isRoofPropertiesDataValid: state.computed.isRoofPropertiesDataValid,
      setPanelAreaSections: state.setPanelAreaSections,
      updateProducts: state.updateProducts,
      setActiveArea: state.setActiveArea,
      setShowResults: state.setShowResults,
      setIsConfigurationComplete: state.setIsConfigurationComplete,
      updatePanelArea: state.updatePanelArea,
      setSummary: state.setSummary,
      setIsApproved: state.setIsApproved,
      setIsLoadingResults: state.setIsLoadingResults,
      setIsLoaded: state.setIsLoaded,
      setShowConditions: state.setShowConditions,
      setShowPanelSettings: state.setShowPanelSettings,
      setTriggerDialog: state.setTriggerDialog
    }),
    shallow
  )

  const handleResponse = (res: AxiosResponse) => {
    if (res.status === 200) {
      setActiveArea(null)
      setPanelAreaSections(res.data.panelAreaSections)
      panelAreas.forEach((panelArea) => {
        updatePanelArea({
          ...panelArea,
          bottomLeftCorner: res.data.panelAreas[panelArea.uid].bottomLeftCorner
        })
      })
      updateProducts(res.data.products)
      setSummary(res.data.summary)
      setIsApproved(res.data.isApproved)
      setIsConfigurationComplete(true)
      setIsLoadingResults(false)
      if (!showResults) {
        setShowResults(true)
      }
    } else {
      setIsLoadingResults(false)
      setShowResults(false)
    }
  }

  const handleClick = () => {
    setPanelAreaSections([])
    setIsLoadingResults(true)
    setIsLoaded(false)
    setShowConditions(false)
    setShowPanelSettings(false)
    if (!showResults) {
      setShowResults(true)
    }
    solarPanelClient
      .post('/calculate', {
        roofs: roofs,
        conditions,
        panelAreas: getPanelAreasForAPI(panelAreas)
      })
      .then(handleResponse)
      .catch((error) => {
        Sentry.captureException(error)
        setIsLoadingResults(false)
        setShowResults(false)
        if (error.response.status === 422) {
          const errors = error.response.data?.errors
          setTriggerDialog(
            'ConfigurationNotPossibleDialog',
            errors && errors.length ? errors[0] : undefined
          )
        }
      })
  }

  const hasError = () =>
    panelAreas.some((panelArea) => panelArea.isOutsideRoof === true)

  const everyRoofHasPanelArea = roofs.every((roof) =>
    panelAreas.some((panelArea) => panelArea.roofUid === roof.uid)
  )

  if (
    isDrawing ||
    isRedrawing ||
    showResults ||
    panelAreas.length === 0 ||
    !isPositionDataValid ||
    !isRoofMaterialDataValid ||
    !isRoofPropertiesDataValid ||
    !everyRoofHasPanelArea ||
    hasError()
  )
    return null

  return (
    <Button
      className="absolute bottom-14 right-14 flex"
      onClick={handleClick}
    >
      {t('Utsättningsplan')}
      <FontAwesomeIcon
        className="ml-[10px]"
        icon={faArrowRight}
      />
    </Button>
  )
})

export default ShowPlan
